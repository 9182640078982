import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Traitement } from 'apps/idl/src/model/Traitement';
import { TraitementService } from 'apps/idl/src/services/traitement.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { IPatient } from '../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../modules/patients/services/patients.service';
import { NB_ITEM_TO_DISPLAY } from '../../anakin.constants';
import { DeviceService } from '../../features/shared/services/device.service';
import { LoaderService } from '../../features/shared/services/loader.service';
import { SeanceService } from '../../features/shared/services/seance.service';
import { IViewCountResult } from '@osapp/model/IViewCountResult';

@Component({
  selector: 'di-facturation',
  templateUrl: './facturation.page.html',
  styleUrls: ['./facturation.page.scss'],
})
export class FacturationPage extends DestroyableComponentBase implements OnInit {

  public ordoActiveCountMessage: string;
  public dateFacturation: Date = new Date();
  public facturationfilters: Array<string> = ["À FACTURER", "FACTURÉES"];
  public defaultFilter = this.facturationfilters[0];
  public ordonnances: Traitement[] = [];
  public filteredOrdonnance: Traitement[] = [];
  public isMobile: boolean = false;
  public displayCount: number = NB_ITEM_TO_DISPLAY;
  public patientsMap: Map<string, IPatient> = new Map();
  public filtreForm: FormGroup;
  public searchedValue: string = "";
  public aucuneOrdonnance: boolean = false;
  public MainTextNoResult: string = "Aucune ordonnance";
  public SubTextNoResult: string = "Vous allez retrouver ici toutes les ordonnances patients en cours.";
  public countSeancesByStatus: IViewCountResult[];
  public countSeancesByDateFacturation: IViewCountResult[];
  public maxDate: Date = new Date();

  constructor(
    private svcTraitement: TraitementService,
    private svcDevice: DeviceService,
    private fb: FormBuilder,
    private router: Router,
    private svcLoader: LoaderService,
    private svcPatient: PatientsService,
    private svcSeance: SeanceService
  ) { super(); }

  ngOnInit() {
    this.filtreForm = this.fb.group({
      dateFacturation: [this.dateFacturation ? this.dateFacturation : new Date(), []],
    });

    // Actualise la liste des ordonnances quand l'une d'elles a été supprimées
    this.svcTraitement.deletion$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadListOrdonnances();
    });

    this.svcDevice.isMobile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flag: boolean) => {
        this.isMobile = flag;
      });
    this.loadListOrdonnances();
  }

  private loadListOrdonnances() {
    this.svcLoader.showLoader();
    this.svcTraitement.getAllTraitementsOrdonnances()
      .pipe(
        takeUntil(this.destroyed$),
        tap((traitements: Traitement[]) => {
          this.ordonnances = traitements
        }),
        // On récupère les counts de séances par traitement, status et date de facturation
        switchMap(() => this.svcSeance.countSeancesGroupByStatusAndDateFacturation()),
        tap((result: IViewCountResult[]) => {
          this.countSeancesByDateFacturation = result;
          this.setCountsByDateFacturation();
        }),
        // On récupère les counts de séances par traitement et par status
        switchMap(() => this.svcSeance.countSeancesGroupByStatus()),
        tap((result: IViewCountResult[]) => {
          this.countSeancesByStatus = result;
          this.ordonnances = this.ordonnances.map((ordonnance: Traitement) => {
            ordonnance.countSeancesTotal = this.countSeancesByStatus
              .filter((traitement: IViewCountResult) => traitement.key[0] === ordonnance._id)
              .reduce((total, traitement) => total + traitement.value, 0);
            ordonnance.countSeancesDone = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
              traitement.key[0] === ordonnance._id && traitement.key[1] === 2
            )?.value ?? 0;
            ordonnance.countSeancesCompleted = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
              traitement.key[0] === ordonnance._id && traitement.key[1] === 5
            )?.value ?? 0;
            return ordonnance;
          })
        }),
        switchMap(() => {
          const mapOrdoPatients = this.ordonnances.map((ordonnance: Traitement) => Traitement.extractPatientId(ordonnance._id)).filter(id => id);
          const setOrdoPatients = new Set(mapOrdoPatients)
          const patientIds = Array.from(setOrdoPatients);
          return this.svcPatient.getPatientsByIds(patientIds);
        }),
        tap((patients: IPatient[]) => {
          this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
          this.ordonnances.forEach((ordonnance: Traitement) => {
            ordonnance.patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id))
          })
          this.applyFilter();
          this.getNbOrdonnance();
          this.aucuneOrdonnance = this.ordonnances.length === 0;
          if (this.aucuneOrdonnance) {
            this.MainTextNoResult = "Aucune ordonnance";
            this.SubTextNoResult = "Vous allez retrouver ici toutes les ordonnances par patient.";
          }
          this.svcLoader.hideLoader();
        }),
        takeUntil(this.destroyed$)
      ).subscribe();
  }

  private applyFilter(): void {
    this.filteredOrdonnance = [...this.ordonnances.filter((ordonnance: Traitement) => {
      const treatmentEntries = this.countSeancesByStatus.filter((traitement: IViewCountResult) =>
        traitement.key[0] === ordonnance._id
      );
      if (this.defaultFilter === "À FACTURER") {
        // A facturer : ordonnances qui ont au moins une séance qui n'est pas completed OU aucune séance
        return treatmentEntries.length === 0 || treatmentEntries.some((entry) => entry.key[1] !== 5)
      } else {
        // Facturées : ordonnances qui n'ont que des séances completed
        return (treatmentEntries.length === 1 && treatmentEntries[0].key[1] === 5)
      }
    })];

    this.filteredOrdonnance.sort((a, b) => {
      return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
    });
    this.getNbOrdonnance();
  }

  changeFilter(filtreSelected: string) {
    this.defaultFilter = filtreSelected;
    this.applyFilter();
    if (this.searchedValue && this.searchedValue.length > 2)
      this.filterOrdonnances(this.searchedValue);
  }

  onValueChange(newDate: string) {
    const newDateFormated = new Date(newDate);
    this.dateFacturation = newDateFormated;
    this.filtreForm.setValue({
      dateFacturation: newDateFormated,
    });
    this.setCountsByDateFacturation();
    this.applyFilter();
  }

  handleFacturer = (idOrdonnance: string): void => {
    if (!idOrdonnance) return;
    this.router.navigate(["facturation", "controle", idOrdonnance], { state: { dateFacturation: this.dateFacturation } });
  }

  showMore() {
    this.displayCount += NB_ITEM_TO_DISPLAY;
  }

  public filterOrdonnances(searchValue: string) {
    this.searchedValue = searchValue.trim().toLowerCase();
    if (this.searchedValue && this.searchedValue.length > 2) {
      this.applyFilter();
      const searchTerms = this.searchedValue.split(' ').filter(term => term);
      this.filteredOrdonnance = this.filteredOrdonnance.filter(ordonnance => {
        const patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
        if (!patient) return false;

        const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
        const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
        const lastNameUsuel = patient.usualLastName ? patient.usualLastName.toLowerCase() : '';

        const formattedBirthDateSlash = DateHelper.transform(patient.birthDate, ETimetablePattern.dd_MM_yyyy_slash);

        const matchesOnlyFirstName = searchTerms.length === 1 &&
          searchTerms[0] && firstName.includes(searchTerms[0]);

        const matchesOnlyLastName = searchTerms.length === 1 &&
          searchTerms[0] && lastName.includes(searchTerms[0]);

        const matchesOnlyLastNameusuel = searchTerms.length === 1 &&
          searchTerms[0] && lastNameUsuel.includes(searchTerms[0]);

        const matchesFirstLast = searchTerms.length === 2 &&
          firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

        const matchesLastFirst = searchTerms.length === 2 &&
          lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

        const matchesFirstLastUsuel = searchTerms.length === 2 &&
          firstName.includes(searchTerms[0]) && lastNameUsuel.includes(searchTerms[1]);

        const matchesLastusuelFirst = searchTerms.length === 2 &&
          lastNameUsuel.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

        const matchesSocialNumber = patient.socialNumber?.startsWith(this.searchedValue);
        const matchesBirthDate = this.searchedValue === formattedBirthDateSlash;

        return matchesOnlyFirstName || matchesOnlyLastName || matchesOnlyLastNameusuel || matchesFirstLastUsuel || matchesLastusuelFirst || matchesFirstLast || matchesLastFirst || matchesSocialNumber || matchesBirthDate;
      });
    } else {
      if (searchValue === "") {
        this.applyFilter();

      }
    }
    this.aucuneOrdonnance = this.filteredOrdonnance.length === 0;
    if (this.aucuneOrdonnance) {
      this.MainTextNoResult = "Aucune ordonnance trouvée";
      this.SubTextNoResult = "Essayer de modifier votre recherche";
    }
    this.getNbOrdonnance();
  }

  public getNbOrdonnance(): void {
    const nbOrdoActive = this.filteredOrdonnance.length;
    if (nbOrdoActive == 0) {
      this.ordoActiveCountMessage = "Aucune ordonnance";
    }
    else {
      const filterString = nbOrdoActive > 1
        ? this.defaultFilter.toLowerCase()
        : (
          this.defaultFilter === "FACTURÉES"
            ? this.defaultFilter.toLowerCase().slice(0, -1)
            : this.defaultFilter.toLowerCase()
        )
      this.ordoActiveCountMessage = `${nbOrdoActive} ordonnance${nbOrdoActive > 1
        ? `s ${filterString}`
        : ` ${filterString}`}`;
    }
  }

  public showOrdonnanceList() {
    return this.filteredOrdonnance.length > 0;
  }

  private setCountsByDateFacturation() {
    const calculateCountByDateAndStatus = (ordonnance: Traitement, status: number) =>
      this.countSeancesByDateFacturation
        .filter((traitement: IViewCountResult) =>
          traitement.key[0] === ordonnance._id &&
          new Date(traitement.key[2]) <= DateHelper.fillDay(this.dateFacturation) &&
          traitement.key[1] === status
        )
        .reduce((total, traitement) => total + traitement.value, 0);

    this.ordonnances = this.ordonnances.map((ordonnance: Traitement) => {
      ordonnance.countSeancesDoneByDateFacturation = calculateCountByDateAndStatus(ordonnance, 2);
      ordonnance.countSeancesToBeDoneByDateFacturation = calculateCountByDateAndStatus(ordonnance, 6);
      ordonnance.countSeancesCancelledByDateFacturation = calculateCountByDateAndStatus(ordonnance, 3);
      return ordonnance;
    });
  }
}

