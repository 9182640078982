<div class="menu-ordonnance">
	<div>
		<lua-list-item *ngIf="edit" class="list-item" text="Modifier" iconColor="CouleurPrimaire" iconName="edit"
			(click)="handleEditClick($event)"></lua-list-item>
		<lua-list-item class="list-item" text="Renouveler" iconName="edit" (click)="handleRenouvelerClick()"
			iconColor="CouleurPrimaire"></lua-list-item>
		<lua-list-item class="list-item" text="Arrêter les soins" subtext="Les séances à venir seront supprimées"
			iconName="stop_circle" iconColor="CouleurPrimaire" (click)="handleStopClick($event)"></lua-list-item>
		<lua-list-item class="list-item" text="Mettre en pause les soins" iconName="pause_circle"
			iconColor="CouleurPrimaire" (click)="handleInterrompreClick($event)" [isDisabled]="true"></lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" text="Facturer le patient" iconColor="CouleurPrimaire" iconName="request_quote"
			(click)="handleFacturationClick($event)"></lua-list-item>
		<lua-divider-horizontal></lua-divider-horizontal>
		<lua-list-item class="list-item" text="Supprimer" iconName="delete" iconColor="Error"
			(click)="handleSupprimerClick($event)" [isDisabled]="hasSeanceFacturee"></lua-list-item>
		<lua-alerte *ngIf="hasSeanceFacturee" type="info" message="Les soins sont démarrés"></lua-alerte>
	</div>
</div>