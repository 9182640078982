import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateHelper } from "@osapp/helpers";
import { Invoice } from "apps/idl/src/modules/facturation/models/invoice";
import { FacturationService } from "../../../../../modules/facturation/facturation.service";
import { ControlConfig } from "../../../shared/enums/ControlConfig";
import { EEtatsFacture } from "../../../shared/enums/EEtatsFacture";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";

@Component({
	selector: "di-etat-tiers-payant",
	templateUrl: "./etat-tiers-payant.component.html",
	styleUrls: ["./etat-tiers-payant.component.scss"],
})

export class EtatTiersPayantComponent implements OnInit {
	@Input() facture: Invoice;
	@Input() typeTier: string;

	public etats = Object.values(EEtatsFacture);
	public selectedEtat: string;

	public form: FormGroup;
	public controlsConfig: ControlConfig[];


	constructor(
		private fb: FormBuilder,
		private svcDrawerPopover: DrawerPopoverService,
		private svcInvoice : FacturationService
	) {}

	ngOnInit() {
		let dateForm : Date;
		let commentaireForm : string = '';

	
		switch(this.typeTier)
		{
			case 'amo':
				this.selectedEtat = this.facture.statusAMO ?? EEtatsFacture.aEnvoyer.status;
				dateForm = this.facture.dateAMO;
				commentaireForm = this.facture.commentaireAMO;
				break;
			case 'amc':
				this.selectedEtat = this.facture.statusAMC ?? EEtatsFacture.aEnvoyer.status;
				dateForm = this.facture.dateAMC;
				commentaireForm = this.facture.commentaireAMC;
				break
		}

		this.controlsConfig = [
      { name: 'date', value: dateForm ?? DateHelper.toDateUrl(new Date()), validators : Validators.required },
			{ name: 'commentaire', value: commentaireForm ?? '' }
    ];
		this.form = this.fb.group({});
      this.controlsConfig.forEach(control => {
        this.form.addControl(
          control.name,
          this.fb.control(control.value, control.validators || [])
        );
      });
	}

	public selectedEtatClick($event,etat)
	{
		this.selectedEtat = etat;
	}

	
	public onCancel() {
		this.svcDrawerPopover.close();
	}

	public handleSubmit(event: Event): void {
    event.preventDefault();
		if (this.form.valid) {
      const formValues = this.form.value;
      let formInvoice = {}
			formInvoice = { ...this.facture }
      formInvoice = {
        ...formInvoice,
        ...formValues
      }
			this.saveInvoice(formInvoice)
		}
	}
	
	private saveInvoice(transValue :any)
	{
		let invoice : Invoice;
		invoice = this.facture
		if(this.typeTier == 'amo')
		{
			invoice.statusAMO = this.selectedEtat;
			invoice.commentaireAMO = transValue.commentaire;
			invoice.dateAMO = transValue.date;


		}
		if(this.typeTier == 'amc')
		{
			invoice.statusAMC = this.selectedEtat;
			invoice.commentaireAMC = transValue.commentaire;
			invoice.dateAMC = transValue.date;
		}
		this.svcInvoice.saveInvoice(invoice).subscribe({
			next: () => {
					this.svcDrawerPopover.close();
			},
			error: (error) => {
			}
		});
	}
}
