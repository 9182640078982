<div class="di-mois-item" (click)="selectMonth()">
	<lua-papier mode="outlined" [fullWidth]="true" [selected]="selected" [selectionnable]="true">
		<div class="item-content">
			<div class="content-start">
				<h4>{{ monthName }}</h4>
				<small>{{ invoiceCount }} facture{{ invoiceCount > 1 ? 's' : '' }}</small>
			</div>
			<div class="end-item">
				<h3 [ngClass]="{ 'negative': montantTotal < 0 }">{{ montantTotal | priceFormat:2 }} €</h3>
				<lua-icone iconName="arrow_forward_ios" color="CouleurPrimaire" fontSize="20px"></lua-icone>
			</div>
		</div>
	</lua-papier>
</div>