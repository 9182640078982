import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ArrayHelper, DateHelper, NumberHelper } from "@osapp/helpers";
import { UserData } from "@osapp/model";
import { C_HOURS_MINUTES_REPETITION_TYPE } from "@osapp/modules/event-markers/models/hours-minutes-repetition";
import { IDayRepetition } from "@osapp/modules/event-markers/models/iday-repetition";
import { C_RANGE_REPETITION_TYPE } from "@osapp/modules/event-markers/models/range-repetition";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService } from "@osapp/services";
import { Observable } from "rxjs";
import { map, switchMap, takeUntil, tap } from "rxjs/operators";
import { Acte } from "../../../../../model/Acte";
import { EMajorationType } from "../../../../../model/EMajorationType";
import { EPathologie } from "../../../../../model/EPathologies";
import { EPlace } from "../../../../../model/EPlace";
import { ESecteur } from "../../../../../model/ESecteur";
import { EStatusSeance } from "../../../../../model/EStatusSeance";
import { IActeDocumentByLc } from "../../../../../model/IActeDocumentByLc";
import { IDeplacementByProfession } from "../../../../../model/IDeplacementByProfession";
import { Majoration } from "../../../../../model/Majoration";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { PatientsService } from "../../../../../modules/patients/services/patients.service";
import { Deplacement } from "../../../../../modules/traitement/model/Deplacement";
import { EIndemniteType } from "../../../../../modules/traitement/model/EIndemniteType";
import { Indemnite } from "../../../../../modules/traitement/model/Indemnite";
import { IdlApplicationService } from "../../../../../services/idlApplicationService.service";
import { IndemniteService } from "../../../../../services/indemnite.service";
import { StoredSeance } from "../../../../models/StoredSeance";
import { EEtatActe } from "../../enums/EEtatActe";
import { EMoments } from "../../enums/EMoments";
import { DeviceService } from "../../services/device.service";
import { SeanceService } from "../../services/seance.service";
import { SeancesGeneratorService } from "../../services/seances-generator.service";

@Component({
	selector: "di-soins-realises",
	templateUrl: "./soins-realises.component.html",
	styleUrls: ["./soins-realises.component.scss"],
})
export class SoinsRealisesComponent extends DestroyableComponentBase implements OnInit, OnDestroy {
	@Input() public seance?: StoredSeance;
	@Input() public patient?: IPatient;
	@Input() index?: number;
	@Input() updateConcurrentSeances: (seancesConcurrentes: StoredSeance[]) => void;
	@Input() cancel: () => void;
	public horairePrecise: string = "";
	public total: number;
	showAllMajorations = true;
	checkedIndexes: number[] = [];
	public isMobile: boolean = false;
	public selectedMajos: Majoration[] = [];
	public selectedDeplaces: Indemnite[] = [];
	public showAllDeplacement = true;
	public showIkConfig = false;
	public ikForm: FormGroup;
	public abattement: number = 0;
	public deplacement: Deplacement;
	public selectedOption: string = 'IK';
	public majoList: Majoration[] = [];
	public indemList: Indemnite[] = [];
	public IKSelected: Indemnite;
	public deplacementByLC: IDeplacementByProfession[];
	public isIK: boolean = false;
	public eligibleMIE: boolean = false;
	public sundayOrHoliday: boolean = false;
	public sundayOrHolidayRegion: boolean = false;
	public eligibleN1: boolean = false;
	public eligibleN2: boolean = false;
	public eligibleMAU: boolean = false;
	public eligibleIFI: boolean = false;
	public eligibleMCI: boolean = false;
	private C_IFI_ELIGIBLE_KEY_LETTERS = ["BSA", "BSB", "BSC", "AMX"];
	private C_BSI_ELIGIBLE_KEY_LETTERS = ["BSA", "BSB", "BSC"];



	public concurrentSeances: StoredSeance[] = [];
	public displayedActes: Acte[] = [];
	// Seance utilisée pour calculer les abattement d'actes de plusieurs séances
	public seanceActesSelectionnes: StoredSeance;
	// Seance utilisée pour stocker les actes sans abattement
	public seanceActesNonSelectionnes: StoredSeance;

	constructor(
		private svcDevice: DeviceService,
		private svcGenerateSeance: SeancesGeneratorService,
		private svcIndemnite: IndemniteService,
		private fb: FormBuilder,
		private svcApplication: IdlApplicationService,
		private svcPatient: PatientsService,
		private svcSeance: SeanceService
	) {
		super();
	}

	ngOnInit() {
		this.svcDevice.isMobile$.pipe(
			takeUntil(this.destroyed$)
		).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});
		this.initializeForm();
		this.initializeDeplacement();
		this.initializeDisplayedActes().pipe(
			tap(_ => {
				let ikIndemnite: Indemnite;
				if (this.patient.lastIkUpdate && this.seance.mePlace == EPlace.home) {
					ikIndemnite = this.initIK(this.patient.lastIkUpdate);
					this.selectedDeplaces.push(ikIndemnite);
				}
				if (this.seance.status === EStatusSeance.done) {
					this.displayedActes.forEach((acte: Acte) => {
						if (acte.etat === EEtatActe.not_done)
							acte.etat = EEtatActe.to_be_done;
					});
					this.selectedMajos = [...this.seance.majorations];
					this.selectedDeplaces = this.seance.indemnites?.map((indemnite: Indemnite) => Indemnite.getInstanceFromData(indemnite)) ?? [];
					let ikIndemniteFind = this.seance.indemnites?.find(deplace =>
						deplace.type === EIndemniteType.IK ||
						deplace.type === EIndemniteType.IKM ||
						deplace.type === EIndemniteType.IKS
					);
					if (ikIndemniteFind && ikIndemniteFind.distance) {
						ikIndemnite = this.initIK(ikIndemniteFind);
					} else {
						if (ikIndemnite) this.selectedDeplaces.push(ikIndemnite);
					}
				}
				else {
					this.displayedActes.forEach((acte: Acte) => {
						acte.etat = EEtatActe.done;
					});
				}
				this.sortDisplayedActes();
				this.eligibleIFI = this.isIFIEligible();
				this.initializeIndemniteList();
				this.initializeMajorationList();
				this.calculateTotal();
			})
		).subscribe();
	}

	private sortDisplayedActes() {
		this.displayedActes.sort((a: Acte, b: Acte) => {
			const priceComparison = b.priceCoefficient - a.priceCoefficient;
			if (priceComparison !== 0) {
				return priceComparison;
			}
			const guidComparison = a.guid.localeCompare(b.guid);
			if (guidComparison !== 0) {
				return guidComparison;
			}
			return a.seanceId.localeCompare(b.seanceId);
		});
	}

	private initializeForm(): void {
		let hours: string;
		let minutes: string;
		const moment: IDayRepetition = this.seance.moment;
		if (this.seance.status !== EStatusSeance.done && moment?.type === C_RANGE_REPETITION_TYPE) {
			hours = moment.from.hours.toString();
			// Issue 2708: changement de l'heure du matin de 6h à 8h
			if (hours === "6") hours = "8";
			minutes = moment.from.minutes.toString();
		} else {
			const dateSeance: Date = new Date(this.seance.startDate);
			hours = dateSeance.getHours().toString();
			minutes = dateSeance.getMinutes().toString();
		}
		hours = hours.padStart(2, "0");
		minutes = minutes.padStart(2, "0");
		this.horairePrecise = `${hours}:${minutes}`;
		this.ikForm = this.fb.group({
			distance: [0],
			montant: [0],
			heure: [this.horairePrecise],
		});
	}

	private initializeDeplacement() {
		this.deplacement = new Deplacement();
		this.deplacement.sectorType = ESecteur.Plaine;
		this.deplacement.isManualPrice = false
		this.IKSelected = new Indemnite(
			"IK-1-1",
			this.svcIndemnite.getIndemniteTypeFromId("IK-1-1"),
			'',
			'',);
	}

	private initializeDisplayedActes(): Observable<StoredSeance[]> {
		const startDate = new Date(this.seance.startDate);
		return this.svcSeance.selectSeancesByDateMomentAndPatient(
			startDate,
			StoredSeance.determineMoment(this.seance.moment ?? startDate) as EMoments,
			this.seance.patientId
		).pipe(
			map((seances: StoredSeance[]) => {
				seances.forEach((seance: StoredSeance) => {
					seance.actes = seance.actes.map((acte: Acte) => {
						const clonedActe = new Acte(acte);
						clonedActe.seanceId = seance._id;
						return clonedActe;
					});
				});
				this.concurrentSeances = seances.filter(seance => seance._id != this.seance._id);
				return seances;
			}),
			switchMap((seances: StoredSeance[]) => {
				this.seanceActesSelectionnes = Object.assign({}, this.seance);
				this.seanceActesNonSelectionnes = Object.assign({}, this.seance);
				this.seanceActesSelectionnes.actes = [];
				this.seanceActesNonSelectionnes.actes = [];
				seances.forEach((seance: StoredSeance) => {
					seance.actes.forEach((acte: Acte) => {
						if (this.seance.status !== EStatusSeance.done || acte.etat === EEtatActe.done) {
							this.seanceActesSelectionnes.actes.push(acte);
						} else {
							this.seanceActesNonSelectionnes.actes.push(acte);
						}
					})
				})
				return this.initializeAbattement();
			})
		);
	}

	// Calcul l'abattement des actes de chaque séance
	private initializeAbattement(): Observable<StoredSeance[]> {
		// On applique l'abattement seulement sur les actes selectionnés
		return this.svcGenerateSeance.applyTaxAllowance([this.seanceActesSelectionnes], this.patient).pipe(
			tap((seances: StoredSeance[]) => {
				const seance = ArrayHelper.getFirstElement(seances);
				if (seance) {
					this.displayedActes = [];
					this.concurrentSeances.forEach((seance: StoredSeance) => {
						seance.actes = [];
					});
					this.seance.actes = [];

					// Met à jour la liste des actes des séances concurrents avec l'abattement
					this.seanceActesSelectionnes = seance;
					// gestion des BSI a 100% abbatu en cas de deuxieme ou xieme passage dans la même journée
					const momentseance: IDayRepetition = this.seanceActesSelectionnes.moment;

					const updatedActes = this.seanceActesSelectionnes.actes.map(acte => {
						if (this.C_BSI_ELIGIBLE_KEY_LETTERS.some(bsi => bsi === acte.keyLetters) && (acte.recurrences && Array.isArray(acte.recurrences))) {
							acte.recurrences.forEach(recurrence => {
								const recurrenceSort = SeanceService.sortDayRepetitions(recurrence.dayRepetitions);
								const index = recurrenceSort.findIndex((rep: IDayRepetition) =>
									rep.type === momentseance.type &&
									(rep.type === 'range' && 
									rep.from.hours === momentseance.from.hours &&
									rep.to.hours === momentseance.to.hours) || 
									(rep.type === C_HOURS_MINUTES_REPETITION_TYPE && 
									rep.hours === momentseance.hours)
								);

								if (index > 0) {
									acte.taxAllowance = 0;
								}
							});
						}
						return acte;
					});
					this.seanceActesSelectionnes.actes = updatedActes;
					[...this.seanceActesSelectionnes.actes, ...this.seanceActesNonSelectionnes.actes].forEach((acte: Acte) => {
						if (acte.seanceId === this.seance._id)
							this.seance.actes.push(acte)
						else
							this.concurrentSeances.find((seance: StoredSeance) => seance._id === acte.seanceId)?.actes.push(acte);
					});

					// Met à jour la liste des actes affichés dans le panneau
					this.displayedActes.push(...this.seanceActesSelectionnes.actes);
					this.displayedActes.push(...this.seanceActesNonSelectionnes.actes);
					this.sortDisplayedActes();
				}
			})
		);
	}


	private initIK(ikIndemnite: Indemnite) {
		if (ikIndemnite) {
			ikIndemnite = Indemnite.getInstanceFromData(ikIndemnite);
			this.isIK = true;
			this.IKSelected = ikIndemnite;
			this.showIkConfig = true;
			this.ikForm.get('distance')?.setValue(this.IKSelected.distance);
			this.selectedOption = this.IKSelected.type;
			this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
			this.deplacement.sectorType = this.svcIndemnite.getSecteur(this.IKSelected.type);
			this.deplacement.distance = this.IKSelected.distance;
			this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		}
		return ikIndemnite;
	}

	public isSelectedInListIndem(indem: Indemnite) {
		if (!ArrayHelper.hasElements(this.selectedDeplaces)) return false
		return this.selectedDeplaces.find(deplacement => deplacement.type == indem.type)
	}

	public isSelectedInListMajo(majo: Majoration) {
		if (!ArrayHelper.hasElements(this.selectedMajos)) return false
		return this.selectedMajos.find(majoration => majoration.type == majo.type)
	}

	// Appelé au clic sur un type d'IK
	selectOption(option: string) {
		// On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
		this.selectedDeplaces = this.selectedDeplaces.filter((indem: Indemnite) => !indem.isIKType);
		this.IKSelected.type = this.svcIndemnite.getType(option);
		this.selectedOption = option;
		this.deplacement.sectorType = this.svcIndemnite.getSecteur(option);
		this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		this.IKSelected.id = "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType;
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
		this.selectedDeplaces.push(this.IKSelected);
		this.calculateTotal();
	}

	// Appelé lorsqu'on saisi une distance réelle pour l'IK
	public onInputChange(distance: number) {
		// On garde seulement les déplamcents qui ne sont pas de type IK car on va recalculer l'IK puis l'ajouter de nouveau
		this.selectedDeplaces = this.selectedDeplaces.filter(indem => !indem.isIKType);
		this.deplacement.distance = distance;
		this.IKSelected.distance = distance;
		this.IKSelected.label = `(${distance}km)`;
		this.abattement = this.svcIndemnite.calculateAbattementDistance(this.deplacement);
		this.deplacement.sectorPrice = this.svcIndemnite.getDeplacementTarif(
			this.deplacementByLC.find(deplacement => deplacement.id === "IK-" + this.svcApplication.profession + "-" + this.deplacement.sectorType)
		);
		this.svcIndemnite.setIndemnitePrice(this.deplacement, this.IKSelected, this.deplacementByLC);
		this.ikForm.get('montant')?.setValue(NumberHelper.round(this.IKSelected.price, 2));
		this.selectedDeplaces.push(this.IKSelected)
		this.calculateTotal();

	}

	private initializeMajorationList(): void {
		this.svcGenerateSeance.getMajorations().pipe(
			takeUntil(this.destroyed$),
			tap((majorations: IActeDocumentByLc[]) => {
				this.majoList = majorations.map((majo) => {
					const type = this.svcGenerateSeance.getMajorationTypeFromId(majo.id);
					return new Majoration(
						majo.id,
						type,
						this.svcGenerateSeance.getMajorationPriceFromArray([majo], type),
						this.svcGenerateSeance.getMajorationDescriptionFromArray([majo], type)
					);
				});
			}),
			tap(_ => this.initListMajo())
		).subscribe();
	}

	private initializeIndemniteList(): void {
		this.svcIndemnite.getDeplacementsByProfession(this.svcApplication.profession).pipe(
			takeUntil(this.destroyed$),
			tap((deplacements: IDeplacementByProfession[]) => {
				this.deplacementByLC = deplacements;
				deplacements.map((deplacement: IDeplacementByProfession) => {
					if (!deplacement.lettreCle.includes(EIndemniteType.IK)) {
						const indemnite = new Indemnite(
							deplacement.id,
							this.svcIndemnite.getIndemniteTypeFromId(deplacement.id),
							deplacement.description,
							'',
							this.svcIndemnite.getDeplacementTarif(deplacement, this.seance.startDate)
						);
						this.indemList.push(indemnite)
					}
				})
				this.initListIndem()
			})
		).subscribe()
	}


	private initListMajo(): void {
		this.eligibleIFI = this.isIFIEligible();
		this.eligibleMIE = this.eligibleMajorationMie();
		this.eligibleMCI = this.eligibleMajorationMCI();
		this.eligibleMAU = this.eligibleMajorationMAU();
		this.sundayOrHoliday = this.svcGenerateSeance.isSundayOrPublicHoliday(new Date(this.seance.startDate));
		this.sundayOrHolidayRegion = DateHelper.isPublicHolidayForRegion(new Date(this.seance.startDate));
		this.eligibleN1 = this.eligibleMajorationN1();
		this.eligibleN2 = this.eligibleMajorationN2();
		if (this.seance.status === EStatusSeance.to_be_done) {
			this.selectedMajos = [];
		}

		this.majoList.forEach((majoration) => {
			majoration.disabled = this.isMajorationDisabled(majoration.type);
			if (this.seance.status === EStatusSeance.to_be_done &&
				(
					(majoration.type === EMajorationType.Mie && this.eligibleMIE) ||
					(majoration.type === EMajorationType.SundayAndHolyday && !this.eligibleN1 && !this.eligibleN2 && this.sundayOrHoliday) ||
					(majoration.type === EMajorationType.Mci && this.eligibleMCI) ||
					(majoration.type === EMajorationType.NightFirstHalf && this.eligibleN1) ||
					(majoration.type === EMajorationType.NightSecondHalf && this.eligibleN2) ||
					(majoration.type === EMajorationType.Mau && this.eligibleMAU && !this.eligibleIFI && !this.eligibleMCI)
				)
			) {
				this.selectedMajos.push(majoration);
			} else if (majoration.disabled) {
				// Retirer la coche si la majoration est désactivée
				this.selectedMajos = this.selectedMajos.filter(a => a.type !== majoration.type);
			}
		});
		this.initListIndem();
	}

	public onHoraireChange(event: Event) {
		this.initListMajo()
	}

	private isMajorationDisabled(type: EMajorationType): boolean {
		switch (type) {
			case EMajorationType.Mie:
				return !this.eligibleMIE;
			case EMajorationType.SundayAndHolyday:
				return !this.sundayOrHoliday && !this.sundayOrHolidayRegion;
			case EMajorationType.NightFirstHalf:
				return !this.eligibleN1;
			case EMajorationType.NightSecondHalf:
				return !this.eligibleN2;
			case EMajorationType.Mau:
				return !this.eligibleMAU;
			case EMajorationType.Mci:
				return !this.eligibleMCI;
			default:
				return false;
		}
	}

	private isIndemiteDisabled(type: EIndemniteType): boolean {
		switch (type) {
			case EIndemniteType.IFI:
				return !this.eligibleIFI;
			case EIndemniteType.IFD:
				return this.seanceActesSelectionnes.actes.some(acte => acte.keyLetters === "IFI")
			default:
				return false;
		}
	}

	private initListIndem(): void {
		if (this.seance.status === EStatusSeance.to_be_done) {
			this.selectedDeplaces = this.IKSelected ? [this.IKSelected] : [];
		}
		this.indemList.forEach((indemnite) => {
			indemnite.disabled = this.isIndemiteDisabled(indemnite.type);
			if (this.seance.status !== EStatusSeance.done) {
				switch (true) {
					case indemnite.type === EIndemniteType.IFD && this.seance.mePlace === EPlace.home && !this.eligibleIFI && !this.seanceActesSelectionnes.actes.some(acte => acte.keyLetters === "IFI"):
						this.selectedDeplaces.push(indemnite)
						break;
					case indemnite.type === EIndemniteType.IFI && this.seance.mePlace === EPlace.home && this.eligibleIFI:
						this.selectedDeplaces.push(indemnite)
						break;
				}
			}
		})
		this.calculateTotal();
	}

	// Appelé au clic sur la checkbox IK
	toggleIKSelected() {
		// Si la config de l'IK était affiché, alors on supprime l'IK de la liste des indemnités sélectionnées
		if (this.showIkConfig) {
			this.selectedDeplaces = this.selectedDeplaces.filter((indem: Indemnite) => !indem.isIKType)
		} else {
			// Sinon on rajoute le dernier type d'IK coché ou IK plaine si il n'y a pas eu d'IK coché précédement
			this.selectedDeplaces.push(this.IKSelected)
		}
		this.showIkConfig = !this.showIkConfig;
		this.calculateTotal();
	}

	private eligibleMajorationMAU(): boolean {
		return this.seanceActesSelectionnes.actes.length == 1 &&
			this.seanceActesSelectionnes.actes[0].priceCoefficient <= 1.5 &&
			!this.eligibleIFI &&
			// On vérifie qu'aucun acte n'est un acte antigrippal
			this.seanceActesSelectionnes.actes.every(acte => acte.id !== 'act_0350');
	}

	private isIFIEligible(): boolean {
		if (ArrayHelper.hasElements(this.seance.actes)) {
			if (this.seanceActesSelectionnes.actes.some(acte => acte.keyLetters === "IFI")) {
				return false;
			}
			// On regroupe toutes les lettre-clés dans un seul tableau sans doublon dans lequel on vérifie s'il y a des lettre-clés éligibles à l'IFI.
			return ArrayHelper.unique(this.seanceActesSelectionnes.actes.map((acte: Acte) => acte.keyLetters))
				.some((psKeyLetter: string) => this.C_IFI_ELIGIBLE_KEY_LETTERS.some((psEligible: string) => psEligible === psKeyLetter));
		}
		else
			return false;
	}

	private eligibleMajorationMie(): boolean {
		return DateHelper.isDate(this.patient?.birthDate) && DateHelper.diffYear(new Date(this.seance.startDate), new Date(this.patient.birthDate)) < 7;
	}


	private eligibleMajorationN1(): boolean {
		const [hours, minutes] = this.horairePrecise.split(':').map(Number);  // Convertit en nombre
		return (hours >= 5 && hours < 8) || (hours >= 20 && hours < 23);
	}


	private eligibleMajorationN2(): boolean {
		const [hours, minutes] = this.horairePrecise.split(':').map(Number);  // Convertit en nombre
		return hours >= 23 || hours < 5;
	}

	public eligibleMajorationMCI(): boolean {
		return this.seanceActesSelectionnes.actes.some((acte: Acte) => acte.isMciEligible) || this.patient.pathologies?.includes(EPathologie.palliatif);
	}



	toggleMajorations() {
		this.showAllMajorations = !this.showAllMajorations;
	}

	toggleCheckMajoration(event: Event, majo: Majoration) {
		// Vérifier les incompatibilités entre Dim et N1/N2
		const isSundayOrHoliday = majo.type === EMajorationType.SundayAndHolyday;
		const isNightFirstHalf = majo.type === EMajorationType.NightFirstHalf;
		const isNightSecondHalf = majo.type === EMajorationType.NightSecondHalf;

		// Retirer N1 ou N2 si Dim est sélectionné
		if (isSundayOrHoliday && (this.selectedMajos.some(a => a.type === EMajorationType.NightFirstHalf) ||
			this.selectedMajos.some(a => a.type === EMajorationType.NightSecondHalf))) {
			this.selectedMajos = this.selectedMajos.filter(a => ![EMajorationType.NightFirstHalf, EMajorationType.NightSecondHalf].includes(a.type));
		}
		// Retirer Dim si N1 ou N2 est sélectionné
		else if ((isNightFirstHalf || isNightSecondHalf) && this.selectedMajos.some(a => a.type === EMajorationType.SundayAndHolyday)) {
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.SundayAndHolyday);
		}

		// Gérer les incompatibilités entre MAU, MCI
		const isMau = majo.type === EMajorationType.Mau;
		const isMci = majo.type === EMajorationType.Mci;

		if (isMau) {
			// Désélectionner MIE et MCI si MAU est sélectionné
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mci);
		} else if (isMci) {
			// Désélectionner MAU si MCI est sélectionné
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mau);
		}

		// Toggle la sélection de la majoration
		if (this.selectedMajos?.some(a => a.id === majo.id)) {
			this.selectedMajos = this.selectedMajos.filter(a => a.id !== majo.id); // Retirer si déjà sélectionné
		} else {
			this.selectedMajos.push(majo); // Ajouter si pas sélectionné
		}

		// Calculer le total après modification de la sélection
		this.calculateTotal();
	}



	toggleDeplacement() {
		this.showAllDeplacement = !this.showAllDeplacement;
	}

	toggleCheckDeplacement(event: Event, depla: Indemnite) {
		if (depla.type === EIndemniteType.IFD && this.selectedDeplaces.some(a => a.type === EIndemniteType.IFI)) {
			this.selectedDeplaces = this.selectedDeplaces.filter(a => a.type !== EIndemniteType.IFI);
		}
		else if (depla.type === EIndemniteType.IFI && this.selectedDeplaces.some(a => a.type === EIndemniteType.IFD)) {
			this.selectedDeplaces = this.selectedDeplaces.filter(a => a.type !== EIndemniteType.IFD);
		}
		if (this.selectedDeplaces?.some(a => a.id === depla.id)) {
			this.selectedDeplaces = this.selectedDeplaces.filter(a => a.id !== depla.id);
		} else {
			this.selectedDeplaces.push(depla);
		}
		if (depla.type === EIndemniteType.IFI && this.selectedMajos.some(a => a.type === EMajorationType.Mau)) {
			this.selectedMajos = this.selectedMajos.filter(a => a.type !== EMajorationType.Mau);
		}
		this.calculateTotal();
	}

	getSelectedItems() {
		return this.checkedIndexes.map((index) => this.seance.majorations[index]);
	}

	majActes = (actes: Acte[]) => {
		actes.forEach(acte => {
			acte.etat = this.seanceActesSelectionnes.actes.some(selectedActe => selectedActe.guid === acte.guid && selectedActe.seanceId === acte.seanceId)
				? EEtatActe.done
				: EEtatActe.not_done;
		});
	};

	private majSeance() {
		delete this.seance.concurrentSeanceId;
		const statusChangeDate = new Date();
		this.seance.statusChangeDate = statusChangeDate;
		this.seance.status = EStatusSeance.done;
		this.majActes(this.seance.actes);

		const heureRealisation = this.ikForm.get('heure')?.value;
		if (heureRealisation) {
			const [hours, minutes] = heureRealisation.split(':').map(Number);
			const dateSeance = new Date(this.seance.startDate);
			dateSeance.setHours(hours, minutes)
			this.seance.startDate = dateSeance;
		}
		this.seance.majorations = this.selectedMajos;
		this.selectedDeplaces.sort((a, b) => a.type.localeCompare(b.type));
		this.seance.indemnites = !this.IKSelected.distance ? this.selectedDeplaces.filter(indem => !indem.isIKType) : this.selectedDeplaces;

		//mettre a jour mon patient si IK change seulement si c'est a domicile
		if (this.patient.lastIkUpdate != this.IKSelected && this.seance.mePlace == EPlace.home && (this.IKSelected.distance)) {
			this.patient.lastIkUpdate = this.IKSelected;
			this.svcPatient.savePatientAnakin(this.patient).subscribe();
		}
		
		this.seance.infirmierId = ContactsService.getContactIdFromUserId(UserData.current._id);
		this.concurrentSeances.forEach((seanceChild: StoredSeance) => {
			seanceChild.concurrentSeanceId = this.seance._id;
			// TODO : voir cumul possibles en fonction du retour PO
			seanceChild.majorations = [];
			seanceChild.indemnites = [];
			seanceChild.infirmierId = this.seance.infirmierId;
			seanceChild.statusChangeDate = statusChangeDate;
			seanceChild.status = EStatusSeance.done;
			seanceChild.startDate = this.seance.startDate;
			this.majActes(seanceChild.actes);
		})
	}

	private resetSeance(seance: StoredSeance) {
		seance.actes.map((acte: Acte) => {
			acte.etat = EEtatActe.to_be_done;
			acte.taxAllowance = 1;
		})
		seance.majorations = [];
		seance.indemnites = [];
		seance.status = EStatusSeance.to_be_done;
		delete seance.statusChangeDate;
		delete seance.concurrentSeanceId;
	}

	private majSeanceDelete() {
		this.resetSeance(this.seance);
		this.concurrentSeances.forEach((seance: StoredSeance) => {
			this.resetSeance(seance);
		})
	}

	handleClickConfirm() {
		this.majSeance();
		if (this.updateConcurrentSeances)
			this.updateConcurrentSeances([this.seance, ...this.concurrentSeances])
	}

	handleClickDelete() {
		this.majSeanceDelete();
		if (this.updateConcurrentSeances)
			this.updateConcurrentSeances([this.seance, ...this.concurrentSeances])
	}

	handleClickAnnule() {
		if (this.cancel)
			this.cancel();
	}

	handleCheckboxChange(event: Event, acte: Acte) {
		if (this.seanceActesSelectionnes.actes?.some(a => a.guid === acte.guid && a.seanceId === acte.seanceId)) {
			this.seanceActesSelectionnes.actes = this.seanceActesSelectionnes.actes.filter(a => a.guid !== acte.guid || a.seanceId !== acte.seanceId);
			acte.taxAllowance = 1;
			this.seanceActesNonSelectionnes.actes.push(acte);
		} else {
			this.seanceActesSelectionnes.actes.push(acte);
			this.seanceActesNonSelectionnes.actes = this.seanceActesNonSelectionnes.actes.filter(a => a.guid !== acte.guid || a.seanceId !== acte.seanceId);
		}
		this.initializeAbattement().pipe(
			tap(_ => {
				this.initListMajo();
				this.calculateTotal();
			})
		).subscribe();
	}

	calculateTotal() {
		const acteTotal = this.seanceActesSelectionnes.actes.reduce((sum, acte) => sum + (acte?.price || 0), 0);
		const majorationTotal = this.selectedMajos.reduce((sum, majo) => sum + (majo?.price || 0), 0);
		const indemnityTotal = this.selectedDeplaces.reduce((sum, indem) => sum + (indem?.price || 0), 0);

		this.total = acteTotal + majorationTotal + indemnityTotal;
	}
}
