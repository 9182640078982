<div class="di-liste-seance__container">
	<div *ngIf="mode === 'add'">
		<lua-message-action-en-cours mainText="Calcul des séances de soins"
			subText="Les soins seront automatiquement affectés selon le planning infirmier" [loaderSize]="60"
			[loaderSpeed]="1"></lua-message-action-en-cours>
	</div>
	<div *ngIf="mode === 'edit'">
		<div *ngIf="seances.length > 0">
			<di-liste-seance [visibleGroupedSeances]="visibleGroupedSeances" [ordonnance]="ordonnance" [patient]="patient" [targetSeanceId]="targetSeanceId">
			</di-liste-seance>
			<div class="buttons">
				<lua-action-secondaire *ngIf="!noMoreSeances" libelle="Afficher les soins suivants" (click)="loadMoreSeances()"
					[fullWidth]="false"></lua-action-secondaire>
			</div>
			<div *ngIf="noMoreSeances" class="text-not-found">
				<p>Pas d'autre séance</p>
			</div>
		</div>
		<ng-container *ngIf="seances.length === 0 && !loadingSeances">
			<lua-message-aucune-donnee mainText="Aucune séance" iconName="contract" buttonLabel="Retour"
				(onClick)="goBack($event)"></lua-message-aucune-donnee>
		</ng-container>
	</div>
</div>