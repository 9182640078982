import { Component, Input } from '@angular/core';
import { IContact } from '@osapp/model';

@Component({
	selector: 'di-panneau-form-contact',
	templateUrl: './panneau-form-contact.component.html',
	styleUrls: ['./panneau-form-contact.component.scss'],
})
export class PanneauFormContactComponent {

	@Input() contact?: IContact;

	constructor(
	) { }

}
