import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { EEtatsFacture } from "../../../shared/enums/EEtatsFacture";
import { EEtatsReglement } from "../../../shared/enums/EEtatsReglement";
import { EModesReglement } from "../../../shared/enums/EModesReglement";

@Component({
	selector: "di-part-detail-facturation",
	templateUrl: "./part-detail-facturation.component.html",
	styleUrls: ["./part-detail-facturation.component.scss"],
})
export class PartDetailFacturationComponent implements OnInit,OnChanges{
	@Input() text: string = "";
	@Input() reglementType?: EModesReglement;
	@Input() typePart : string;
	@Input() date?: Date;
	@Input() status: string = EEtatsFacture.aEnvoyer.status;
	@Input() labelStatus?: string;
	public statusColor: string = '';
	public statusStyle: string = '';
	public statusText: string = '';
	public statusClass: string = '';
	public showInfo : boolean = false;
	public infoPart : string;
	public isPP: boolean = false;
	public statusTier: any;
	public etats = [...Object.values(EEtatsFacture), ...Object.values(EEtatsReglement)];


	ngOnInit(): void {
		this.updateStatusValues();
		this.setInfoPart();
	}

	private setInfoPart() {
		if(this.typePart === 'pp' && this.status === EEtatsReglement.paye.status)
		{
			this.infoPart = `Règlement par `;
			this.isPP = true;
		}

		if(this.status !== EEtatsFacture.aEnvoyer.status && this.status !== EEtatsFacture.papier.status && this.status !== EEtatsReglement.aRegler.status)
		{
			this.statusTier = this.etats.find((etat) => etat.status === this.status);
			this.showInfo = true;
		}
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		this.updateStatusValues();
	}

	private updateStatusValues(): void {
		this.statusClass = this.getStatusClass();
	}

	getStatusClass(): string {
		switch (this.status) {
			case EEtatsFacture.paye.status:
			case EEtatsReglement.paye.status:
				return "status-paid";
			case EEtatsFacture.envoyer.status:
			case EEtatsReglement.envoyer.status:
				return "status-sent";
			case EEtatsFacture.papier.status:
				return "status-paper";
			case EEtatsFacture.rejet.status:
			case EEtatsReglement.impaye.status:
				return "status-rejet";
			case EEtatsFacture.aEnvoyer.status:
			case EEtatsReglement.aRegler.status:
				return "status-toSend";
			default:
				return "";
		}
	}
}
