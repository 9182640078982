import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'di-panneau-menu-mois',
	templateUrl: './panneau-menu-mois.component.html',
	styleUrls: ['./panneau-menu-mois.component.scss'],
})
export class PanneauMenuMoisComponent implements OnInit {
	@Input() public onMonthSelected: (month: number, year: number) => void = () => { };

	constructor() { }

	ngOnInit() { }

}
