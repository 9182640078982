import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigData } from '@osapp/model';
import { AuthenticatedRequestOptionBuilder } from '@osapp/modules/api/models/authenticated-request-option-builder';
import { IGenererTokenUI } from 'apps/idl/src/model/IGenererTokenUI';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class IESecurisationService {

	//#region METHODS

	constructor(private httpClient: HttpClient) {
	}

	public genererTokenUI(numRpps: string, numFiness: string, page: string): Observable<string> {
		if (!numRpps || !numFiness || !page) return EMPTY;

		return this.httpClient.get<IGenererTokenUI>(
			`${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/securisation/tokenUI?page=${page}&numeroFiness=${numFiness}&numeroRpps=${numRpps}`,
			AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
		)
			.pipe(
				map((data: IGenererTokenUI) => data.token),
				catchError(error => {
					return throwError(error);
				})
			);
	}

	//#endregion

}