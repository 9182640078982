import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'di-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss'],
})
export class DayComponent implements OnInit {

  @Input() number: number;
  @Input() month: number;
  @Input() year: number;
  @Input() isWorked: boolean;
  @Input() isWorkedNotByMe: boolean;
  @Input() disabled: boolean;
  @Input() onSelectDayItem: (date: Date, isWorked: boolean) => void;

	public classes : { [key: string]: boolean };

  constructor() { 
	}


	ngOnInit(): void {
		this.classes = this.getClasses()
	}

  selectDay(): void {
    this.isWorked = !this.isWorked;
    const selectedDate = new Date(this.year, this.month, this.number);
		this.classes = this.getClasses();
    if (this.onSelectDayItem) {
      this.onSelectDayItem(selectedDate, this.isWorked);
    }
  }

	private getClasses(): { [key: string]: boolean } {
		return {
			"empty": this.number == null,
			"workedByMe": this.isWorked,
			"workedNotByMe": this.isWorked && this.isWorkedNotByMe ? !this.isWorkedNotByMe : this.isWorkedNotByMe,
			"not-worked": !this.isWorked && !this.isWorkedNotByMe && this.number != null,
		};
	}
}
