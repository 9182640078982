import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'di-impression-tournee',
  templateUrl: './impression-tournee.component.html',
  styleUrls: ['./impression-tournee.component.scss'],
})
export class ImpressionTourneeComponent implements OnInit {

  constructor() { }

  @Input() tourneeDate!: string;
  @Input() nurseName!: string;
  @Input() sortedSeancesEvents: { type: 'seance' | 'event'; data: any }[] = [];

  ngOnInit() {
  }
}