import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ArrayHelper } from '@osapp/helpers';
import { UserData } from '@osapp/model';
import { HoursMinutesRepetition } from '@osapp/modules/event-markers/models/hours-minutes-repetition';
import { IDayRepetition } from '@osapp/modules/event-markers/models/iday-repetition';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService } from '@osapp/services';
import { IModifActesSeance } from 'apps/idl/src/anakin/models/IModifActesSeance';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { cloneDeep } from 'lodash';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Acte } from '../../../../../model/Acte';
import { EStatusSeance } from '../../../../../model/EStatusSeance';
import { StoredSeance } from '../../../../models/StoredSeance';
import { EEtatActe } from '../../enums/EEtatActe';
import { EMoments } from '../../enums/EMoments';
import { ESeanceOptionModification } from '../../enums/ESeanceOptionModification';
import { ETypeModifSeance } from '../../enums/ETypeModifSeance';
import { DeviceService } from '../../services/device.service';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { PanneauService } from '../../services/panneau.service';
import { SeanceService } from '../../services/seance.service';
import { AffectationSoinsComponent } from '../affectation-soins/affectation-soins.component';
import { AjouterSeanceComponent } from '../ajouter-seance/ajouter-seance.component';
import { ChoixSeanceComponent } from '../choix-seance/choix-seance.component';
import { ConfirmationSuppressionComponent } from '../confirmation-suppression/confirmation-suppression.component';
import { ModifierActesComponent } from '../modifier-actes/modifier-actes.component';
import { ModifierDateSeanceComponent } from '../modifier-date-seance/modifier-date-seance.component';
import { ModifierHeuresSoinsComponent } from '../modifier-heures-soins/modifier-heures-soins.component';
import { PatientIndisponibleComponent } from '../patient-indisponible/patient-indisponible.component';
import { SoinsRealisesComponent } from '../soins-realises/soins-realises.component';


@Component({
	selector: 'di-menu-modifier-seance',
	templateUrl: './menu-modifier-seance.component.html',
	styleUrls: ['./menu-modifier-seance.component.scss'],
})
export class MenuModifierSeanceComponent extends DestroyableComponentBase implements OnInit, OnDestroy {
	@Input() seance: StoredSeance;
	@Input() patient: IPatient;
	@Input() anchorElement?: any;
	@Input() openChoix?: string;

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcPanneau: PanneauService,
		private svcDevice: DeviceService,
		private svcSeance: SeanceService,
		private router: Router,
	) {
		super();
	}

	public showPopover: boolean = false;
	public isMobile: boolean = false;
	public typeModif: string;

	// Contient la liste des modifications d'acte à appliquer
	public modifActesSeance: IModifActesSeance

	ngOnInit() {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});

		if (this.openChoix) {
			switch (true) {
				case this.openChoix === "soinsRealise":
					this.openMenuSoins();
					break;
				case this.openChoix === "infirmier":
					this.changerInfirmier();
					break;
				case this.openChoix === "moment":
					this.editHeure();
					break;
			}
		}
	}


	public editHeure(event?: Event): void {
		let anchorRect: DOMRect = null;
		if(event){
			const element: HTMLElement = event.currentTarget as HTMLElement;
			anchorRect = element.getBoundingClientRect();
		}
		this.closeMenu()
		this.svcPanneau.close();
		this.svcDrawerPopover.open("Modifier l'heure des soins",
			"60%",
			this.anchorElement,
			ModifierHeuresSoinsComponent,
			{
				seance: this.seance,
				onConfirm: (updatedSeance: StoredSeance) => {
					this.typeModif = ETypeModifSeance.Heure;
					this.seance = updatedSeance;
					this.openOccurence()
				},
				onCancel: () => this.closeMenu(),
			},
			null,
			anchorRect
		)

	}

	public editDate(event?: Event): void {
		let anchorRect: DOMRect = null;
		if (event) {
			const element: HTMLElement = event.currentTarget as HTMLElement;
			anchorRect = element.getBoundingClientRect();
		}
		this.closeMenu();
		this.svcPanneau.close();
		const title = 'Modifier date';
		const content = ModifierDateSeanceComponent;
		const contentInputs = {
			seance: this.seance,
			onConfirm: (selectedDate: Date) => this.onConfirmDateChange(selectedDate),
			onCancel: () => this.closeMenu(),
		};

		this.svcDrawerPopover.open(title, "60%", this.anchorElement, content, contentInputs, null, anchorRect);
	}

	onConfirmDateChange(selectedDate: Date) {
		this.typeModif = ETypeModifSeance.Date;
		this.seance.startDate = selectedDate;
		this.updateSeance();
	}

	public changerInfirmier(event?: Event): void {
		let anchorRect: DOMRect = null;
		if (event) {
			const element: HTMLElement = event.currentTarget as HTMLElement;
			anchorRect = element.getBoundingClientRect();
		}
		this.closeMenu();
		this.svcDrawerPopover.open(
			"Affectation soins",
			"60%",
			this.anchorElement,
			AffectationSoinsComponent,
			{
				confirm: (selectedInfirmierId) => {
					this.confirmModifInfirmier(selectedInfirmierId);
				},
				cancel: () => this.closeMenu(),
			},
			null,
			anchorRect
		);
	}

	confirmModifInfirmier(selectedInfirmierId: string) {
		this.typeModif = ETypeModifSeance.Infirmier;
		this.seance.infirmierId = selectedInfirmierId;
		this.openOccurence()
	}

	confirmPatientIndispo(commentaireIndisponibilitePatient: string) {
		this.seance.commentaireIndisponibilitePatient = commentaireIndisponibilitePatient;
		this.seance.infirmierId = ContactsService.getContactIdFromUserId(UserData.current._id);
		this.seance.status = EStatusSeance.canceled;
		this.seance.actes.map((acte: Acte) => {
			acte.etat = EEtatActe.not_done
		})
		this.updateSeance();
	}

	public deletePatientDispo() {
		delete this.seance.commentaireIndisponibilitePatient;
		this.seance.status = EStatusSeance.to_be_done;
		this.seance.actes.map((acte: Acte) => {
			acte.etat = EEtatActe.to_be_done
		})
		this.updateSeance();

	}

	public updateConcurrentSeances(seancesConcurrentes: StoredSeance[]) {
		let seanceUpdates = [];
		if (ArrayHelper.hasElements(seancesConcurrentes)) {
			seanceUpdates.push(...seancesConcurrentes.map((seance: StoredSeance) => {
				return this.svcSeance.updateSeance(seance);
			}));
		}
		forkJoin(seanceUpdates).pipe(
			tap(() => {
				this.closeMenu();
				this.svcSeance.triggerSeanceUpdated(this.seance._id);
			}),	
		).subscribe();
	}

	private updateSeance() {

		switch (this.typeModif) {
			case ETypeModifSeance.Heure:
			case ETypeModifSeance.Infirmier:
			default:
				this.svcSeance.updateSeance(this.seance).pipe(
					tap(() => {
						this.closeMenu();
					}),
				).subscribe();
				break;
			case ETypeModifSeance.Actes:
				this.updateActesSeance(this.seance, this.patient).pipe(
					tap(() => {
						this.closeMenu();
					}),
				).subscribe();
				break;
		}
	}

	private updateSeances(onlyFutur: boolean) {
		const seances$ = onlyFutur
			? this.svcSeance.getFuturSeances(this.seance)
			: this.svcSeance.getSeancesNonFactures(this.seance);
		seances$.pipe(
			switchMap((seances: StoredSeance[]) => {

				let seanceUpdates = [];

				let seancesUpdated: StoredSeance[] = [];
				seances.forEach((seance: StoredSeance) => {
					switch (this.typeModif) {
						case ETypeModifSeance.Heure:
							const moment: IDayRepetition = this.seance.moment;
							seance.moment = moment
							if (moment.type == "range") {
								seance.startDate = StoredSeance.getStartDateFromMoment(StoredSeance.determineMoment(moment) as EMoments, seance.startDate)
							}
							if (moment.type == "hours-minutes") {
								seance.startDate = StoredSeance.getStartDateFromMoment(moment as HoursMinutesRepetition, seance.startDate)
							}
							seanceUpdates.push(this.svcSeance.updateSeance(seance));
							break;
						case ETypeModifSeance.Infirmier:
							const infirmierId: string = this.seance.infirmierId;
							seance.infirmierId = infirmierId
							seanceUpdates.push(this.svcSeance.updateSeance(seance));
							break;
						case ETypeModifSeance.Actes:
							seance = this.svcSeance.updateActesSeance(seance, this.modifActesSeance)
							seance.actes = seance.actes.map((acte: Acte) => new Acte(acte))
							seancesUpdated.push(seance)
							break;
					}
				});
				if (this.typeModif === ETypeModifSeance.Actes) {
					seancesUpdated.forEach((seance: StoredSeance) => {
						seanceUpdates.push(this.svcSeance.updateSeance(seance));
					});
					return forkJoin(seanceUpdates);
				}
				if (seanceUpdates.length > 0) {
					return forkJoin(seanceUpdates);
				} else {
					return of(false);
				}
			})
		).subscribe()
	}

	// Met à jour les actes d'une séance et recalcul les cotations
	public updateActesSeance(seance: StoredSeance, patient: IPatient): Observable<boolean> {
		seance = this.svcSeance.updateActesSeance(seance, this.modifActesSeance)
		return this.svcSeance.updateSeance(seance)
	}

	public editActes(): void {
		this.closeMenu()
		if (this.isMobile) {
			this.svcPanneau.close();
			this.svcDrawerPopover.open("Modifier les actes",
				"60%",
				null,
				ModifierActesComponent,
				{
					seance: this.seance,
					confirm: (seance: StoredSeance) => {
						this.seance = seance;
						this.typeModif = ETypeModifSeance.Actes;
						this.openOccurence();
					},
					cancel: () => this.closeMenu()
				})
		} else {
			const panneauTitle = "Modifier les actes";
			const panneauContent = ModifierActesComponent;
			const panneauInputs = {
				seance: this.seance,
				confirm: (seance: StoredSeance, modifActesSeance: IModifActesSeance) => {
					this.modifActesSeance = modifActesSeance;
					this.seance = seance;
					this.typeModif = ETypeModifSeance.Actes;
					this.openOccurence();
				},
				cancel: () => this.closeMenu()
			};
			this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);
		}
	}


	public openMenuSoins(): void {
		const clonedSeance = cloneDeep(this.seance);
		//open menu soins
		this.closeMenu()
		//open menu edit actes
		if (this.isMobile) {
			this.svcPanneau.close();
			this.svcDrawerPopover.open("Soins réalisés",
				"90%",
				null,
				SoinsRealisesComponent,
				{
					seance: clonedSeance,
					patient: this.patient,
					updateConcurrentSeances: (seancesConcurrentes: StoredSeance[]) => {
						this.updateConcurrentSeances(seancesConcurrentes);
					},
					cancel: () => this.closeMenu(),
				})
		} else {
			const panneauTitle = "Soins réalisés";
			const panneauContent = SoinsRealisesComponent;
			const panneauInputs = {
				seance: clonedSeance,
				patient: this.patient,
				updateConcurrentSeances: (seancesConcurrentes: StoredSeance[]) => {
					this.updateConcurrentSeances(seancesConcurrentes);
				},
				cancel: () => this.closeMenu(),
			};
			this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);

		}
	}

	public editPatient(event?: Event): void {
		let anchorRect: DOMRect = null;
		if(event) {
			const element: HTMLElement = event.currentTarget as HTMLElement;
			anchorRect = element.getBoundingClientRect();
		}
		this.closeMenu();
		this.svcDrawerPopover.open(
			"Affectation soins",
			"60%",
			this.anchorElement,
			PatientIndisponibleComponent,
			{
				seance: this.seance,
				confirm: (commentaire: string) => {
					this.confirmPatientIndispo(commentaire);
				},
				deletePatientIndispo: () => {
					this.deletePatientDispo();
				},
				cancel: () => this.closeMenu(),
			},
			null,
			anchorRect
		);
	}

	public editLocal(): void {
		//open menu changer local
	}

	public afficheOrdo(): void {
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['ordonnances/edit/', this.seance.traitementId], { state: { tabSelected: "Prescription" } });
		});
	}

	public ouvrirDossierPatient(): void {
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(["patients", this.seance.patientId], { state: { tabSelected: "Informations" } })
		});
	}

	handleSupprimerClick(event: MouseEvent) {
		const element: HTMLElement = event.currentTarget as HTMLElement;
		const anchorRect: DOMRect = element.getBoundingClientRect();
		this.showPopover = true;
		this.svcDrawerPopover.open(
			"Confirmation de suppression",
			"50%",
			this.anchorElement?.nativeElement,
			ConfirmationSuppressionComponent,
			{
				onConfirm: () => this.onDeleteSeance(),
				onCancel: () => this.closeMenu(),
			},
			() => (this.showPopover = false),
			anchorRect
		);
	}

	handleFacturationClick(event: Event) {
		this.closeMenu()
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(["facturation/controle", this.seance.traitementId])
		});
	}

	public onDeleteSeance() {
		this.svcSeance.deleteSeance(this.seance).subscribe(
			() => this.closeMenu(),
			//todo ajout d'un snackbar pour notification du delete
			(error) => {
				console.error('Erreur lors de la suppression de la séance', error);
			}
		);
	}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
		this.svcPanneau.close();
	}


	openOccurence(): void {
		this.closeMenu();
		const drawerPanneauTitle = "Quelle(s) séance(s) modifier";
		const drawerPanneauContent = ChoixSeanceComponent;
		const drawerPanneauInputs = {
			seance: this.seance,
			onSave: this.confirmOccurence,
			onCancel: this.cancelOccurence,
		};
		if (this.isMobile) {
			this.svcPanneau.close();
			this.svcDrawerPopover.open(
				drawerPanneauTitle,
				"85%",
				null,
				drawerPanneauContent,
				drawerPanneauInputs
			);
		} else {
			this.svcPanneau.open(
				drawerPanneauTitle,
				drawerPanneauContent,
				drawerPanneauInputs
			);
		}
	}

	confirmOccurence = (option) => {
		switch (option) {
			case ESeanceOptionModification.Seance:
				this.updateSeance();
				break;
			case ESeanceOptionModification.AllSeances:
				this.updateSeances(false);
				break;
			case ESeanceOptionModification.OnlyFutur:
				this.updateSeances(true);
				break;
		}
		this.closeMenu();
	};

	cancelOccurence = () => {
		this.closeMenu();
	};

	addSeance() {
		this.closeMenu();
		const drawerPanneauTitle = "Ajouter une séance";
		const drawerPanneauContent = AjouterSeanceComponent;
		if (this.isMobile) {
			const drawerPanneauInputs = {
				seance: this.seance,
				close: () => this.svcDrawerPopover.close()
			};
			this.svcPanneau.close();
			this.svcDrawerPopover.open(
				drawerPanneauTitle,
				"80%",
				null,
				drawerPanneauContent,
				drawerPanneauInputs
			);
		} else {
			const drawerPanneauInputs = {
				seance: this.seance,
				close: () => this.svcPanneau.close()
			};
			this.svcPanneau.open(
				drawerPanneauTitle,
				drawerPanneauContent,
				drawerPanneauInputs
			);
		}
	}
}
