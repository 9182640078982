import { Component, OnInit } from '@angular/core';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { EInvoiceType } from '../../../modules/facturation/models/einvoice-type.enum';
import { Invoice } from '../../../modules/facturation/models/invoice';
import { DeviceService } from '../../features/shared/services/device.service';
import { FacturationService } from 'apps/idl/src/modules/facturation/facturation.service';
import { switchMap, tap } from 'rxjs/operators';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { LoaderService } from '../../features/shared/services/loader.service';
import { PanneauService } from '../../features/shared/services/panneau.service';
import { PanneauMenuMoisComponent } from './panneau-menu-mois/panneau-menu-mois.component';
import { forkJoin } from 'rxjs';
import { ContactsService } from '@osapp/services';

@Component({
	selector: 'di-suivi-facturation',
	templateUrl: './suivi-facturation.page.html',
	styleUrls: ['./suivi-facturation.page.scss'],
})
export class SuiviFacturationPage extends DestroyableComponentBase implements OnInit {
	public filterOptions = [
		{ label: 'Infirmier', value: 'Infirmier' },
		{ label: 'Sécurité sociale', value: 'Sécurité sociale' },
		{ label: 'Mutuelle', value: 'Mutuelle' },
		{ label: 'Part patient', value: 'Part patient' }
	]

	public nbInvoices: number = 31;

	public libelleNbInvoices: string = "";
	public libelleNbInvoicesFiltered: string = "";
	public montantTotalInvoice: number = 0;
	public invoices: Invoice[] = [];
	public filteredInvoices: Invoice[] = [];

	public patientsMap: Map<string, IPatient> = new Map();

	public filters = []
	public searchedValue: string = "";

	public isMobileView: boolean = false;
	public mainTextAucuneFacture: string = "Aucune facture trouvés";
	public subTextAucuneFacture = "Essayer de modifier votre recherche";

	public dateMonthDisplayed: string;

	public showMenu: boolean;

	constructor(
		private svcDevice: DeviceService,
		private svcFacturation: FacturationService,
		private svcPatient: PatientsService,
		private svcContact: ContactsService,
		private svcLoader: LoaderService,
		private svcPanneau: PanneauService
	) {
		super();
	}
	ngOnInit(): void {
		this.svcDevice.isMobile$.subscribe((flag: boolean) => {
			this.isMobileView = flag;
		});

		// this.svcFacturation.invoiceChanges$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
		// 	this.initValuePanneau();    
		//   });

		const today = new Date();
		this.initListeFactures(today.getMonth(), today.getFullYear());
	}

	public initListeFactures = (mois: number, annee: number) => {
		this.dateMonthDisplayed = new Date(annee, mois).toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' });
		this.svcLoader.showLoader("Chargement des factures...");
		this.svcFacturation.getInvoicesByMonth(mois, annee)
			.pipe(
				tap((invoices: Invoice[]) => {
					this.invoices = invoices;
				}),
				switchMap(() => {
					let patientIdsSet: Set<string> = new Set();
					let contactIdsSet: Set<string> = new Set();

					this.invoices.forEach(inv => {
						if (inv.patientId) {
							patientIdsSet.add(inv.patientId);
						}

						// Récupération de l'intervenantId de chaque invoice
						if (inv.intervenantId) {
							contactIdsSet.add(inv.intervenantId);
						}
					});

					let patientIds = Array.from(patientIdsSet).filter(id => id);
					let contactIdsTab = Array.from(contactIdsSet).filter(id => id);

					// Appels en parallèles pour récupérer les patients et les contacts
					return forkJoin({
						patients: this.svcPatient.getPatientsByIds(patientIds),
						infirmiers: this.svcContact.getContactsByIds(contactIdsTab)
					});
				}),
				tap(({ patients, infirmiers }) => {
					this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));

					// Mappage des infirmiers pour accéder rapidement via leur ID
					let infirmiersMap = new Map(infirmiers.map(inf => [inf._id, inf]));

					// Assignation des patients et auteurs
					this.invoices.forEach(invoice => {
						invoice.infirmier = infirmiersMap.get(invoice.intervenantId);
						invoice.patient = this.patientsMap.get(invoice.patientId);
					});

					this.filteredInvoices = this.invoices;
					this.getLibelleNbInvoice();
					this.getMontantTotalInvoice();
					this.svcLoader.hideLoader();
				}),
				tap(() => {
					if (this.isMobileView) {
						this.svcPanneau.close();
					}
				})
			)
			.subscribe();
	}

	public getLibelleNbInvoice() {
		this.libelleNbInvoices = (this.filteredInvoices?.length || 0) + ' facture' + (this.filteredInvoices.length > 1 ? 's' : '');
	}

	public getMontantTotalInvoice() {
		this.montantTotalInvoice = this.filteredInvoices.reduce((totalInvoice, invoice) => {
			// Addition des honoraires de chaque acte dans la facture
			const totalActes = invoice.actes.reduce((totalActe, acte) => {
				return totalActe + (acte.honoraires || 0); // Sécurité si honoraires est absent
			}, 0);
			if (invoice.invoiceType === EInvoiceType.avoir) {
				return totalInvoice - totalActes;
			} else {
				return totalInvoice + totalActes;
			}
		}, 0);
	}

	public openPanneauFactureMois() {
		const panneauTitle = "Suivi des factures";
		const panneauContent = PanneauMenuMoisComponent;
		const panneauInputs = {
			onMonthSelected: this.initListeFactures
		};
		this.svcPanneau.open(
			panneauTitle,
			panneauContent,
			panneauInputs
		);
	}

	onfiltersChange(filters: any[]) {

		this.filters = filters
		//to do add a filter 
	}

	public filterInvoices(searchValue: string) {
		this.searchedValue = searchValue.trim().toLowerCase();
		if (this.searchedValue && this.searchedValue.length > 2) {
			const searchTerms = this.searchedValue.split(' ').filter(term => term);
			this.filteredInvoices = this.filteredInvoices.filter(invoice => {
				const patient = this.patientsMap.get(invoice.patientId);
				if (!patient) return false;

				const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
				const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
				const lastNameUsuel = patient.usualLastName ? patient.usualLastName.toLowerCase() : '';


				const matchesOnlyFirstName = searchTerms.length === 1 &&
					searchTerms[0] && firstName.includes(searchTerms[0]);

				const matchesOnlyLastName = searchTerms.length === 1 &&
					searchTerms[0] && lastName.includes(searchTerms[0]);

				const matchesOnlyLastNameusuel = searchTerms.length === 1 &&
					searchTerms[0] && lastNameUsuel.includes(searchTerms[0]);

				const matchesFirstLast = searchTerms.length === 2 &&
					firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

				const matchesLastFirst = searchTerms.length === 2 &&
					lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

				const matchesFirstLastUsuel = searchTerms.length === 2 &&
					firstName.includes(searchTerms[0]) && lastNameUsuel.includes(searchTerms[1]);

				const matchesLastusuelFirst = searchTerms.length === 2 &&
					lastNameUsuel.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

				const matchesInvoiceNumber = invoice.invoiceNumber?.startsWith(this.searchedValue);
				const matchesFSENumber = invoice.securisationData?.ref.startsWith(this.searchedValue);

				return matchesOnlyFirstName || matchesOnlyLastName || matchesOnlyLastNameusuel || matchesFirstLastUsuel || matchesLastusuelFirst || matchesFirstLast || matchesLastFirst || matchesInvoiceNumber || matchesFSENumber;
			});
		} else {
			if (searchValue === "") {
				this.filteredInvoices = this.invoices;
			}
		}

		this.getLibelleNbInvoice();
		this.getMontantTotalInvoice();
	}

	handleSelection() {
		//to do select un item de sidebar

	}
}

