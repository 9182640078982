import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateHelper } from "@osapp/helpers";
import { Invoice } from "apps/idl/src/modules/facturation/models/invoice";
import { ControlConfig } from "../../../shared/enums/ControlConfig";
import { EModesReglement } from "../../../shared/enums/EModesReglement";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";


@Component({
	selector: "di-mode-de-reglement",
	templateUrl: "./mode-de-reglement.component.html",
	styleUrls: ["./mode-de-reglement.component.scss"],
})
export class ModeDeReglementComponent implements OnInit {


	@Input() facture: Invoice;
	@Input() confirm: () => void;
	@Input() cancel: () => void;
	public modes = Object.values(EModesReglement);
	public selectedMode = this.modes[0];
	public form: FormGroup;
	public controlsConfig: ControlConfig[];

	constructor(
		private fb: FormBuilder,	
		private svcDrawerPopover: DrawerPopoverService,
		) {}

	ngOnInit(): void {
		this.controlsConfig = [
      { name: 'date', value: DateHelper.toDateUrl(new Date()) },
			{ name: 'commentaire', value: '' }
    ];

		this.form = this.fb.group({});
      this.controlsConfig.forEach(control => {
        this.form.addControl(
          control.name,
          this.fb.control(control.value, control.validators || [])
        );
      });

	}

	public handleSubmit(event: Event): void {
    event.preventDefault();
	}	
	
	
	public onConfirm() {

	}

	public onCancel() {
		this.svcDrawerPopover.close();
	}

}
