import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EEtatsFacture } from '../../../shared/enums/EEtatsFacture';
import { EEtatsReglement } from '../../../shared/enums/EEtatsReglement';

@Component({
  selector: 'di-tag-suivi-facture',
  templateUrl: './tag-suivi-facture.component.html',
  styleUrls: ['./tag-suivi-facture.component.scss']
})
export class TagSuiviFactureComponent implements OnInit {
  @Input() etat: string;
	@Input() forceLabel?: string;

  public tagLabel: string;
  public tagColor: string;
  public tagMode: string;

  // Mappage des tags en fonction de l'état
  private tagMapping = {
    [EEtatsFacture.aEnvoyer.status]: {
      tagLabel: 'A envoyer',
      tagColor: 'Noir60',
      tagMode: 'outlined',
    },
    [EEtatsFacture.papier.status]: {
      tagLabel: 'Papier',
      tagColor: 'Noir60',
      tagMode: 'outlined',
    },
    [EEtatsFacture.envoyer.status]: {
      tagLabel: 'Envoyé',
      tagColor: 'Info',
      tagMode: 'outlined',
    },
    [EEtatsFacture.paye.status]: {
      tagLabel: 'Payé',
      tagColor: 'OK',
      tagMode: 'filled',
    },
    [EEtatsFacture.rejet.status]: {
      tagLabel: 'Rejet',
      tagColor: 'Error',
      tagMode: 'filled',
    },
    [EEtatsReglement.aRegler.status]: {
      tagLabel: 'A regler',
      tagColor: 'Noir60',
      tagMode: 'outlined',
    },
    [EEtatsReglement.envoyer.status]: {
      tagLabel: 'Envoyé',
      tagColor: 'Info',
      tagMode: 'outlined',
    },
    [EEtatsReglement.paye.status]: {
      tagLabel: 'Payé',
      tagColor: 'OK',
      tagMode: 'filled',
    },
    [EEtatsReglement.impaye.status]: {
      tagLabel: 'Impayé',
      tagColor: 'Error',
      tagMode: 'filled',
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.initTagValue();
  }

	ngOnChanges(changes: SimpleChanges): void {
		this.initTagValue();
	}

	private initTagValue() {
		const tag = this.tagMapping[this.etat] || null;
		if (tag) {
			this.tagLabel = this.forceLabel ?? tag.tagLabel;
			this.tagColor = tag.tagColor;
			this.tagMode = tag.tagMode;
		} else {
			// Valeurs par défaut si aucun tag n'est trouvé
			this.tagLabel = 'Inconnu';
			this.tagColor = 'Grey';
			this.tagMode = 'outlined';
		}
	}
}
