import { Component, DoCheck, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DateHelper } from "@osapp/helpers";
import { ETimetablePattern } from "@osapp/model";
import { EPlace } from "apps/idl/src/model/EPlace";
import { ETraitementState } from "apps/idl/src/model/ETraitementState";
import { PatientsService } from "apps/idl/src/modules/patients/services/patients.service";
import { IActe } from "../../../../../model/IActe";
import { Traitement } from "../../../../../model/Traitement";
import { IPatient } from "../../../../../modules/patients/model/IPatient";
import { EEtatActe } from "../../../shared/enums/EEtatActe";
import { EEtatFacturationSeance } from "../../../shared/enums/EEtatFacturationSeance";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { MenuOrdonnanceComponent } from "../menu-ordonnance/menu-ordonnance.component";

@Component({
	selector: "di-carte-ordonnance",
	templateUrl: "./carte-ordonnance.component.html",
	styleUrls: ["./carte-ordonnance.component.scss"],
})
export class CarteOrdonnanceComponent implements OnInit, DoCheck {
	@ViewChild('menuOrdonnance', { static: false }) menuOrdonnance!: ElementRef<HTMLDivElement>;

	@Input() ordonnance?: Traitement;
	@Input() isNew = false;
	@Input() modeFacturation = false;
	@Input() onFacturerClick: (idOrdonnance: string) => void;
	@Input() patient?: IPatient;

	public etatFacturation: EEtatFacturationSeance = EEtatFacturationSeance.Default;
	public aFacturer: number = 0;
	public finishDate = "";
	public status: "active" | "finished" = "active";
	public inCabinet = false;
	public showPopover: boolean;
	public actes: IActe[] = [];

	public doneSeances: number = 0;
	public completedSeances: number = 0;
	public totalSeances: number = 0;

	// Nombre de séances completed ou done
	public currentSeance: number = 0;
	public fullLastName :string;
	
	public previouscountSeancesDoneByDateFacturation: number = -1;
	public previouscountSeancesToBeDoneByDateFacturation: number = -1;
	public previouscountSeancesCancelledByDateFacturation: number = -1;

	constructor(
		private svcDrawerPopover: DrawerPopoverService,
		private svcPatient: PatientsService,
		private router: Router
	) { }

	ngDoCheck(): void {
		if (this.ordonnance && 
			(this.ordonnance?.countSeancesDoneByDateFacturation !== this.previouscountSeancesDoneByDateFacturation
			|| this.ordonnance?.countSeancesToBeDoneByDateFacturation !== this.previouscountSeancesToBeDoneByDateFacturation
			|| this.ordonnance?.countSeancesCancelledByDateFacturation !== this.previouscountSeancesCancelledByDateFacturation)
		) {
			this.previouscountSeancesDoneByDateFacturation = this.ordonnance?.countSeancesDoneByDateFacturation;
			this.previouscountSeancesToBeDoneByDateFacturation = this.ordonnance?.countSeancesToBeDoneByDateFacturation;
			this.previouscountSeancesCancelledByDateFacturation = this.ordonnance?.countSeancesCancelledByDateFacturation
			this.etatFacturation = this.getEtatFacturation();
		}
	}

	ngOnInit() {
		this.initializeActes();
		this.initializePatient();
		this.setCountsSeances(this.ordonnance?.countSeancesDone, this.ordonnance?.countSeancesCompleted, this.ordonnance?.countSeancesTotal);
		this.setInCabinet();
	}

	private initializePatient(): void {
		this.fullLastName = this.svcPatient.getFullNamePatient(this.patient);
	}

	private getEtatFacturation(): EEtatFacturationSeance {
		if (this.totalSeances === 0) return EEtatFacturationSeance.None;
		if (this.ordonnance?.state === ETraitementState.termine || this.ordonnance?.countSeancesCompleted == this.ordonnance?.countSeancesTotal) {
			this.status = "finished";
			return EEtatFacturationSeance.All;
		}

		const done = this.ordonnance?.countSeancesDoneByDateFacturation ?? 0;
		const toBeDone = this.ordonnance?.countSeancesToBeDoneByDateFacturation ?? 0;
		const cancelled = this.ordonnance?.countSeancesCancelledByDateFacturation ?? 0;

		this.aFacturer = done + toBeDone + cancelled;

		const result = done + toBeDone + cancelled === 0
				? EEtatFacturationSeance.None
				: EEtatFacturationSeance.Some;

		return result;
	}

	private initDateFinTraitement() {
		if (this.ordonnance?.endDate && this.totalSeances > 0) {
			this.finishDate = DateHelper.transform(this.ordonnance?.endDate, ETimetablePattern.dd_MM_yyyy_slash);
		}
	}

	public handleCarteClick (event : Event)
	{
		this.router.navigate(["ordonnances", "edit", this.ordonnance._id]);
	}

	private initializeActes(): void {
		if (this.ordonnance?.actes) {
			this.ordonnance.actes.forEach(x => {
				x.etat = EEtatActe.to_be_done;
			});
			this.actes = [...this.ordonnance.actes];		
		}
	}

	private setCountsSeances(done: number, completed: number, total: number) {
		this.doneSeances = done ?? 0;
		this.completedSeances = completed ?? 0;
		this.totalSeances = total ?? 0;
		this.currentSeance = this.doneSeances + this.completedSeances;
		if (this.modeFacturation)
			this.etatFacturation = this.getEtatFacturation();
		this.initDateFinTraitement();
	}

	handleFacturerClick() {
		if (this.ordonnance?.state !== ETraitementState.termine && this.aFacturer > 0 && this.onFacturerClick) {
			this.onFacturerClick(this.ordonnance._id);
		}
	}

	getSoinNumber() {
		const remaining = this.totalSeances - this.currentSeance;
		if (this.currentSeance === 0) {
			return "1er soin";
		}
		if (remaining === 1) {
			return "Dernier soin";
		}
		if (remaining > 1 && remaining <= 7) {
			return `J-${remaining}`;
		}
		return "";
	}

	getProgressColor() {
		if (Number(this.currentSeance) === 0 && Number(this.totalSeances) === 0) {
			return "Gris"
		}

		if (Number(this.currentSeance) > Number(this.totalSeances) / 2) {
			return "Raspberry";
		} else {
			return "CouleurPrimaire";
		}
	}

	public getAdressePatient(pat: IPatient): string {
		const addressParts: string[] = [];
		if (pat.street) {
			addressParts.push(pat.street);
		}
		if (pat.zipCode) {
			addressParts.push(pat.zipCode);
		}
		if (pat.city) {
			addressParts.push(pat.city);
		}

		return addressParts.join(' ');
	}

	public openMenu(event : Event): void {
		event.stopPropagation();
		this.showPopover = true;
		const menuOrdonnanceInputs = {
			ordonnance: this.ordonnance,
			anchorElement: this.menuOrdonnance,
			hasSeanceFacturee: this.completedSeances > 0
		}
		this.svcDrawerPopover.open("", "30%", this.menuOrdonnance?.nativeElement, MenuOrdonnanceComponent, menuOrdonnanceInputs, () => this.showPopover = false)
	}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}

	private setInCabinet() {
		if (!this.ordonnance.actes) return;
		this.inCabinet = this.ordonnance.actes.some((acte: IActe) => acte.place === EPlace.center)
	}
}
