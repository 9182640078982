<div class="page-ordonnances__container" [ngClass]="{ 'mode-add': mode === 'add' }">
	<div class="page-ordonnances__header">
		<div class="page-ordonnances__header-titre-btn">
			<div class="page-ordonnances__titre">
				<h1>Ordonnances</h1>
				<ng-container *ngIf="patient">
					<h4>{{fullLastName | uppercase}} {{patient.firstName | firstUpperCase}} </h4>
					<small>
						{{ patient.street ? patient.street + (patient.city ? ', ' + patient.city : '') : patient.city || '' }}
					</small>
				</ng-container>
				<lua-alerte *ngIf="ordonnance && ordonnance.stopDate" type="warning"
					[message]="'Ordonnance arrêtée : '+ordonnance.stopComment"></lua-alerte>
			</div>
			<ng-container *ngIf="mode === 'add'">
				<div class="page-ordonnances__btn-add-ordonance">
					<lua-stepper [stepNames]="stepNames" (onStepClick)="onStepChange($event)" [currentStep]="currentStep"
						[cliquable]="true">
					</lua-stepper>
				</div>
			</ng-container>

			<div *ngIf="mode === 'edit'">

				<div class="button-menu">
					<lua-bouton-icone iconName="more_vert" size="medium" (click)="openMenu($event)"></lua-bouton-icone>
				</div>
			</div>
		</div>
		<ng-container *ngIf="mode === 'edit'">
			<lua-tab-group [selectTab]="handleTabSelection">
				<lua-tab label="Prescription" [active]="!tabSelected || tabSelected === 'Prescription'">
					<ng-container *ngIf="ordonnance && patient">
						<di-form-ordonnance [mode]="mode" [ordonnance]="ordonnance" [medecin]="medecin"
							(updateOrCreateOrdonnance)="handleSaveOrdonnance($event)"></di-form-ordonnance>
					</ng-container>
				</lua-tab>
				<lua-tab label="Actes" [active]="tabSelected === 'Actes' || showTabActe">
					<ng-container *ngIf="showTabActe">
						<di-liste-actes [mode]="mode" [generateSeances]="handleGenerateSeances"
							[ordonnance]="ordonnance"></di-liste-actes>
					</ng-container>
				</lua-tab>
				<lua-tab label="Séances" [active]="tabSelected === 'Séances' || showTabSeances">
					<ng-container *ngIf="showTabSeances">
						<di-liste-seance-ordonnance [ordonnance]="ordonnance" [patient]="patient"
							[mode]="mode"></di-liste-seance-ordonnance>
					</ng-container>
				</lua-tab>
				<lua-tab label="Factures" [active]="tabSelected === 'Factures' || showTabFactures">
					<ng-container *ngIf="showTabFactures">
						<di-liste-facture-ordonnance [ordonnance]="ordonnance" [patient]="patient"
							[mode]="mode"></di-liste-facture-ordonnance>
					</ng-container>
				</lua-tab>
			</lua-tab-group>
		</ng-container>
	</div>
	<ng-container *ngIf="mode === 'add'">
		<ng-container *ngIf="currentStep === 0">
			<di-form-ordonnance *ngIf="isInit" [patient]="patient" [mode]="mode" [medecin]="medecin" [ordonnance]="ordonnance"
				[ordonnanceOrigine]="ordonnanceOrigineId ? ordonnanceOrigine : null"
				(updateOrCreateOrdonnance)="handleSaveOrdonnance($event)">
			</di-form-ordonnance>
		</ng-container>
		<ng-container *ngIf="currentStep === 1">
			<di-liste-actes [mode]="mode" [generateSeances]="handleGenerateSeances" [ordonnance]="ordonnance"
				[updateOrdonnance]="setOrdonnance"></di-liste-actes>
		</ng-container>
		<ng-container *ngIf="currentStep === 2">
			<di-liste-seance-ordonnance [patient]="patient" [mode]="mode"
				[ordonnance]="ordonnance"></di-liste-seance-ordonnance>
		</ng-container>
	</ng-container>
</div>