import { Component, Input, OnInit } from '@angular/core';
import { IViewCountResult } from '@osapp/model/IViewCountResult';
import { FacturationService } from 'apps/idl/src/modules/facturation/facturation.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'di-menu-mois',
	templateUrl: './menu-mois.component.html',
	styleUrls: ['./menu-mois.component.scss'],
})
export class MenuMoisComponent implements OnInit {
	@Input() public onMonthSelected: (month: number, year: number) => void = () => {};

	public selectedYear: number;
	public selectedMonth: number;
	public years: Map<number, Map<number, { montantTotal: number; invoiceCount: number }>> = new Map();
	public yearsArray: {
		year: number;
		months: {
			month: number;
			montantTotal: number;
			invoiceCount: number;
		}[];
	}[];

	constructor(private svcFacturation: FacturationService) { }

	ngOnInit(): void {
		const now = new Date();
		this.selectedYear = now.getFullYear();
		this.selectedMonth = now.getMonth();
		const previousMonths: { year: number, month: number }[] = this.getPreviousMonths();
		const requests = previousMonths.map(({ year, month }) =>
			this.svcFacturation.getTotalHonorairesByMonth(month, year).pipe(
				map((result: IViewCountResult[]) => ({ year, month, result }))
			)
		);

		forkJoin(requests).subscribe((responses) => {
			responses.forEach(({ year, month, result }) => {
				if (!this.years.has(year)) {
					this.years.set(year, new Map());
				}
				const yearMap = this.years.get(year);
				let montantTotal: number = 0;
				let invoiceCount: number = 0;
				result.forEach((viewCount: IViewCountResult) => {
					montantTotal += viewCount.value;
					invoiceCount++;
				});
				if (invoiceCount > 0) {
					yearMap?.set(month, { montantTotal, invoiceCount });
				}
			});
			this.yearsArray = Array.from(this.years.entries()).map(([year, monthsMap]) => ({
				year,
				months: Array.from(monthsMap.entries()).map(([month, data]) => ({
					month,
					...data
				}))
				.sort((a, b) => b.month - a.month),
			}))
			.sort((a, b) => b.year - a.year);;
		});
	}

	private getPreviousMonths(count = 12): { year: number, month: number }[] {
		const result: { year: number, month: number }[] = [];
		let year = this.selectedYear;
		let month = this.selectedMonth;
		for (let i = 0; i < count; i++) {
			result.push({ year, month });
			month--;
			if (month === -1) {
				month = 11;
				year--;
			}
		}
		return result;
	}

	public wrappedOnMonthSelected = (event: { month: number; year: number }) => {
		this.selectedMonth = event.month;
		this.selectedYear = event.year;
		this.onMonthSelected(event.month, event.year);
	}
}

