<div class="lecture-carte__container">
	<div class="lecteurs__container">
		<h4>Lecteur carte vitale</h4>
		<ng-container *ngIf="listTerminal">
			<ng-container *ngFor="let lecteurItem of listTerminal">
				<lua-option-data-picker [label]="lecteurItem.name" [fullWidth]="true" iconName="smart_card_reader"
					[isSelected]="isLastTerminal(lecteurItem)" (click)="onSelectTerminal(lecteurItem)"></lua-option-data-picker>
			</ng-container>
		</ng-container>
	</div>
	<lua-bouton-texte *ngIf="!isSelected" libelle="Je n’ai pas de lecteur de carte"
		(click)="onClose()"></lua-bouton-texte>
	<lua-message-action-en-cours *ngIf="chargementLecteur" mainText="Chargement du lecteur" subText="" [loaderSize]="60"
		[loaderSpeed]="1"></lua-message-action-en-cours>
	<ng-container *ngIf="isReady && !chargementLecteur">
		<div class="container__row">
			<lua-icone iconName="check_circle" mode="filled" [color]="terminal?.terminalConnected ? 'Vert' : 'Gris'">
			</lua-icone>
			<div class="double-ligne">
				<strong>Lecteur connecté</strong>
				<p *ngIf="!terminal?.terminalConnected">Vérifiez qu’il est allumé et connecté</p>
			</div>
		</div>
		<ng-container *ngIf="!terminal?.terminalConnected">
			<lua-action-secondaire [isDisabled]="!isSelected" libelle="Réessayer"
				(click)="onReadyLecteur()"></lua-action-secondaire>
			<lua-bouton-texte libelle="Je n’ai pas de lecteur de carte" (click)="onClose()"></lua-bouton-texte>
		</ng-container>
		<div class="container__row">
			<lua-icone iconName="check_circle" mode="filled" [color]="terminal?.cpsInserted ? 'Vert' : 'Gris'">
			</lua-icone>
			<div class="double-ligne">
				<strong [ngClass]="{'disabled' :!terminal?.cpsInserted}">Lecture carte CPS</strong>
				<p *ngIf="terminal?.terminalConnected && !terminal?.cpsInserted">Insérer la carte CPS</p>
			</div>
		</div>
		<ng-container *ngIf="terminal?.terminalConnected && !terminal?.cpsInserted">
			<lua-action-secondaire [isDisabled]="!isSelected" libelle="Réessayer"
				(click)="onReadyLecteur()"></lua-action-secondaire>
			<lua-bouton-texte libelle="Je n'ai pas de cps" (click)="onClose()"></lua-bouton-texte>
		</ng-container>
		<ng-container *ngIf="!adri">
			<div class="container__row">
				<lua-icone iconName="check_circle" mode="filled" [color]="terminal?.vitaleInserted ? 'Vert' : 'Gris'">
				</lua-icone>
				<div class="double-ligne">
					<strong [ngClass]="{'disabled' :!terminal?.vitaleInserted}">Lecture carte vitale</strong>
					<p *ngIf="terminal?.terminalConnected && terminal?.cpsInserted && !terminal?.vitaleInserted">Insérer la carte
						vitale
						du patient</p>
				</div>
			</div>
			<ng-container *ngIf="terminal?.terminalConnected && terminal?.cpsInserted && !terminal?.vitaleInserted">
				<lua-action-secondaire [isDisabled]="!isSelected" libelle="Réessayer"
					(click)="onReadyLecteur()"></lua-action-secondaire>
				<lua-bouton-texte libelle="Je n'ai pas de carte vitale" (click)="onClose()"></lua-bouton-texte>
			</ng-container>
			<ng-container *ngIf="choixPatient">
				<div class="container__row">
					<lua-icone iconName="check_circle" mode="filled" color="Gris"> </lua-icone>
					<strong>Choix de l'assuré ou bénéficiaire</strong>
				</div>
				<div *ngIf="assure || beneficiaires">
					<h4>Assuré</h4>
					<button *ngIf="assure" type="button" (click)="onPatientSelect(assure)" class="container__patient-button">
						<div class="patient-button__content">
							<p>{{ assure.lastName }} {{ assure.firstName }}</p>
							<p>{{ assure.socialNumber }}</p>
						</div>
					</button>
					<h4>Bénéficiaires</h4>
					<div *ngIf="beneficiaires">
						<button *ngFor="let patient of beneficiaires" type="button" (click)="onPatientSelect(patient)"
							class="container__patient-button">
							<div class="patient-button__content">
								<p>{{ patient.lastName }} {{ patient.firstName }}</p>
								<p>{{ patient.socialNumber }}</p>
							</div>
						</button>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
</div>