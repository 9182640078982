import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotAuthenticatedGuard } from "@osapp/guards";
import { AuthenticatedGuard } from "./core/guards/authenticated.guard";
import { FormContactsComponent } from './features/contacts/components/form-contacts/form-contacts.component';
import { AppInfoPage } from "./pages/app-info/app-info.page";
import { AuthenticatorComponent } from "./pages/authenticator";
import { CompteBloquePage } from "./pages/connexion/compte-bloque/compte-bloque.page";
import { ConfirmationEmailPage } from "./pages/connexion/confirmation-email/confirmation-email.page";
import { ConfirmationMdpChangePage } from "./pages/connexion/confirmation-mdp-change/confirmation-mdp-change.page";
import { LienExpirePage } from "./pages/connexion/lien-expire/lien-expire.page";
import { ChangePasswordPage } from "./pages/connexion/password-change/password-change.page";
import { PasswordDemandePage } from "./pages/connexion/password-demande/password-demande.page";
import { SelectionCabinetPage } from "./pages/connexion/selection-cabinet/selection-cabinet.page";
import { WorkspaceSelectionPage } from "./pages/connexion/workspace-selection/workspace-selection.page";
import { ContactsPage } from "./pages/contacts/contacts.page";
import { ControleFacturationPage } from "./pages/facturation/controle-facturation/controle-facturation.component";
import { FacturationPage } from "./pages/facturation/facturation.page";
import { HomePage } from "./pages/home/home.page";
import { MonComptePage } from "./pages/mon-compte/mon-compte.page";
import { DetailOrdonnancePage } from "./pages/ordonnance/detail-ordonance/detail-ordonnance.page";
import { OrdonnancePage } from "./pages/ordonnance/ordonances/ordonnance.page";
import { CreationPatientPage } from "./pages/patients/creation-patient/creation-patient.page";
import { DetailPatientPage } from "./pages/patients/detail-patient/detail-patient.page";
import { PatientsPage } from "./pages/patients/patients/patients.page";
import { PlanningPage } from "./pages/planning/planning.page";
import { TeletransmissonPage } from "./pages/teletransmisson/teletransmisson.page";
import { TourneesPage } from "./pages/tournees/tournees.page";
import { TransmissionsPage } from "./pages/transmissions/transmissions.page";
import { ParametresPage } from "./pages/parametres/parametres.page";
import { SuiviFacturationPage } from "./pages/suivi-facturation/suivi-facturation.page";


export const routes: Routes = [
	{ path: "", redirectTo: "home", pathMatch: "full" },
	{
		path: "home",
		component: HomePage,
		data: {},
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "authenticator",
		component: AuthenticatorComponent,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "confirmationEmail",
		component: ConfirmationEmailPage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "confirmationMdpChange",
		component: ConfirmationMdpChangePage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "lienExpire",
		component: LienExpirePage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "selectionWorkspace",
		component: WorkspaceSelectionPage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "selectionCabinet",
		component: SelectionCabinetPage,
		data: {},
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "passwordDemande",
		component: PasswordDemandePage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "passwordChange",
		component: ChangePasswordPage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "compteBloque",
		component: CompteBloquePage,
		data: {},
		canActivate: [NotAuthenticatedGuard],
	},
	{
		path: "patients",
		data: {},
		canActivate: [AuthenticatedGuard],
		children: [
			{ path: "", component: PatientsPage },
			{ path: "add", component: CreationPatientPage },
			{ path: ":idPatient", component: DetailPatientPage },
			{ path: "edit/:idPatient", component: CreationPatientPage },
		]
	},
	{
		path: "contacts",
		component: ContactsPage,
		data: {},
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "contacts/add",
		component: FormContactsComponent,
		data: { mode: "add", contact: null },
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "contacts/add/:idRetourPatient",
		component: FormContactsComponent,
		data: { mode: "add", contact: null },
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "contacts/edit/:id",
		component: FormContactsComponent,
		data: { mode: "edit" },
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "mon-compte",
		component: MonComptePage,
		data: {},
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "app-info",
		component: AppInfoPage,
		data: {},
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "transmissions",
		children: [
			{ path: "", component: TransmissionsPage, data: {}, canActivate: [AuthenticatedGuard] },
		]
	},
	{
		path: "planning",
		children: [
			{ path: "", component: PlanningPage, data: {}, canActivate: [AuthenticatedGuard] },
		]
	},
	{
		path: "ordonnances",
		data: {},
		canActivate: [AuthenticatedGuard],
		children: [
			{ path: "", component: OrdonnancePage, },
			{ path: "add", component: DetailOrdonnancePage },
			{
				path: "edit/:id",
				component: DetailOrdonnancePage,
				data: { mode: "edit" },
				canActivate: [AuthenticatedGuard],
			},
		]
	},
	{
		path: "tournees",
		data: {},
		component: TourneesPage,
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "teletransmission",
		data: {},
		component: TeletransmissonPage,
		canActivate: [AuthenticatedGuard],
	},
	{
		path: "facturation",
		data: {},
		canActivate: [AuthenticatedGuard],
		children: [
			{ path: "", component: FacturationPage },
			{
				path: "controle/:id",
				component: ControleFacturationPage,
				data: {},
				canActivate: [AuthenticatedGuard],
			},
		]
	},
	{
		path: 'parametres',
		data: {},
		component: ParametresPage,
		canActivate: [AuthenticatedGuard],
	},
	{
		path: 'suivi-facturation',
		data: {},
		component: SuiviFacturationPage,
		canActivate: [AuthenticatedGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AnakinRoutingModule { }
