<div class="di-suivi-facturation">
	<ng-container *ngIf="!isMobileView">
		<div class="menu-mois-container">
			<h1>Suivi facturation</h1>
			<di-menu-mois [onMonthSelected]="initListeFactures"></di-menu-mois>
		</div>
	</ng-container>

	<main class="facturation-content">
		<header class="facturation-header">
			<div class="titre">
				<div class="soustitre">
					<h1>{{ dateMonthDisplayed | firstUpperCase }}</h1>
					<small>{{libelleNbInvoices}} - {{montantTotalInvoice | currency: 'EUR' : 'symbol' : '1.2-2' : 'fr-FR' }}</small>
				</div>
				<ng-container *ngIf="isMobileView">
					<lua-bouton-icone (onClick)="openPanneauFactureMois()" iconName="menu" mode="outlined"></lua-bouton-icone>
				</ng-container>
			</div>
			<div class="filters">
				<lua-data-picker *ngIf="!isMobileView" [options]="filterOptions" [fullWidth]="true" [selectedOptions]="filters"
					[multiSelection]="true" (selectedOptionsChange)="onfiltersChange($event)"></lua-data-picker>
				<lua-data-picker *ngIf="isMobileView" [options]="[filterOptions[0]]" [fullWidth]="true"
					[selectedOptions]="filters" [multiSelection]="true"
					(selectedOptionsChange)="onfiltersChange($event)"></lua-data-picker>
				<lua-recherche 
					iconeDefault="search" 
					[secondaryAction]="false"
				 	[searchText]="searchedValue"
					(onValueChange)="filterInvoices($event)">
				</lua-recherche>
			</div>
		</header>

		<section class="facture-list">
			<ng-container *ngIf="filteredInvoices && filteredInvoices.length > 0">
				<ng-container *ngFor="let item of filteredInvoices">
					<di-carte-facturation [invoice]="item"></di-carte-facturation>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!filteredInvoices || filteredInvoices.length === 0">
				<lua-message-aucune-donnee
					[mainText]="mainTextAucuneFacture"
					[subText]="subTextAucuneFacture"
					iconName="request_quote">
				</lua-message-aucune-donnee>
			</ng-container>
		</section>
	</main>
</div>