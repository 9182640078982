import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IContact, UserData } from '@osapp/model';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { ContactsService, SecurityService } from '@osapp/services';
import { of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthenticatorService } from '../../services/authenticator.service';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { MenuCompteUtilisateurComponent } from '../menu-compte-utilisateur/menu-compte-utilisateur.component';

@Component({
	selector: "di-entete-mobile",
	templateUrl: './entete-mobile.component.html',
	styleUrls: ['./entete-mobile.component.scss'],
})
export class EnteteMobileComponent extends DestroyableComponentBase implements OnInit {

	public firstName: string;
	public color: string;
	public lastName: string;
	public previousUrl = "/authenticator";
	public currentUrl: string;
	public showPopover: boolean = false;
	public contact: IContact;


	@ViewChild('menuUtilisateur', { static: false }) menuUtilisateur!: ElementRef<HTMLDivElement>;


	constructor(
		private location: Location,
		private router: Router,
		private svcSecurity: SecurityService,
		private svcAuthenticator: AuthenticatorService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcContact: ContactsService

	) { super()}

	public ngOnInit(): void {

		this.svcContact.reloadContact$
		.pipe(
			takeUntil(this.destroyed$),
			switchMap(() => {
				this.loadContact();
				return of(true);
			})
		)
		.subscribe();


		this.loadContact();

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			});
	}

	private loadContact() {
		this.svcContact.getContact(ContactsService.getContactIdFromUserId(UserData.current._id)).pipe(
			tap((contact: IContact) => {
				this.contact = contact;
				this.lastName = this.contact.lastName;
				this.firstName = this.contact.firstName;
				this.color = this.contact.avatarCouleur ?? 'CouleurPrimaire'

			})
		).subscribe();
	}

	public getPreviousUrl(): string | undefined {
		return this.previousUrl;
	}

	public goBack(): void {
		if (this.previousUrl?.startsWith("/selectionCabinet") ||
			this.previousUrl?.startsWith("/selectionWorkspace") ||
			this.previousUrl?.startsWith("/authenticator")) {
			this.svcAuthenticator.setAuthenticationStatus(false);
			this.svcSecurity.disconnect().subscribe();
		} else {
			if (!this.previousUrl || this.previousUrl.includes(this.currentUrl))
				this.navigateToHome();
			else
				this.router.navigate([this.previousUrl]);
		}
	}

	public navigateToHome() {
		this.router.navigate(['/home']);
	}

	public isHomePage(): boolean {
		return this.router.url === '/home';
	}

	public openMenu(): void {
		this.showPopover = true;
		this.svcDrawerPopover.open("", "25%", this.menuUtilisateur?.nativeElement, MenuCompteUtilisateurComponent, {}, () => this.showPopover = false)
	}

	public closeMenu(): void {
		this.showPopover = false;
		this.svcDrawerPopover.close();
	}
}
