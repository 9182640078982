import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'di-mois-item',
	templateUrl: './mois-item.component.html',
	styleUrls: ['./mois-item.component.scss'],
})
export class MoisItemComponent implements OnInit {
	@Input() public month: number;
	@Input() public year: number;
	@Input() public invoiceCount: number;
	@Input() public montantTotal: number;
	@Input() public selected: boolean = false;
	@Output() public monthSelected = new EventEmitter<{ month: number; year: number }>();

	public monthName: string;

	constructor() {}

	ngOnInit(): void {
		this.monthName = this.getMonthName(this.month);
	}

	private getMonthName(month: number): string {
		const months = [
			'Janvier',
			'Février',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Décembre'
		];
		if (month < 0 || month > 11) {
			return 'Invalid month';
		}
		return months[month];
	}

	public selectMonth(): void {
		this.monthSelected.emit({ month: this.month, year: this.year });
	}
	
}