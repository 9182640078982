import { Injectable, ComponentRef, Type } from "@angular/core";
import { PopoverComponent } from "lighting-up-angular";
import { Subject, Subscription } from "rxjs";
import { DeviceService } from "./device.service";
import { DestroyableServiceBase } from "@osapp/modules/utils/services/destroyable-service-base";
import { takeUntil } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class PopoverService extends DestroyableServiceBase{
	
	private popoverCloseSubscription: Subscription | null = null;

	constructor(private svcDevice: DeviceService) {
		super();
		this.svcDevice.isMobile$.pipe(
			takeUntil(this.destroyed$)	
		).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});
	}

	private popoverComponentRef: ComponentRef<PopoverComponent>;
	private closePopoverSubject = new Subject<void>();
	isMobile = false;

	setPopoverComponentRef(componentRef: ComponentRef<PopoverComponent>) {
		this.popoverComponentRef = componentRef;
	}

	open(
		title: string,
		anchorElement: any,
		content: Type<any>,
		inputs: any,
		onClose?: () => void,
		anchorRect?: DOMRect
	) {
		if (this.popoverComponentRef) {
			this.popoverComponentRef.instance.setContent(content, inputs);
			this.popoverComponentRef.instance.title = title;
			this.popoverComponentRef.instance.anchorElement = anchorElement;
			this.popoverComponentRef.instance.anchorRect = anchorRect;
			this.popoverComponentRef.instance.useAnchor = !this.isMobile
			this.popoverComponentRef.instance.open = true;
			if (this.popoverCloseSubscription) {
				this.popoverCloseSubscription.unsubscribe();
			}
			this.popoverCloseSubscription = this.popoverComponentRef.instance.onPopoverClose.subscribe(() => {
				if (onClose) {
					onClose();
				}
				this.close();
			});
		}
	}

	close() {
		if (this.popoverComponentRef) {
			this.popoverComponentRef.instance.open = false;
			this.popoverComponentRef.instance.isReady = false;
			this.closePopoverSubject.next();
		}
	}

	get closePopover$() {
		return this.closePopoverSubject.asObservable();
	}
}
