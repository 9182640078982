import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Directory } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { ConvertHelper } from '@osapp/helpers/convertHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { EXTENSIONS_AND_MIME_TYPES } from '@osapp/helpers/fileHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { EPrefix } from '@osapp/model/EPrefix';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { IContact } from '@osapp/model/contacts/IContact';
import { IGroup } from '@osapp/model/contacts/IGroup';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { EFileError } from '@osapp/model/file/EFileError';
import { IBase64Data } from '@osapp/model/file/IBase64Data';
import { IFileError } from '@osapp/model/file/IFileError';
import { EPointsToUnit } from '@osapp/model/helper/EPointsToUnit';
import { FilesystemService } from '@osapp/modules/filesystem/services/filesystem.service';
import { IHydratedGroupMember } from '@osapp/modules/groups/model/IHydratedGroupMember';
import { Loader } from '@osapp/modules/loading/Loader';
import { ModalComponentBase } from '@osapp/modules/modal/model/ModalComponentBase';
import { ContactNamePipe } from '@osapp/pipes/contactName.pipe';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { PlatformService } from '@osapp/services/platform.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import domtoimage, { Options } from 'dom-to-image';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { Observable, defer, of, throwError } from 'rxjs';
import { catchError, filter, finalize, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ISeanceTournee } from '../../../../model/seances/ISeanceTournee';
import { SeanceService } from '../../../../services/seance.service';
import { IExportTourneeParams } from '../../model/IExportTourneeParams';
import { TourneeFilterService } from '../../services/tournee-filter.service';
import { TourneesService } from '../../services/tournees.service';

interface IPageContent {
	/** Titre de la page. */
	title: string;
	/** Intervenant correspondant à la liste de séances. */
	subTitile: string
	/** Tableau des items de séances pour la colonne de gauche. */
	leftColumn: ISeanceTournee[];
	/** Tableau des items de séances pour la colonne de droite. */
	rightColumn: ISeanceTournee[];
}
@Component({
	templateUrl: './export-tournee-modal.component.html',
	styleUrls: ['./export-tournee-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportTourneeModalComponent extends ModalComponentBase<IBase64Data> implements OnInit, AfterViewInit, IExportTourneeParams {

	//#region FIELDS

	private static readonly C_TOURNEE_DIRECTORY_NAME = "Tournées";
	/** Largeur du format A4 en unité 'points'. */
	private static readonly C_A4_WIDTH_POINTS_UNIT = 595.28;
	/** Hauteur du format A4 en unité 'points'. */
	private static readonly C_A4_HEIGHT_POINTS_UNIT = 841.89;
	/** Nombre d'items maximum pour une colonne. */
	private static readonly columnMaxItems = 9;
	/** Hauteur en pixels pour le format A4. */
	private static readonly a4FormatHeightInPx: number = ConvertHelper.pointsToUnit(ExportTourneeModalComponent.C_A4_HEIGHT_POINTS_UNIT, EPointsToUnit.px);
	/** Largeur en pixels pour le format A4. */
	private static readonly a4FormatWidthInPx: number = ConvertHelper.pointsToUnit(ExportTourneeModalComponent.C_A4_WIDTH_POINTS_UNIT, EPointsToUnit.px);
	/** Map répertoriant les 'ISeanceTournee' planifiées pour chaque intervenant. */
	private moSeancesTourneeByIntervenantMap: Map<string, ISeanceTournee[]> = new Map();
	/** Nom du pdf qui sera créé. */
	private pdfName: string;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public intervenants: IHydratedGroupMember[];
	/** @implements */
	@Input() public plannedSeances: ISeanceTournee[];
	/** @implements */
	@Input() public toBePlannedSeances: ISeanceTournee[];
	/** @implements */
	@Input() public autoExport?: boolean;
	/** @implements */
	@Input() public showSeancesWithoutIntervenant?: boolean;

	/** Nombre de pages totales. */
	public totalPageNumber = 1;
	/** Map des pages à afficher. */
	public pages: Map<number, IPageContent> = new Map();

	//#endregion

	//#region METHODS

	constructor(
		public isvcLoading: LoadingService,
		public isvcTournees: TourneesService,
		public isvcSeances: SeanceService,
		private isvcUiMessage: UiMessageService,
		private ioFileOpener: FileOpener,
		private ioContactNamePipe: ContactNamePipe,
		private readonly isvcFilesystem: FilesystemService,
		public isvcPlatform: PlatformService,
		poModalCtrl: ModalController,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, isvcPlatform, poChangeDetectorRef);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.init();
		this.detectChanges();
	}

	public ngAfterViewInit(): void {
		super.ngAfterViewInit();

		let loCreateDirectory$: Observable<string | void>;

		if (this.isvcPlatform.isMobileApp) {
			loCreateDirectory$ = defer(() => this.isvcFilesystem.createDirectoryAsync(`${ConfigData.environment.dms.publicPath}${ExportTourneeModalComponent.C_TOURNEE_DIRECTORY_NAME}`))
				.pipe(catchError((poError: IFileError) => poError.errorCode === EFileError.DirectoryAlreadyExist ? of(undefined) : throwError(poError)));
		}
		else
			loCreateDirectory$ = of(undefined);

		loCreateDirectory$
			.pipe(
				filter(_ => this.autoExport),
				mergeMap(_ => this.exportPdf()),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}


	/** Initialise les données nécessaires du composant. */
	private init(): void {
		const laAllSeancesTournee: ISeanceTournee[] = TourneesService.sortSeanceTournees(this.plannedSeances.concat(this.toBePlannedSeances).filter((poSeanceTournee: ISeanceTournee) => !poSeanceTournee.seance.isCanceled));

		if (ArrayHelper.hasElements(laAllSeancesTournee)) {
			this.initSeancesTourneeByIntervenantMap(laAllSeancesTournee);
			this.fillPages();

			if (this.pages.has(1)) {
				const lsTitle = `Tournée du ${DateHelper.transform(ArrayHelper.getFirstElement(this.pages.get(1).leftColumn).seance.startDate, ETimetablePattern.dd_MMM_yyyy)}`;
				this.pdfName = `${lsTitle.replace(/ /g, "_")}.${EXTENSIONS_AND_MIME_TYPES.pdf.key}`;
			}

			this.totalPageNumber = this.pages.size;
		}
		else
			this.totalPageNumber = 0;
	}

	private initSeancesTourneeByIntervenantMap(paSeances: ISeanceTournee[]): void {
		paSeances
			.forEach((poSeanceTournee: ISeanceTournee) => {
				if (ArrayHelper.hasElements(poSeanceTournee.intervenants)) {
					this.filterSeanceIntervenants(poSeanceTournee).forEach((poIntervenant: IHydratedGroupMember) =>
						this.addEntryToMap(this.moSeancesTourneeByIntervenantMap, poSeanceTournee, poIntervenant)
					);
				}
				else if (this.showSeancesWithoutIntervenant)
					this.addEntryToMap(this.moSeancesTourneeByIntervenantMap, poSeanceTournee);
			});
	}

	private filterSeanceIntervenants(poSeanceTournee: ISeanceTournee): IHydratedGroupMember<IContact | IGroup>[] {
		return poSeanceTournee.intervenants.filter((poIntervenant: IHydratedGroupMember) => this.intervenants.some((poSelectedGroupMember: IHydratedGroupMember<IContact | IGroup>) => poIntervenant.groupMember._id === poSelectedGroupMember.groupMember._id));
	}

	private addEntryToMap(poMap: Map<string, ISeanceTournee[]>, poSeanceTournee: ISeanceTournee,
		poIntervenant?: IHydratedGroupMember): void {

		let lsIntervenantName: string;

		if (poIntervenant)
			lsIntervenantName = IdHelper.hasPrefixId(poIntervenant.groupMember._id, EPrefix.contact) ?
				this.ioContactNamePipe.transform(poIntervenant.groupMember as IContact) : (poIntervenant.groupMember as IGroup).name;
		else
			lsIntervenantName = TourneeFilterService.C_NO_INTERVENANT_NAME;

		if (poMap.has(lsIntervenantName))
			poMap.get(lsIntervenantName).push(poSeanceTournee);
		else
			poMap.set(lsIntervenantName, [poSeanceTournee]);
	}

	private fillPages(): void {
		let lnPageIndex = 1;

		this.moSeancesTourneeByIntervenantMap.forEach((paSeancesTournee: ISeanceTournee[], psIntervenantLabel: string) => {
			let lnStartIndex = 0;
			let lnEndIndex: number = ExportTourneeModalComponent.columnMaxItems * 2;

			while (lnStartIndex < paSeancesTournee.length) {
				const laPageSeancesTournee: ISeanceTournee[] = paSeancesTournee.slice(lnStartIndex, lnEndIndex);
				const loFirstSeanceTournee: ISeanceTournee = ArrayHelper.getFirstElement(laPageSeancesTournee);

				const lsTitle = `Tournée du ${DateHelper.transform(loFirstSeanceTournee.seance.startDate, ETimetablePattern.dd_MMM_yyyy)}`;
				this.pages.set(lnPageIndex, this.fillPage(lsTitle, psIntervenantLabel, laPageSeancesTournee));

				lnStartIndex = lnEndIndex;
				lnEndIndex += ExportTourneeModalComponent.columnMaxItems * 2;
				lnPageIndex++;
			};
		});
	}

	private fillPage(psTitle: string, psSubTitle: string, paSeancesTournee: ISeanceTournee[]): IPageContent {
		const lnPageMaxItems: number = ExportTourneeModalComponent.columnMaxItems * 2;
		if (paSeancesTournee.length > lnPageMaxItems)
			console.error("EXP.TOURNEE.M.C:: Nombre de séances supérieur à la limite d'affichage, une ou plusieurs séances risque de ne pas être affichées.");

		const leftItems: ISeanceTournee[] = paSeancesTournee.slice(0, ExportTourneeModalComponent.columnMaxItems);
		const rightItems: ISeanceTournee[] = paSeancesTournee.slice(ExportTourneeModalComponent.columnMaxItems, lnPageMaxItems);

		return {
			title: psTitle,
			subTitile: psSubTitle,
			leftColumn: leftItems,
			rightColumn: rightItems
		};
	}

	public exportPdf(): Observable<boolean> {
		if (this.totalPageNumber === 0) {
			return this.isvcUiMessage.showAsyncMessage(
				new ShowMessageParamsPopup({
					header: "Export",
					message: `Aucune donnée de tournée à exporter.`,
					buttons: [{ text: "OK", handler: () => UiMessageService.getFalsyResponse() }]
				})
			).pipe(mergeMap(() => of(true)));
		} else {
			const laPages: HTMLCollectionOf<Element> = document.getElementsByClassName("page");
			let loLoader: Loader;
			return defer(() => this.isvcLoading.create("Création du fichier pdf ..."))
				.pipe(
					mergeMap((poLoader: Loader) => {
						loLoader = poLoader;
						return loLoader.present();
					}),
					mergeMap(() => this.createAndOpenPdf(laPages)),
					mergeMap(() => of(true)),
					tap(() => loLoader.dismiss()),
					finalize(() => loLoader?.dismiss()),
					takeUntil(this.destroyed$)
				);
		}
	}

	private createAndOpenPdf(paPages: HTMLCollectionOf<Element>): Observable<boolean> {
		return defer(() => this.getPdf(paPages))
			.pipe(
				mergeMap((poJsPdf: jsPDF) => {
					const lsPdfOutput: ArrayBuffer = poJsPdf.output('arraybuffer');
					const loUint8Array = new Uint8Array(lsPdfOutput);

					if (this.isvcPlatform.isMobileApp) {
						// Enregistrer le fichier dans le répertoire Downloads sur mobile
						return defer(() =>
							this.isvcFilesystem.createFileAsync(
								`Download/${ExportTourneeModalComponent.C_TOURNEE_DIRECTORY_NAME}/${this.pdfName}`,
								loUint8Array,
								Directory.ExternalStorage,
								true
							)
						).pipe(
							tap((psPdfPath: string) =>
								console.log(`EXPTOUR.IDL.C:: Pdf d'export de tournée créé vers '${psPdfPath}'.`)
							),
							mergeMap((psPdfPath: string) =>
								this.ioFileOpener.open(psPdfPath, EXTENSIONS_AND_MIME_TYPES.pdf.mimeType)
							),
							mapTo(true)
						);
					} else {
						// Enregistrer dans le navigateur
						return defer(() => poJsPdf.save(this.pdfName, { returnPromise: true })).pipe(mapTo(true));
					}
				})
			);
	}

	private async getPdf(paTourneePages: HTMLCollectionOf<Element>): Promise<jsPDF> {
		const loJsPdf = new jsPDF("p", "px", "a4");
		const loDomToPngImageOptions: Options = {
			bgcolor: "white",
			quality: 1, // Qualité maximale pour les images
			height: ExportTourneeModalComponent.a4FormatWidthInPx,
			width: ExportTourneeModalComponent.a4FormatHeightInPx
		};

		for (let lnIndex = 0; lnIndex < paTourneePages.length; ++lnIndex) {
			const loTourneePage: Element = paTourneePages.item(lnIndex);
			const loPdfPage: jsPDF = lnIndex === 0 ? loJsPdf : loJsPdf.addPage();
			let lsImage: string;

			if (this.isvcPlatform.isMobileApp) {
				// Utiliser html2canvas pour mobile
				const canvas = await html2canvas((loTourneePage as HTMLElement));
				lsImage = canvas.toDataURL("image/jpeg");
			} else {
				// Utiliser dom-to-image pour les navigateurs
				lsImage = await domtoimage.toJpeg(loTourneePage, loDomToPngImageOptions);
			}

			loPdfPage.addImage(
				lsImage,
				EXTENSIONS_AND_MIME_TYPES.jpeg.key,
				0,
				0,
				loJsPdf.internal.pageSize.getWidth(),
				loJsPdf.internal.pageSize.getHeight()
			);
		}

		return loJsPdf;
	}


	public getStatusIcon(poSeanceTournee: ISeanceTournee): string {
		return TourneesService.getStatusIcon(poSeanceTournee).replace('-outline', '');
	}

	//#endregion
}