<div class="di-mode-de-reglement">
	<form [formGroup]="form" (ngSubmit)="handleSubmit($event)">
		<div class="liste_modes">
			<ng-container *ngFor="let mode of modes">
				<lua-radio-button [label]="mode" [fullWidth]="true" [checked]="mode === selectedMode"
					(click)="selectedMode = mode"></lua-radio-button>
			</ng-container>
		</div>
		<lua-input formControlName="date" type="date" label="Date" [fullWidth]="true"></lua-input>
		<lua-input formControlName="commentaire" label="Commentaire" [multiline]="true" [fullWidth]="true"></lua-input>
		<div class="actions">
			<lua-action-principale libelle="Enregistrer le règlement" [fullWidth]="true"
				(click)="onConfirm()"></lua-action-principale>
			<lua-bouton-texte libelle="Annuler" color="CouleurPrimaire" [fullWidth]="true"
				(click)="onCancel()"></lua-bouton-texte>
		</div>
	</form>
</div>