import { INavigationItem } from '../core/models/navigation/INavigationItem';

export const NavigationItems: INavigationItem[] = [
	{
		iconName: 'group',
		label: 'Patients',
		route: ['patients'],
		badgeCount: 0,
	},
	{
		iconName: 'contract',
		label: 'Ordonnances',
		route: ['ordonnances'],
		badgeCount: 0,
	},
	{
		iconName: 'backup_table',
		label: 'Transmissions',
		route: ['transmissions'],
		badgeCount: 0,
	},
	{
		iconName: 'directions_car',
		label: 'Tournées',
		route: ['tournees'],
		badgeCount: 0,
	},
	{
		iconName: 'calendar_month',
		label: 'Planning infirmier',
		route: ['planning'],
		badgeCount: 0,
	},
	{
		iconName: 'phone',
		label: 'Médecins et contacts',
		route: ['contacts'],
		badgeCount: 0,
	},
	{
		iconName: 'request_quote',
		label: 'Facturation',
		route: ['facturation'],
		badgeCount: 0,
	},
	{
		iconName: 'upload',
		label: 'Télétransmissions',
		route: ['teletransmission'],
		badgeCount: 0,
		onlyDesktop : true
	},
	{
		iconName: 'checklist_rtl',
		label: 'Suivi des factures',
		route: ['suivi-facturation'],
		badgeCount: 0,
	},
	{
		iconName: 'settings',
		label: 'Paramétrages',
		route: ['parametres'],
		badgeCount: 0,
	}
];
