<div id="impression-container">
  <div class="header">
    <h1>{{ tourneeDate | firstUpperCase }}</h1>
    <p>{{ nurseName }}</p>
  </div>
  <div class="seance-list">
    <div *ngFor="let item of sortedSeancesEvents; let i = index" class="item-line">
      <ng-container [ngSwitch]="item.type">
        <div class="seance-line" *ngSwitchCase="'seance'">
          <!-- Rendu pour les séances -->
          <div class="time">{{ item.data.labelMoment }}</div>
          <div class="patient-info">
            <strong>{{ item.data.patient.lastName | uppercase }} {{item.data.patient.firstName | firstUpperCase}}</strong>
            <small *ngIf="item.data.adress">{{ item.data.adress }}</small>
            <small *ngIf="item.data.phone">{{ item.data.phone }}</small>
            <small class="etage-code" *ngIf="item.data.floorAndAccesCode">{{ item.data.floorAndAccesCode }}</small>
          </div>
          <div class="actes">        
              <div class="ligne-acte" *ngFor="let act of item.data.actes">
                <lua-checkbox [checked]="false"></lua-checkbox>
                <p class="infos-acte">{{ act.keyLetters }} {{ act.priceCoefficient }} - {{ act.label }}</p>
              </div>        
          </div>
          <div class="comments">
            <lua-tag class="tag" *ngIf="item.data.estPremierSoin" color="CouleurPrimaire" label="1er Soin" mode="outlined"></lua-tag>
            <p class="placeholder-comment" *ngIf="i === 0">Notes et commentaires</p>
          </div>
        </div>
        <div class="event-line" *ngSwitchCase="'event'">
            <!-- Rendu pour les événements -->
            <div class="time-event">{{ item.data.labelMoment }}</div>
            <div class="description-event">
              <strong>{{ item.data.description }}</strong>
            </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
