import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateHelper, GuidHelper, IdHelper } from "@osapp/helpers";
import { EPrefix, UserData } from "@osapp/model";
import { Recurrence } from "@osapp/modules/event-markers/models/recurrence";
import { Evenement } from "apps/idl/src/anakin/models/Evenement";
import { StoredSeance } from "apps/idl/src/anakin/models/StoredSeance";
import { moments } from "apps/idl/src/anakin/shared/moments.constants";
import { EMoments } from "../../../shared/enums/EMoments";

@Component({
	selector: "di-evenement-form",
	templateUrl: "./evenement-form.component.html",
	styleUrls: ["./evenement-form.component.scss"],
})
export class EvenementFormComponent implements OnInit {
	@Input() evenement?: Evenement;
	@Input() momentLabel ?:string;
	@Input() date?:Date;
	@Input() onSave?: (evenement: Evenement) => void;
	@Input() onCancel?: () => void;

	eventForm: FormGroup;
	selectedMoment: string[] = [];
	showTimeInput = false;
	momentOptions = moments;

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		this.initEventForm();
	}

	initEventForm() {
		this.eventForm = this.fb.group({
			description: [this.evenement?.description || "", Validators.required],
			date: [
				DateHelper.toDateUrl(this.evenement?.date) || DateHelper.toDateUrl(this.date),
				Validators.required,
			],
			time: this.getEventTime(),
		});

		this.selectedMoment = this.getEventMoment();
	}

	getEventTime(): string {
		if (
			!this.evenement ||
			!this.evenement?.moment ||
			this.evenement?.moment.type !== "hours-minutes"
		)
			return "";

		this.showTimeSelection();
		const hours = this.evenement?.moment.hours.toString().padStart(2, "0");
		const minutes = this.evenement?.moment.minutes.toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	getEventMoment() {
		if(!this.evenement ||
			!this.evenement?.moment ){
				return [ 
					this.momentLabel 
				];
			}
		else if (
			
			this.evenement?.moment.type !== "range"
		)
			return [];

		return [StoredSeance.determineMoment(this.evenement?.moment)];
	}

	showTimeSelection() {
		this.showTimeInput = true;
	}

	onMomentChange(moment: EMoments[]) {
		this.eventForm.patchValue({ time: "" });
		this.selectedMoment = moment;
	}

	saveEvenement(): void {
		this.evenement = {
			...this.evenement,
			description: this.eventForm.get("description")?.value,
			date: new Date(this.eventForm.get("date")?.value),
			infirmierId: UserData.current._id.replace(EPrefix.user, EPrefix.contact),
		};

		if (this.selectedMoment.length > 0) {
			this.evenement.moment = Recurrence.getRepetition(
				this.selectedMoment[0] as EMoments
			);
		}

		if (this.eventForm.get("time")?.value) {
			this.evenement.moment = {
				type: "hours-minutes",
				hours: this.eventForm.get("time")?.value.split(":")[0],
				minutes: this.eventForm.get("time")?.value.split(":")[1],
			};
		}

this.evenement._id =this.evenement?._id ? this.evenement?._id : IdHelper.buildId(EPrefix.evenement, `${UserData.current._id.replace(EPrefix.user, EPrefix.contact)}~${GuidHelper.newGuid()}`);
		this.onSave && this.onSave(this.evenement);
	}

	cancelEvenement(): void {
		this.onCancel && this.onCancel();
	}
}
