export const EEtatsReglement = {
  aRegler: {
    status: "aRegler",
    labelTier: "A régler par le patient",
		label: ""
  },
  envoyer: {
    status: "envoyer",
    labelTier: "Envoyé au patient",
		label: "Envoyé"
  },
  paye: {
    status: "paye",
    labelTier: "Payé par le patient",
		label: "Payé"
  },
  impaye: {
    status: "impaye",
    labelTier: "Impayé",
		label: "Impayé"
  }
	
};
