import { Component, Input, OnInit } from "@angular/core";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { takeUntil } from "rxjs/operators";
import { Traitement } from "../../../../../model/Traitement";
import { DeviceService } from "../../services/device.service";

@Component({
	selector: "di-ordonnance-stop",
	templateUrl: "./ordonnance-stop.component.html",
	styleUrls: ["./ordonnance-stop.component.scss"],
})
export class OrdonnanceStopComponent
	extends DestroyableComponentBase
	implements OnInit
{
	@Input() confirm: (ordonnance : Traitement) => void;
	@Input() cancel: () => void;
	@Input() ordonnance: Traitement;
	public isMobileView: boolean = false;
	commentaire: string = "";
	modeEdit = false;

	constructor(
		private svcDevice: DeviceService,
	) {
		super();
	}

	ngOnInit(): void {
		this.commentaire = this.ordonnance.stopComment ?? '';
		this.svcDevice.isMobile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((flag: boolean) => {
				this.isMobileView = flag;
			});
	}

	onConfirm() {
		this.ordonnance.stopComment = this.commentaire;
		this.ordonnance.stopDate = new Date();
		if(this.confirm)
			this.confirm(this.ordonnance);
	}

	onCancel() {
		if(this.cancel)
			this.cancel();
	}
}
