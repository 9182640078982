import { ArrayHelper } from "@osapp/helpers/arrayHelper";
import { DateHelper } from "@osapp/helpers/dateHelper";
import { IdHelper } from "@osapp/helpers/idHelper";
import { IGalleryFile } from "@osapp/model/gallery/IGalleryFile";
import { IMinMaxDates } from "@osapp/modules/date/model/IMinMaxDates";
import { ResolveModel } from "@osapp/modules/utils/models/decorators/resolve-model.decorator";
import { Exclude, Expose, plainToClass } from "class-transformer";
import { Traitement } from "../../../model/Traitement";
import { EFormuleSTS } from "./eformule-sts.enum";
import { EInvoiceStatus } from "./einvoice-status";
import { EInvoiceType } from "./einvoice-type.enum";
import { EModeSecurisation } from "./emode-securisation.enum";
import { IFSE } from "./ifse";
import { IFsvErrorSV } from "./ifsv-error-sv";
import { IInvoice } from "./iinvoice";
import { IInvoiceLine } from "./iinvoice-line";
import { InvoiceLine } from "./invoice-line";
import { InvoiceStatus } from "./invoice-status";
import { IContact } from "@osapp/model";

export class Invoice implements IInvoice {

	//#region FIELDS

	private static readonly C_CAN_GO_FORWARD_STATUSES: EInvoiceStatus[] = [EInvoiceStatus.created, EInvoiceStatus.priced, EInvoiceStatus.invoicing, EInvoiceStatus.checked, EInvoiceStatus.payment];
	private static readonly C_CAN_CANCEL_STATUSES: EInvoiceStatus[] = [EInvoiceStatus.created, EInvoiceStatus.priced, EInvoiceStatus.checked, EInvoiceStatus.invoicing, EInvoiceStatus.payment, EInvoiceStatus.closed];

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	/** @implements */
	public intervenantId: string;
	/** @implements */
	public authorId: string;
	/** @implements */
	public invoiceNumber?: string;
	/** @implements */
	public ordonnanceId?: string;
	/** @implements */
	public documents: IGalleryFile[] = [];
	/** @implements */
	public fspDmsFiles: IGalleryFile[] = [];
	/** @implements */
	public externalId?: string;
	@Exclude()
	private moActes: InvoiceLine[];
	@Expose()
	/** @implements */
	public get actes(): InvoiceLine[] { return this.moActes; }
	public set actes(poActes: InvoiceLine[]) {
		this.moActes = poActes.map((poLine: IInvoiceLine) => plainToClass(InvoiceLine, poLine));

		const laDates: string[] = [];

		this.moActes.forEach((poActe: InvoiceLine) => {
			laDates.push(new Date(poActe.date).toISOString());
		});

		const loMinAndMaxDate: IMinMaxDates = DateHelper.getMinAndMaxDates(laDates);

		this.mnNbSeances = ArrayHelper.unique(laDates).length;
		this.mdMinDate = loMinAndMaxDate.min;
		this.mdMaxDate = loMinAndMaxDate.max;
	}
	/** @implements */
	public securisationData?: IFSE;
	/** @implements */
	public canceled?: boolean;
	/** @implements */
	public date?: string;
	@Exclude()
	private meSecurisationMode: EModeSecurisation;
	@Expose()
	/** @implements */
	public get securisationMode(): EModeSecurisation {
		if (!this.meSecurisationMode)
			this.meSecurisationMode = EModeSecurisation.SECURISE;

		return this.meSecurisationMode;
	}
	public set securisationMode(peSecurisationMode: EModeSecurisation) {
		if (peSecurisationMode !== this.meSecurisationMode)
			this.meSecurisationMode = peSecurisationMode;
	}
	/** @implements */
	public dre?: boolean;
	/** @implements */
	public adcDate?: string;
	/** @implements */
	public amoTp?: boolean;
	/** @implements */
	public amcTp?: boolean;
	/** @implements */
	public sts: EFormuleSTS;
	/** @implements */
	public gestionUnique?: boolean;
	@ResolveModel(Date)
	/** @implements */
	public exportDate?: Date;

	@ResolveModel(InvoiceStatus)
	@Exclude()
	private moStatus: InvoiceStatus;
	@Expose()
	/** @implements */
	public get status(): InvoiceStatus {
		return this.moStatus ?? (this.moStatus = new InvoiceStatus());
	}
	public set status(poStatus: InvoiceStatus) {
		if (poStatus !== this.moStatus)
			this.moStatus = poStatus;
	}

	/** @implements */
	public paymentIds?: string[] = [];
	/** @implements */
	public invoiceType: EInvoiceType = EInvoiceType.facture;
	/** @implements */
	public invoiceSourceId?: string;
	/** @implements */
	public avoirInvoiceId?: string;
	/** @implements */
	public erreurSv?: IFsvErrorSV[];

	/** @implements */
	public statusAMO?: string;
	/** @implements */
	public statusAMC?: string;
	/** @implements */
	public statusPat?: string;
	/** @implements */
	public commentaireAMO?: string;
	/** @implements */
	public commentaireAMC?: string;
	/** @implements */
	public commentairePat?: string;
	/** @implements */
	public modeReglementPat?: string;
	/** @implements */
	public dateAMO?: Date;
	/** @implements */
	public dateAMC?: Date;
	/** @implements */
	public datePat?: Date;

	/** @implements */
	public labelAMO?: string;
	/** @implements */
	public labelAMC?: string;

	/** @implements */
	public amoId?: string;
	/** @implements */
	public amcId?: string;

	/** @implements */
	public amcNumeroAdherent?: string;

	private msTraitementId: string;
	@Expose()
	/** @implements */
	public get traitementId(): string {
		if (!this.msTraitementId)
			this.msTraitementId = Invoice.extractTraitementId(this._id);

		return this.msTraitementId;
	}

	@Exclude()
	private msPatientId: string;
	public get patientId(): string {
		if (!this.msPatientId)
			this.msPatientId = Traitement.extractPatientId(this.traitementId);

		return this.msPatientId;
	}

	@Exclude()
	private mnNbSeances: number;
	public get nbSeances(): number { return this.mnNbSeances; }

	@Exclude()
	private mdMinDate: Date;
	public get minDate(): Date { return this.mdMinDate; }

	@Exclude()
	private mdMaxDate: Date;
	public get maxDate(): Date { return this.mdMaxDate; }

	@Exclude()
	public infirmier?: IContact;

	@Exclude()
	public patient?: IContact;

	public get honoraires(): number {
		return this.actes.map((poLine: InvoiceLine) => poLine.honoraires).reduce((pnPreviousPrice: number, pnCurrentPrice: number) => pnPreviousPrice + pnCurrentPrice, 0);
	}

	public get totalPartAMO(): number {
		return this.actes.map((poLine: InvoiceLine) => poLine.partAMO).reduce((pnPreviousPrice: number, pnCurrentPrice: number) => pnPreviousPrice + pnCurrentPrice, 0);
	}

	public get totalPartAMC(): number {
		return this.actes.map((poLine: InvoiceLine) => poLine.partAMC).reduce((pnPreviousPrice: number, pnCurrentPrice: number) => pnPreviousPrice + pnCurrentPrice, 0);
	}

	public get totalPartPatient(): number {
		return this.actes.map((poLine: InvoiceLine) => poLine.partPatient).reduce((pnPreviousPrice: number, pnCurrentPrice: number) => pnPreviousPrice + pnCurrentPrice, 0);
	}

	public get label(): string {
		return `${this.invoiceType === EInvoiceType.avoir ? "Avoir" : "Facture"} ${this.invoiceNumber ? `n°${this.invoiceNumber}` : ""}`;
	}

	public get statusLabel(): string {
		return this.getStatusLabel();
	}

	public get statusIcon(): string {
		return this.getStatusIcon();
	}

	public get nextActionLabel(): string {
		return this.getNextActionLabel();
	}

	public get nextActionIcon(): string {
		return this.getNextActionIcon();
	}

	public get isPriced(): boolean {
		return this.status.value === EInvoiceStatus.priced ||
			this.status.value === EInvoiceStatus.invoicing ||
			this.status.value === EInvoiceStatus.checked ||
			this.status.value === EInvoiceStatus.closed ||
			this.status.value === EInvoiceStatus.payment;
	}

	public get isClosed(): boolean {
		return this.status.value === EInvoiceStatus.closed;
	}

	public get isCancel(): boolean {
		return this.status.value === EInvoiceStatus.canceled;
	}

	public get canGoForward(): boolean {
		return Invoice.C_CAN_GO_FORWARD_STATUSES.includes(this.status.value);
	}

	public get canCancel(): boolean {
		return Invoice.C_CAN_CANCEL_STATUSES.includes(this.status.value) && !this.avoirInvoiceId && this.invoiceType === EInvoiceType.facture;
	}

	public get cancelLabel(): string {
		return this.getCancelLabel();
	}

	public get cancelIcon(): string {
		return this.getCancelIcon();
	}

	//#endregion

	//#region METHODS

	public static extractTraitementId(psInvoiceId: string): string {
		return ArrayHelper.getFirstElement(IdHelper.extractAllIds(psInvoiceId));
	}

	/** Récupère un label représentant un statut.
	 * @param peStatusValue
	 */
	public getStatusLabel(peStatusValue: EInvoiceStatus = this.status.value): string {
		switch (peStatusValue) {
			case EInvoiceStatus.aborted:
				return "Abandonnée";
			case EInvoiceStatus.archived:
				return "Archivée";
			case EInvoiceStatus.canceled:
				return "Annulée";
			case EInvoiceStatus.closed:
				return ArrayHelper.hasElements(this.paymentIds) ? "Encaissée" : this.getStatusLabel(EInvoiceStatus.payment);
			case EInvoiceStatus.created:
				return "Créée";
			case EInvoiceStatus.checked:
				return "Facturée";
			case EInvoiceStatus.invoicing:
				return "Tarifée/Facturation incomplète";
			case EInvoiceStatus.priced:
				return "Tarifée";
			case EInvoiceStatus.payment:
				return this.securisationMode === EModeSecurisation.SECURISE ? "Sécurisée" : "Signée";
			default:
				return "État inconnu";
		}
	}

	public getCancelLabel(peStatusValue: EInvoiceStatus = this.status.value): string {
		switch (peStatusValue) {
			case EInvoiceStatus.created:
			case EInvoiceStatus.priced:
				return "Annuler";
			case EInvoiceStatus.checked:
			case EInvoiceStatus.invoicing:
			case EInvoiceStatus.payment:
			case EInvoiceStatus.closed:
				return "Annuler";
			default:
				return "";
		}
	}

	public getCancelIcon(peStatusValue: EInvoiceStatus = this.status.value): string {
		switch (peStatusValue) {
			case EInvoiceStatus.created:
			case EInvoiceStatus.priced:
				return "close";
			case EInvoiceStatus.checked:
			case EInvoiceStatus.invoicing:
			case EInvoiceStatus.payment:
			case EInvoiceStatus.closed:
				return "close";
			default:
				return "";
		}
	}

	/** Récupère un icône représentant un statut.
	 * @param peStatusValue
	 */
	public getStatusIcon(peStatusValue: EInvoiceStatus = this.status.value): string {
		switch (peStatusValue) {
			case EInvoiceStatus.aborted:
			case EInvoiceStatus.canceled:
			case EInvoiceStatus.archived:
				return "close";
			case EInvoiceStatus.closed:
				return ArrayHelper.hasElements(this.paymentIds) ? "collect" : this.getStatusIcon(EInvoiceStatus.payment);
			case EInvoiceStatus.created:
				return "create";
			case EInvoiceStatus.checked:
				return "invoice";
			case EInvoiceStatus.priced:
			case EInvoiceStatus.invoicing:
				return "number";
			case EInvoiceStatus.payment:
				return "lock-closed";
			default:
				return "help";
		}
	}

	/** Récupère un icône représentant un statut.
	 * @param peStatusValue
	 */
	public getNextActionIcon(peStatusValue: EInvoiceStatus = this.status.value): string {
		switch (peStatusValue) {
			case EInvoiceStatus.closed:
			case EInvoiceStatus.aborted:
			case EInvoiceStatus.canceled:
			case EInvoiceStatus.archived:
				return "close";
			case EInvoiceStatus.created:
				return "number";
			case EInvoiceStatus.checked:
				return "lock-closed";
			case EInvoiceStatus.priced:
			case EInvoiceStatus.invoicing:
				return "invoice";
			case EInvoiceStatus.payment:
				return "collect";
			default:
				return "help";
		}
	}

	/** Récupère un label représentant un statut.
	 * @param peStatusValue
	 */
	public getNextActionLabel(peStatusValue: EInvoiceStatus = this.status.value): string {
		switch (peStatusValue) {
			case EInvoiceStatus.created:
				return "Tarifer";
			case EInvoiceStatus.checked:
				return this.securisationMode === EModeSecurisation.SECURISE ? "Sécuriser" : "Signer";
			case EInvoiceStatus.priced:
			case EInvoiceStatus.invoicing:
				return "Facturer";
			case EInvoiceStatus.payment:
				return "Encaisser";
		}

		return "";
	}

	/** Récupère les états possibles pour une facture. */
	public getAvailableStatuses(): EInvoiceStatus[] {
		if (this.totalPartPatient > 0)
			return [EInvoiceStatus.created, EInvoiceStatus.priced, EInvoiceStatus.checked, EInvoiceStatus.payment, EInvoiceStatus.closed];
		else
			return [EInvoiceStatus.created, EInvoiceStatus.priced, EInvoiceStatus.checked, EInvoiceStatus.payment];
	}

	//#endregion

}
