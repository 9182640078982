import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { IHttpOptions } from '@osapp/model/IHttpOptions';
import { UserData } from '@osapp/model/application/UserData';
import { ConfigData } from '@osapp/model/config/ConfigData';
import { IUiResponse } from '@osapp/model/uiMessage/IUiResponse';
import { AuthenticatedRequestOptionBuilder } from '@osapp/modules/api/models/authenticated-request-option-builder';
import { Loader } from '@osapp/modules/loading/Loader';
import { IPreferences } from '@osapp/modules/preferences/model/IPreferences';
import { RecentsService } from '@osapp/modules/preferences/recents/services/recents.service';
import { ContactsService } from '@osapp/services/contacts.service';
import { ShowMessageParamsPopup } from '@osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@osapp/services/loading.service';
import { UiMessageService } from '@osapp/services/uiMessage.service';
import { WorkspaceService } from '@osapp/services/workspace.service';
import { EMPTY, Observable, Subject, defer, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { LoaderService } from '../../../anakin/features/shared/services/loader.service';
import { C_PREFIX_TERMINAL } from '../../../app/app.constants';
import { PatientSelectorModalOpenerService } from '../../patients/components/patient-selector-modal/services/patient-selector-modal-opener.service';
import { IPatient } from '../../patients/model/IPatient';
import { EPatientDataSource } from '../../patients/model/epatient-data-source.enum';
import { CouverturesService } from '../../patients/services/couvertures.service';
import { ITerminal } from '../models/iterminal';
import { ITerminalInfo } from '../models/iterminal-info';

@Injectable()
export class OlaqinService {

	public erreurLectureCVSubject = new Subject<string>();
	public erreurLectureCV$: Observable<string> = this.erreurLectureCVSubject.asObservable();

	//#region FIELDS

	private get baseUrl(): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}workspaces/${this.isvcWorkspace.getWorkspaceNameFromId(ArrayHelper.getFirstElement(UserData.current.workspaceInfos).id)}`;
	}

	private get terminalsBaseUrl(): string {
		return `${this.baseUrl}/contacts/${ContactsService.getUserContactId()}/terminals`;
	}

	private getUpdatePatientUrl(psWorkspace: string, poModel: IPatient, peDataSource: EPatientDataSource): string {
		return `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/${psWorkspace}/entities/patients/${poModel._id}?systemId=fsv&datasource=${peDataSource}`;
	}

	//#endregion

	//#region PROPERTIES



	//#endregion

	//#region METHODS

	constructor(
		private readonly ioHttpClient: HttpClient,
		private readonly isvcRecents: RecentsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcCouvertures: CouverturesService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcPatientSelectorModalOpener: PatientSelectorModalOpenerService,
		private readonly svcLoader: LoaderService
	) { }

	/** Retourne la liste des terminaux disponibles. */
	public listTerminals(): Observable<ITerminal[]> {
		return this.ioHttpClient.get<ITerminal[]>(this.terminalsBaseUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions());
	}

	/** Récupère les informations d'un terminal.
	 * @param psTerminalId
	 */
	public getTerminalInfos(psTerminalId: string): Observable<ITerminalInfo> {
		const loRequestParams: IHttpOptions = AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions();

		loRequestParams.params = { systemId: "fsv" };

		return this.ioHttpClient.get<ITerminal>(`${this.terminalsBaseUrl}/${IdHelper.getGuidFromId(psTerminalId, C_PREFIX_TERMINAL)}`, loRequestParams);
	}

	/** Récupère le dernier terminal sélectionné par l'utilisateur.
	 * @param pbLive
	 */
	public getLastUsedTerminalId(pbLive?: boolean): Observable<string> {
		return this.isvcRecents.get(C_PREFIX_TERMINAL, pbLive).pipe(map((poPreference: IPreferences) => ArrayHelper.getLastElement(poPreference?.ids)));
	}

	/** Défini le dernier terminal utilisé par l'utilisateur.
	 * @param psTerminalId
	 */
	public setLastUsedTerminalId(psTerminalId: string): Observable<boolean> {
		if (!StringHelper.isBlank(psTerminalId))
			return this.isvcRecents.add(IdHelper.buildId(C_PREFIX_TERMINAL, psTerminalId));

		return of(true);
	}

	/** Lit la carte vitale insérée dans un terminal.
	 * @param psTerminalId Identifiant du terminal dans lequel se trouve la carte vitale.
	 * @param psCodeCPS Optionnel. Si non renseigné, une popup va apparaitre pour permettre la saisie.
	 */
	public readVitalCard(psTerminalId: string, psCodeCPS?: string): Observable<IPatient[]> {
		let loLoader: Loader;
		const loHttpOptions: IHttpOptions = AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions();

		// ajout d'un code CPS fictif
		loHttpOptions.params = { terminalId: psTerminalId, codeCPS: "1111" };

		return defer(() => this.isvcLoading.create("Lecture de la carte vitale en cours ...")).pipe(
			mergeMap((poLoader: Loader) => (loLoader = poLoader).present()),
			mergeMap(() => this.ioHttpClient.get<IPatient[]>(`${this.baseUrl}/entities/patients/lireVitale`, loHttpOptions)),
			finalize(() => loLoader?.dismiss())
		);
	}

	public updatePatientWithADRiOrVitale(poPatient: IPatient, psTerminalId: string, peDataSource: EPatientDataSource): Observable<HttpResponse<IPatient>> {
		let loLoader: Loader;
		const loHttpOptions: IHttpOptions = AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions();
		loHttpOptions.observe = "response" as "body";
		const lsWorkspace: string = this.isvcWorkspace.getWorkspaceNameFromId(ArrayHelper.getFirstElement(UserData.current.workspaceInfos).id);

		// On envoie un code cps fictif
		const init$: Observable<string | undefined> = [EPatientDataSource.vitale, EPatientDataSource.ADRi].includes(peDataSource) ? of("1111") : of(undefined);

		return defer(() => init$).pipe(
			tap((psCodeCps: string | undefined) => {
				loHttpOptions.params = { terminalId: psTerminalId, codeCps: psCodeCps };
			}),
			mergeMap(() => this.isvcLoading.create("Mise à jour en cours ...")),
			mergeMap((poLoader: Loader) => {
				loLoader = poLoader;
				return loLoader.present();
			}),
			mergeMap(() =>
				this.ioHttpClient.put<HttpResponse<IPatient>>(
					this.getUpdatePatientUrl(lsWorkspace, poPatient, peDataSource),
					StoreHelper.getCleanedDocument(poPatient),
					loHttpOptions
				).pipe(
					tap((updateResponse: HttpResponse<IPatient>) => {
						this.isvcCouvertures.deleteAllCouvertureAMO(poPatient._id).subscribe();
					}),
					catchError(error => {
						console.error('Erreur lors de la mise à jour du patient:', error);
						return throwError(() => new Error('Une erreur est survenue lors de la mise à jour du patient.'));
					})
				)
			),
			finalize(() => loLoader?.dismiss())
		);
	}

	public updatePatientWithADRiOrVitaleAnakin(poPatient: IPatient, psTerminalId: string, peDataSource: EPatientDataSource): Observable<HttpResponse<IPatient>> {
		const loHttpOptions: IHttpOptions = AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions();
		loHttpOptions.observe = "response" as "body";
		const lsWorkspace: string = this.isvcWorkspace.getWorkspaceNameFromId(ArrayHelper.getFirstElement(UserData.current.workspaceInfos).id);

		//On envoie un code cps fictif
		const init$: Observable<string> = [EPatientDataSource.vitale, EPatientDataSource.ADRi].includes(peDataSource) ? of("1111") : of(undefined);
		return defer(() => init$)
			.pipe(
				tap(() => { if (peDataSource == EPatientDataSource.ADRi) this.svcLoader.showLoader("Appel ADRI en cours...") }),
				tap((psCodeCps: string) => loHttpOptions.params = { terminalId: psTerminalId, codeCps: psCodeCps }),
				mergeMap(() =>
					this.ioHttpClient.put<HttpResponse<IPatient>>(
						this.getUpdatePatientUrl(lsWorkspace, poPatient, peDataSource),
						StoreHelper.getCleanedDocument(poPatient),
						loHttpOptions
					).pipe(
						tap((updateResponse: HttpResponse<IPatient>) => {
							this.isvcCouvertures.deleteAllCouvertureAMO(poPatient._id).subscribe();
						}),
						catchError(error => {
							console.error('Erreur lors de la mise à jour du patient:', error);
							return throwError(() => new Error('Une erreur est survenue lors de la mise à jour du patient.'));
						})
					)
				),
				finalize(() => { if (peDataSource == EPatientDataSource.ADRi) this.svcLoader.hideLoader() }),

			);
	}

	private showCPSPopup(): Observable<string> {
		return this.isvcUiMessage.showAsyncMessage<boolean, { cps: string }>(new ShowMessageParamsPopup({
			header: "Saisir code CPS",
			inputs: [{ type: "password", name: "cps", attributes: { maxlength: 4, autocomplete: "false" } }],
			buttons: [{ text: "ANNULER", handler: UiMessageService.getFalsyResponse }, { text: "VALIDER", handler: UiMessageService.getTruthyResponse }],
			cssClass: "z-index-max"
		})).pipe(
			filter((poResponse: IUiResponse<boolean, { cps: string }>) => poResponse.response),
			map((poResponse: IUiResponse<boolean, { cps: string }>) => poResponse.values?.cps)
		);
	}

	public selectBeneficiaire(paPatients: IPatient[]): Observable<IPatient> {
		if (ArrayHelper.hasElements(paPatients)) {
			return this.isvcPatientSelectorModalOpener.open({
				required: true,
				patients: paPatients,
				removeGroupFilter: true,
				title: "Bénéficiaires",
				canCreate: false
			}).pipe(
				map((paSelectedPatients: IPatient[]) => ArrayHelper.getFirstElement(paSelectedPatients))
			);
		}
		else
			return EMPTY;
	}

	public setErreurLectureCV(messageErreur: string) {
		this.erreurLectureCVSubject.next(messageErreur);
	}

	//#endregion
}
