import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ArrayHelper, ComponentBase, IdHelper, ObjectHelper } from '@osapp/helpers';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { EDatabaseRole, IDataSource } from '@osapp/model';
import { Store } from '@osapp/services';
import { C_PREFIX_AMOP, C_PREFIX_TERMINAL } from 'apps/idl/src/app/app.constants';
import { ITerminalInfoModalParams } from 'apps/idl/src/modules/olaqin/components/terminal-info-modal/models/iterminal-info-modal-params';
import { ITerminalInfo } from 'apps/idl/src/modules/olaqin/models/iterminal-info';
import { OlaqinService } from 'apps/idl/src/modules/olaqin/services/olaqin.service';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { EPatientDataSource } from 'apps/idl/src/modules/patients/model/epatient-data-source.enum';
import { IAMOP } from 'apps/idl/src/modules/patients/model/iamo-p';
import { CouverturesService } from 'apps/idl/src/modules/patients/services/couvertures.service';
import { PatientsService } from 'apps/idl/src/modules/patients/services/patients.service';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ITerminal } from '../../../../../modules/olaqin/models/iterminal';
import { EUpdateMode } from '../../../../../modules/patients/model/eupdate-mode.enum';
import { DrawerPopoverService } from '../../services/drawer-popover.service';
import { LoaderService } from '../../services/loader.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
    selector: 'di-lecture-terminal',
    templateUrl: './lecture-terminal.component.html',
    styleUrls: ['./lecture-terminal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LectureTerminalComponent extends ComponentBase implements OnInit {

    @Input()
    public adri = false;
    @Input()
    public params?: ITerminalInfoModalParams;
    @Input()
    public patient?: IPatient;

    private lastTerminalId: string = "";
    public readonly terminalId$: Observable<string>;
    public terminal?: ITerminalInfo;
    public canValidate = false;
    public listTerminal: ITerminal[] = [];
    public isReady: boolean = false;

    public terminalError = false;
    public cpsError = false;
    public vitaleError = false;

    public choixPatient = false;

    public assure: IPatient;
    public beneficiaires: IPatient[];
    public selectedPatientSubject = new Subject<IPatient>();
    public selectedPatient$: Observable<IPatient> = this.selectedPatientSubject.asObservable();
    public isSelected: boolean = false;
		public chargementLecteur: boolean = false;

    constructor(
        public svcOlaqin: OlaqinService,
        public changeDetector: ChangeDetectorRef,
        public svcDrawerPopover: DrawerPopoverService,
        private svcPatients: PatientsService,
        private svcCouvertures: CouverturesService,
        private svcStore: Store,
        private svcLoader: LoaderService,
        private svcSnackbar: SnackbarService
    ) {
        super(changeDetector);
    }

    public ngOnInit(): void {
        this.getListTerminal();
    }

    public getListTerminal(): void {
        this.svcOlaqin.getLastUsedTerminalId().pipe(
            tap((terminalId: string) => {

                this.lastTerminalId = IdHelper.getGuidFromId(terminalId, C_PREFIX_TERMINAL) || '';
                if (this.lastTerminalId !== ''){
									this.isSelected = true;
									this.onReadyLecteur();
								} 
                this.detectChanges();
            }),
            mergeMap(() => this.svcOlaqin.listTerminals()),
            tap((list: ITerminal[]) => {
                this.listTerminal = list;
                this.detectChanges();
            }),
            takeUntil(this.destroyed$)
        ).subscribe();
    }

    public isLastTerminal(terminal: ITerminal): boolean {
        return this.lastTerminalId === terminal.terminalId;
    }

    private isTerminalReady(): boolean {
        this.terminalError = false
        this.cpsError = false
        this.vitaleError = false
        return this.terminal?.terminalConnected && this.terminal?.cpsInserted && (this.terminal?.vitaleInserted || this.adri);
    }

    public onReadyLecteur(): void {
				this.chargementLecteur = true;
        this.isReady = true;
        this.terminal = undefined;
        this.detectChanges();

        const terminalInfo$ = StringHelper.isBlank(this.lastTerminalId)
            ? of({} as ITerminalInfo)
            : this.svcOlaqin.getTerminalInfos(this.lastTerminalId);

        terminalInfo$.pipe(
						catchError((error) => {
							this.chargementLecteur = false;
							return of({})
						}),
            switchMap((terminal: ITerminalInfo) => {
								this.chargementLecteur = false;
                this.terminal = terminal;
                this.canValidate = this.isTerminalReady();
                this.detectChanges();

                if (this.canValidate) {
                    if (this.adri) {
                        // Si ADRI est actif, fermeture du popover
                        this.svcDrawerPopover.close();
                        return of(null);
                    } else {
                        // Processus de lecture de carte vitale
                        return this.svcPatients.getPatient(this.patient._id).pipe(
                            tap(() => this.svcLoader.showLoader("Lecture carte vitale en cours...")),
                            switchMap((patient: IPatient) => {
                                this.patient = patient;
                                return this.svcOlaqin.readVitalCard(this.terminal.terminalId);
                            }),
                            switchMap((patients: IPatient[]) => {
                                // Recherche des patients correspondants à la carte vitale
                                const searchedPatients: IPatient[] = this.svcPatients.searchPatient(patients, {
                                    ...ObjectHelper.pick(this.patient, ["birthDate", "firstName", "lastName", "socialNumber"]),
                                    fullMatch: !!this.patient.updateDate
                                });

                                if (this.patient.updateDate && searchedPatients.length === 1) {
                                    return of(ArrayHelper.getFirstElement(searchedPatients));
                                } else {
                                    this.choixPatient = true;  // Demander la sélection du patient si plusieurs résultats
                                    this.svcLoader.hideLoader();
                                }

                                this.assure = ArrayHelper.getFirstElement(patients.filter(p => p.AMO[0].qualite === "00"));
                                this.beneficiaires = patients.filter(p => p.AMO[0].qualite !== "00");
                                this.detectChanges();
                                return this.selectedPatient$;  // Retourner l'observable de sélection du patient
                            }),
                            map((patient: IPatient) => {
                                if (this.choixPatient) this.svcLoader.showLoader("Mise à jour du patient ...")
                                this.patient.cvLastUpdate = new Date();
                                // On récupère l'adresse de la CV seulement si il n'y avait pas d'adresse dans le dossier patient
                                if (this.patient.zipCode || this.patient.street || this.patient.street) {
                                    delete patient.street;
                                    delete patient.zipCode;
                                    delete patient.city;
                                }
                                return this.svcPatients.mergePatientsData(this.patient, patient);
                            }),
                            mergeMap((patient: IPatient) => {
                                // Exporter le patient si nécessaire
                                if (StringHelper.isBlank(patient.externalId)) {
                                    return this.svcPatients.exportPatientXml(patient, patient.carteVitaleXML);
                                } else {
                                    return of(patient);
                                }
                            }),
                            mergeMap((updatedPatient: IPatient) => {
                                updatedPatient.updateDate = new Date();
                                updatedPatient.updateMode = EUpdateMode.carteVitale;
                                return this.svcPatients.savePatient(updatedPatient).pipe(
                                    map(() => updatedPatient)
                                )
                            }),
                            mergeMap((updatedPatient: IPatient) =>
                                this.svcOlaqin.updatePatientWithADRiOrVitaleAnakin(updatedPatient, this.terminal.terminalId, EPatientDataSource.vitale)
                            ),
                            mergeMap(() => this.svcOlaqin.setLastUsedTerminalId(this.lastTerminalId)),
                            mergeMap(() => {
                                const loAMOPDataSource: IDataSource = this.svcCouvertures.getCouvertureDataSource(this.patient._id, C_PREFIX_AMOP, false);
                                return this.svcStore.get<IAMOP>(loAMOPDataSource).pipe(
                                    mergeMap(async (laCouvertures: IAMOP[]) => {
                                        await this.svcStore.syncDocumentsToLocal(
                                            ArrayHelper.getFirstElement(this.svcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
                                            laCouvertures
                                        );
                                    })
                                );
                            }),
                            tap(() => {
                                this.svcDrawerPopover.close();
                                this.svcLoader.hideLoader();
                            }),
                            catchError((erreur: any) => {
                                const message = erreur?.error?.message || "Une erreur est survenue lors de la lecture de la CV et de la CPS";
                                this.svcOlaqin.setErreurLectureCV(message);
                                this.isReady = false;
                                this.terminal = undefined;
                                this.svcDrawerPopover.close();
                                this.svcLoader.hideLoader();
                                this.detectChanges();
                                return EMPTY;
                            }),
                        );
                    }
                } else {
                    this.terminalError = !this.terminal?.terminalConnected;
                    this.cpsError = !this.terminal?.cpsInserted;
                    this.vitaleError = !this.terminal?.vitaleInserted;
                    return of(null);
                }
            })
        ).subscribe();
    }


    public onClose() {
        this.svcDrawerPopover.close();
    }

    public onSelectTerminal(terminal: ITerminal) {
			this.isReady = false;
			this.terminal = null;
			if (terminal.terminalId === this.lastTerminalId) {
					this.isSelected = false;
					this.lastTerminalId = "";
			} else {
					this.lastTerminalId = terminal.terminalId;
					this.isSelected = true;
					this.onReadyLecteur();
			}
		}

    public onPatientSelect(patient: IPatient) {
        this.selectedPatientSubject.next(patient);
    }

}
