import { Component, Input, OnInit } from "@angular/core";
import { EPrefix, IContact, IGroup, IUser } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService, GroupsService } from "@osapp/services";
import { Subject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "di-utilisateurs",
  templateUrl: "./utilisateurs.component.html",
  styleUrls: ["./utilisateurs.component.scss"],
})
export class UtilisateursComponent extends DestroyableComponentBase implements OnInit {
  @Input() 
  public isMobileView: boolean;
  
  @Input()
  public resetUsersList!: Subject<void>;

  public showListUtilisateur : boolean = true;  
  public showInvitation : boolean = false;  
  public showFormInvitation : boolean = false;

  public adminTag = {
    tagLabel: "Admin",
    tagColor: "Raspberry",
    tagMode: "outlined",
  };

  public remplacantTag = {
    tagLabel: "Remplaçant",
    tagColor: "CouleurPrimaire",
    tagMode: "filled",
  };

  public titulaireTag = {
    tagLabel: "Titulaire",
    tagColor: "CouleurPrimaire",
    tagMode: "filled",
  };

  public utilisateurs: IContact[];
  public nbUtilisateurs: number; 
	public userFind: IUser;
	public contact: IContact;

  constructor(
    private svcContacts: ContactsService,
    private svcGroupe: GroupsService,
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.loadUtilisateurs();
    this.setDefaultView();

    if(this.resetUsersList){
      this.resetUsersList.subscribe(() => {
        this.setDefaultView();
      })
    }
  }

	public openUtilisateur(utilisateur : IContact) {
		this.contact = utilisateur;
		this.showInvitation = false;
		this.showListUtilisateur = false;
		this.showFormInvitation = true;
	}
  
  private setDefaultView(): void{
    this.showListUtilisateur = true;
    this.showFormInvitation = false;
    this.showInvitation = false;
  }
  
	public handleInvitation()
	{
		this.showInvitation = true;
		this.showListUtilisateur = false;
	}

	public onCancel = () => {
		this.showInvitation = false;
		this.showListUtilisateur = true;
		this.showFormInvitation = false;
	}

	public onCancelForm = () => {
		this.showInvitation = true;
		this.showListUtilisateur = false;
		this.showFormInvitation = false;
	}

	public onShowForm = (user :IUser) => {
		this.userFind = user;	
		this.showInvitation = false;
		this.showListUtilisateur = false;
		this.showFormInvitation = true;
	}

  private loadUtilisateurs(): void {
    this.svcContacts.getSiteContactsAnakin([], EPrefix.contact, true, true).pipe(
			map((utilisateurs: IContact[]) => {
        utilisateurs = utilisateurs.map((utilisateur: IContact) => ({
          ...utilisateur,
          avatarCouleur: this.getAvatarColor(utilisateur)
        }));
				return utilisateurs.filter(utilisateur => utilisateur.userId)
      }),
      tap((utilisateurs: IContact[]) => {
        this.utilisateurs = utilisateurs;
        this.nbUtilisateurs = this.utilisateurs.length;
      }),
      switchMap(() => this.svcGroupe.getContactsGroups(this.utilisateurs.map((utilisateur: IContact) => utilisateur._id))),
      tap((utilisateurGroups: Map<string, IGroup[]>) => {
        this.utilisateurs = this.utilisateurs.map((utilisateur: IContact) => {
          const groups = utilisateurGroups.get(utilisateur._id) || [];
          const tags = [];
          if (groups.some(group => group._id === 'grp_remplacant')) {
            tags.push(this.remplacantTag);
          }
					if (utilisateur.isAdmin) {
            tags.push(this.adminTag);
          }
          return {
            ...utilisateur,
            tags 
          } as IContact;
        })
      }),
    ).subscribe();
  }

  getAvatarColor(utilisateur: IContact) {
    return utilisateur.avatarCouleur ?? 'CouleurPrimaire';
  }
}
