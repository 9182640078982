<div class="di-ordonnance-stop">
	<div class="info">
		<p>
			Les soins à venir vont être supprimés, les soins passés seront conservés.
		</p>
		<lua-input label="Commentaire" [multiline]="true" [fullWidth]="true" [(ngModel)]="commentaire"></lua-input>
	</div>

	<lua-action-principale libelle="Arrêter l’ordonnance" [fullWidth]="true" [isDisabled]="!commentaire"
		(click)="onConfirm()"></lua-action-principale>
	<lua-bouton-texte libelle="Annuler" [fullWidth]="true" (click)="onCancel()"></lua-bouton-texte>
</div>