import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileHelper } from '@osapp/helpers';
import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { ETimetablePattern, IGalleryFile } from '@osapp/model';
import { DmsFile } from '@osapp/modules/dms/model/DmsFile';
import { LongGuidBuilder } from '@osapp/modules/guid/models/long-guid-builder';
import { IPatient } from 'apps/idl/src/modules/patients/model/IPatient';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AMCP } from '../../../../../../modules/patients/model/amc-p';
import { ECouvertureType } from '../../../../../../modules/patients/model/ecouverture-type.enum';
import { ETypePieceJustificative } from '../../../../../../modules/patients/model/etype-piece-justificative.enum';
import { EUpdateMode } from '../../../../../../modules/patients/model/eupdate-mode.enum';
import { IAMC } from '../../../../../../modules/patients/model/iamc';
import { CouverturesService } from '../../../../../../modules/patients/services/couvertures.service';
import { DrawerPopoverService } from '../../../services/drawer-popover.service';
import { PanneauService } from '../../../services/panneau.service';
import { UploadFileService } from '../../../services/uploadFile.service';
import { ConfirmationSuppressionComponent } from '../../confirmation-suppression/confirmation-suppression.component';

@Component({
  selector: 'di-panneau-patient-amc',
  templateUrl: './panneau-patient-amc.component.html',
  styleUrls: ['./panneau-patient-amc.component.scss'],
})
export class PanneauPatientAmcComponent implements OnInit {

  @Input()
  public patient: IPatient;

  @Input()
  public couverture: AMCP;

  @ViewChild('selectConvention', { static: false }) selectConvention!: ElementRef<HTMLDivElement>;



  public mutuelleList: IAMC[] = [];
  public filteredMutuelle: IAMC[] = [];
  public showOptions: boolean = false;
  public creationDateMessage = "";

  public piecesJustificatives = [
    { label: "Aucune pièce", value: ETypePieceJustificative.aucunePièce },
    { label: "Attestation de droits", value: ETypePieceJustificative.attestationDeDroits },
    { label: "Carte d'assuré social", value: ETypePieceJustificative.carteAssureSocial }
  ];

  public model: IPatient;

  public amcForm: FormGroup;

  public tierPayant = true;
  public gestionUnique = false;

  public errorMessagePhoto: string = "Le fichier sélectionné est trop volumineux (5Mo maximum)";
  public isErrorPhoto: boolean = false;

  public errorMessage: string;
  public isError: boolean = false;

  public listDoc: IGalleryFile[] = [];
  public listNewFile: File[] = [];
  private readonly guidBuilder = new LongGuidBuilder();


  public gestionUniqueControlsConfig = [
    { name: 'mutNum', value: '', validators: [Validators.required] },
  ];

  constructor(
    private svcCouvertures: CouverturesService,
    private svcDrawerPopover: DrawerPopoverService,
    private svcPanneau: PanneauService,
		private svcUploadFile: UploadFileService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.amcForm = this.fb.group({
      mutuelle: ['', [Validators.required]],
      numeroAdherent: [this.couverture ? this.couverture.numAdherent || '' : '', []],
      pieceJustificative: [this.couverture ? this.couverture.pieceJustificative || '' : '', []],
      dateDebut: [this.couverture?.dateDebut ? DateHelper.toDateUrl(new Date(this.couverture.dateDebut)) : DateHelper.toDateUrl(new Date(new Date().getFullYear(), 0, 1)), []],
      dateFin: [this.couverture?.dateFin ? DateHelper.toDateUrl(new Date(this.couverture.dateFin)) : DateHelper.toDateUrl(new Date(new Date().getFullYear(), 11, 31)), []],
      mutnum: [this.couverture ? this.couverture.gestionUnique && this.couverture.mutnum || '' : '', []],
    });



    this.svcCouvertures.getEtablissements(ECouvertureType.AMCP).subscribe((amcs: IAMC[]) => {
      this.mutuelleList = amcs;
      this.filteredMutuelle = this.mutuelleList;
      if (this.couverture) {
        this.onOptionClick(ArrayHelper.getFirstElement(this.filteredMutuelle.filter((mutuelle: IAMC) => mutuelle._id === this.couverture.amcId)))
        this.tierPayant = this.couverture.tp ?? false;
        this.gestionUnique = this.couverture.gestionUnique ?? false;
        let creationType: string = "";
        switch (this.couverture.updateMode) {
          case EUpdateMode.ADRi:
            creationType = "par appel ADRI";
            break;
          case EUpdateMode.carteVitale:
            creationType = "par lecture de carte vitale";
            break;
          case EUpdateMode.manual:
          default:
            creationType = "manuelle"
            break;
        }
        this.creationDateMessage = `Création ${creationType} le ` + DateHelper.transform(new Date(this.couverture.updateDate), ETimetablePattern.dd_MM_yyyy_HH_mm_slash)
        this.listDoc = ArrayHelper.hasElements(this.couverture.files) ? [...this.couverture.files] : [];
      } else {
        this.couverture = new AMCP(this.model._id);
        this.creationDateMessage = `Création manuelle le ` + DateHelper.transform(new Date(), ETimetablePattern.dd_MM_yyyy_HH_mm_slash)
      }
    })
    this.model = this.patient;
  }


  ngOnChanges(changes: SimpleChanges): void {
    //si l'utilisateur met gestion unique à true
    if (changes.gestionUnique) {
      if (this.gestionUnique) {
        this.gestionUniqueControlsConfig.forEach(control => {
          this.amcForm.addControl(
            control.name,
            this.fb.control(control.value, [])
          );
        });
      } else {
        this.gestionUniqueControlsConfig.forEach(control => {
          this.amcForm.removeControl(control.name);
        });
      }
    }
  }

  public async addAttestation(file: File) {
    if (file) {
      if (file.size > 1000 * 1024) {
				try {
						const newFile: File = await FileHelper.resizeImageAnakn(file);
						this.listNewFile.push(newFile);
				} catch (error) {
						this.isError = true;
						console.error('Error resizing file:', error);
				}	
			}
      else {
        this.isErrorPhoto = false;
        this.listNewFile.push(file);
      }
    }
  }

  public getFormatedId(poEtablissement: IAMC): string {
    return poEtablissement._id.split("_")[1];
  }

  public deleteNewFile(event: File): void {
    this.listNewFile = [];
  }

  public deleteOldFile(event: IGalleryFile): void {
    const index = this.listDoc.findIndex(file =>
      file.guid === event.guid
    );

    if (index !== -1) {
      this.listDoc.splice(index, 1);
    }
  }


  public toggleTierPayant(tierPayant: boolean) {
    this.tierPayant = tierPayant;
  }

  public toggleGestionUnique(gestionUnique: boolean) {
    this.gestionUnique = gestionUnique;
  }

  public goBack(): void {
    this.svcPanneau.close();
  }

  public handleSubmit(event: Event): void {
    event.preventDefault();
    if (this.amcForm.valid) {
      const formValues = this.amcForm.value;
      const updateHistory = {
        date: this.couverture.updateDate = new Date(),
        mode: this.couverture.updateMode = EUpdateMode.manual
      };

      this.listNewFile.map((file: File) => {
        const dmsFile: DmsFile = new DmsFile(file, file.name);
        const galleryFile: IGalleryFile = {
          file: dmsFile,
          isNew: true,
          name: dmsFile.Name,
          description: "",
          guid: this.guidBuilder.build({ withHyphens: false, upperCase: false })
        };
        this.listDoc.push(galleryFile);
      });

      if (!this.couverture.files)
        this.couverture.files = [];
      this.couverture.files = this.listDoc;

      this.couverture.tp = this.tierPayant;
      this.couverture.numAdherent = formValues.numeroAdherent;
      this.couverture.dateDebut = formValues.dateDebut;
      this.couverture.dateFin = formValues.dateFin;
      this.couverture.pieceJustificative = formValues.pieceJustificative;
      this.couverture.datePieceJustificative = new Date();
      this.couverture.gestionUnique = this.gestionUnique;
      this.couverture.mutnum = this.gestionUnique ? formValues.mutnum : undefined;

      this.svcUploadFile.saveFilesWithErrorHandler(this.couverture.files, this.couverture._id).pipe(
        mergeMap(() =>
          this.svcCouvertures.saveCouvertureANAKIN(this.couverture, this.selectConvention)
        ),
        catchError((error) => {
          this.errorMessage = error.error.message;
          this.isError = true;
          return of(null);
        })
      ).subscribe();
    }
  }

  public deleteAmc(event: Event) {
    //TODO: ajouter un loader
    this.svcDrawerPopover.open("Confirmation de suppression", "30%", event.currentTarget, ConfirmationSuppressionComponent, {
      onConfirm: () => this.handleConfirm(),
      onCancel: () => this.handleCancel()
    })
  }

  private handleConfirm() {
    this.svcDrawerPopover.close();
    this.svcCouvertures.deleteCouverture(this.couverture).subscribe((response: boolean) => {
      if (response) {
        this.svcPanneau.close();
      } else {
        console.error("La suppression de la couverture a échoué")
      }
    });
  }

  private handleCancel() {
    this.svcDrawerPopover.close();
  }

  public onOptionClick(option: IAMC): void {
    this.couverture.amcId = option._id;
    this.amcForm.get('mutuelle')?.setValue(option.label);
    this.amcForm.get('mutnum')?.setValue(option.mutnum ?? '');
    this.showOptions = false;
  }

  public onInputClick(): void {
    this.updateFilteredEtablissements("");
    this.showOptions = true;
  }

  public onInputChange(value: string): void {
    this.updateFilteredEtablissements(value);
  }

  public updateFilteredEtablissements(search: string) {
    if (search.length === 0) {
      this.filteredMutuelle = this.mutuelleList;
    } else {
      this.filteredMutuelle = this.mutuelleList.filter((etablissement: IAMC) => {
        return etablissement.label.toLowerCase().includes(search.toLowerCase()) ||
          etablissement._id.replace("-", "").includes(search);
      });
    }
  }

}
