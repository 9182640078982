import { Component, Input, OnInit } from "@angular/core";
import { DateHelper } from "@osapp/helpers";
import { ETimetablePattern, IContact } from "@osapp/model";
import { DestroyableComponentBase } from "@osapp/modules/utils/components/destroyable-component-base";
import { ContactsService } from "@osapp/services";
import { Invoice } from "apps/idl/src/modules/facturation/models/invoice";
import { IPatient } from "apps/idl/src/modules/patients/model/IPatient";
import { Observable, forkJoin } from "rxjs";
import { takeUntil, tap } from 'rxjs/operators';
import { Traitement } from "../../../../../model/Traitement";
import { FacturationService } from "../../../../../modules/facturation/facturation.service";
import { PatientsService } from "../../../../../modules/patients/services/patients.service";
import { DeviceService } from "../../../shared/services/device.service";
import { DrawerPopoverService } from "../../../shared/services/drawer-popover.service";
import { PanneauService } from "../../../shared/services/panneau.service";
import { EtatTiersPayantComponent } from "../etat-tiers-payant/etat-tiers-payant.component";
import { ModeDeReglementComponent } from "../mode-de-reglement/mode-de-reglement.component";
import { PanneauDetailFactureComponent } from "../panneau-detail-facture/panneau-detail-facture.component";



@Component({
	selector: "di-carte-facturation",
	templateUrl: "./carte-facturation.component.html",
	styleUrls: ["./carte-facturation.component.scss"],
})

export class CarteFactureComponent extends DestroyableComponentBase implements OnInit {
	@Input() invoice: Invoice

	public isMobile: boolean = false;
	public total: number = 0;
	public showPopover: boolean = false;
	public showTag: boolean = false;
	public adressePatient: string = '';
	public tag: { label: string; color: string; mode: string };

	public dateInvoice: string = "";



	constructor(
		private svcDevice: DeviceService,
		private svcContact: ContactsService,
		private svcDrawerPopover: DrawerPopoverService,
		private svcFacturation: FacturationService,
		private svcPatient: PatientsService,
		private svcPanneau: PanneauService
	) {
		super();
	}

	ngOnInit(): void {
		this.svcDevice.isMobile$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((flag: boolean) => {
				this.isMobile = flag;
			});
		if (this.invoice) {
			this.dateInvoice = DateHelper.transform(this.invoice.date, ETimetablePattern.dd_MM_yyyy_HH_mm_slash);
			let patient$: Observable<IPatient>;
			if (!this.invoice.patient) {
				const patientId = Traitement.extractPatientId(this.invoice.traitementId);
				patient$ = this.svcPatient.getPatient(patientId).pipe(
					tap((patient: IPatient) => {
						this.invoice.patient = patient;
						this.adressePatient = this.getAdressePatient(patient);
					})
				);
			}

			let intervenant$ = null;
			if (!this.invoice.infirmier && this.invoice.intervenantId) {
				intervenant$ = this.svcContact.getContact(this.invoice.intervenantId).pipe(
					tap((intervenant) => {
						this.invoice.infirmier = intervenant;
					})
				);
			}

			if (intervenant$ && patient$) {
				forkJoin([patient$, intervenant$]).subscribe();
			} else if (intervenant$) {
				patient$.subscribe();
			} else if (patient$) {
				patient$.subscribe();
			}
		}




		this.total = this.invoice?.totalPartAMC + this.invoice?.totalPartAMO + this.invoice?.totalPartPatient
	}

	handleClick(event: Event, choix: string) {
		event.stopPropagation();
		switch (true) {
			case choix === 'amo':
				this.openPanneauTier(choix);
				break;
			case choix === 'amc':
				this.openPanneauTier(choix);
				break;
			case choix === 'pp':
				this.openPanneauReglement();
				break;
		}
	}

	handleClickCarte(event: Event) {
		const panneauTitle = "Détail de la facture";
		const panneauContent = PanneauDetailFactureComponent;
		const panneauInputs = {
			invoice: this.invoice,
			patient: this.invoice.patient,
			infirmier: this.invoice.infirmier
		};
		this.svcPanneau.open(panneauTitle, panneauContent, panneauInputs);
	}

	public openPanneauReglement(): void {

		this.showPopover = true;
		this.svcDrawerPopover?.open(
			"Mode de règlement",
			"50%",
			event.currentTarget,
			ModeDeReglementComponent,
			{
				facture: this.invoice
			},
			() => (this.showPopover = false)
		);
	}

	public openPanneauTier(choix: string): void {
		let titre: string;
		switch (true) {
			case choix == "amo":
				titre = "Part Sécurité sociale"
				break
			case choix == "amc":
				titre = "Part Mutuelle"
				break
		}
		this.showPopover = true;
		this.svcDrawerPopover?.open(
			titre,
			"50%",
			event.currentTarget,
			EtatTiersPayantComponent,
			{
				facture: this.invoice,
				typeTier: choix
			},
			() => (this.showPopover = false)
		);
	}

	public getAdressePatient(pat: IPatient): string {
		if (!pat) return "";

		const addressParts: string[] = [];
		if (pat.street) {
			addressParts.push(pat.street);
		}
		if (pat.zipCode) {
			addressParts.push(pat.zipCode);
		}
		if (pat.city) {
			addressParts.push(pat.city);
		}

		return addressParts.join(" ");
	}
}
