<div class="panneau-detail-facture__container">
	<div class="container__content">
		<div class="facture-container">
			<header class="facture-header">
				<div class="facture-title">
					<h1>Facture</h1>
					<h1>{{ total | priceFormat:2 }} €</h1>
				</div>
				<div class="facture-subtitle">
					<small class="facture-number">N° {{invoice.invoiceNumber}}</small>
					<small class="facture-date">du {{dateInvoice}}</small>
				</div>
				<div class="facture-actions">
					<lua-bouton-toolbar iconName="download" (click)="printInvoice()" [primary]="true" labelType="vertical"
						label="Facture"></lua-bouton-toolbar>
					<lua-bouton-toolbar iconName="print" [primary]="true" (click)="printFsp()" labelType="vertical"
						label="Feuille de soins"></lua-bouton-toolbar>
					<lua-bouton-toolbar iconName="cancel" [primary]="true" color="Error" labelType="vertical" [disabled]="true"
						label="Annuler"></lua-bouton-toolbar>
				</div>

			</header>

			<section class="section">
				<div class="section-head">
					<h2>Patient</h2>
					<h2>{{invoice?.totalPartPatient | priceFormat:2 }} €</h2>
				</div>
				<di-part-detail-facturation [text]="invoice?.commentairePat" [status]="invoice?.statusPat ?? 'aRegler'"
					typePart="pp" [reglementType]="invoice?.modeReglementPat" (click)="handleClick($event,'pp')"
					[date]="invoice?.datePat"></di-part-detail-facturation>
				<div class="detail-row">
					<small>Nom prénom</small> <strong class="actionPatient" (click)="ouvrirDossierPatient()">{{fullLastName |
						uppercase}} {{patient.firstName | firstUpperCase}}
					</strong>
				</div>
				<div class="detail-row">
					<small>Date de naissance</small> <strong>{{ birthDate }}</strong>
				</div>
				<div class="detail-row">
					<small>Adresse</small> <strong>{{ patient.street }}</strong>
				</div>
				<div class="detail-row">
					<small>Code postal</small> <strong>{{ patient.zipCode }}</strong>
				</div>
				<div class="detail-row">
					<small>Ville</small> <strong>{{ patient.city }}</strong>
				</div>
			</section>

			<section class="section">
				<div class="section-head">
					<h2>Sécurité sociale</h2>
					<h2>{{invoice?.totalPartAMO | priceFormat:2 }} €</h2>
				</div>
				<di-part-detail-facturation [text]="invoice?.commentaireAMO" [date]="invoice?.dateAMO"
					[status]="invoice?.statusAMO ?? 'aEnvoyer'" typePart="amo"
					(click)="handleClick($event,'amo')"></di-part-detail-facturation>
				<div class="detail-row">
					<small>Tiers</small> <strong>{{invoice.labelAMO ?? '-'}}</strong>
				</div>
				<div class="detail-row">
					<small>N°</small> <strong>{{amoInfo}}</strong>
				</div>
			</section>

			<section class="section">
				<div class="section-head">
					<h2>Mutuelle</h2>
					<h2>{{invoice?.totalPartAMC | priceFormat:2 }} €</h2>
				</div>
				<di-part-detail-facturation [text]="invoice?.commentaireAMC" [date]="invoice?.dateAMC"
					[status]="invoice?.statusAMC ?? 'aEnvoyer'" typePart="amc"
					(click)="handleClick($event,'amc')"></di-part-detail-facturation>
				<div class="detail-row">
					<small>Tiers</small> <strong>{{invoice.labelAMC ?? '-'}}</strong>
				</div>
				<div class="detail-row">
					<small>N° télétransmission</small> <strong>{{amcInfo}}</strong>
				</div>
				<div class="detail-row">
					<small>N° adhérent</small> <strong>{{ invoice.amcNumeroAdherent ?? '-' }}</strong>
				</div>
			</section>

			<section *ngIf="infirmier" class="section">
				<div class="section-head">
					<h2>Infirmier</h2>
				</div>
				<div class="detail-row">
					<small>Nom prénom</small> <strong>{{infirmier.lastName |
						uppercase}} {{infirmier.firstName | firstUpperCase}}
					</strong>
				</div>
				<div class="detail-row">
					<small>N° Finess</small> <strong>{{ infirmier.finess }}</strong>
				</div>
				<div class="detail-row">
					<small>N° RPPS / Adeli</small> <strong>{{ infirmier.rpps ?? infirmier.adeli }}</strong>
				</div>
			</section>

			<section class="section">
				<div class="section-head">
					<h2>Soins facturés</h2>
				</div>
				<div class="liste-actes-head">
					<small>DATE</small>
					<small>AMO</small>
					<small>AMC</small>
					<small>PAT.</small>
					<small>HONO.</small>
				</div>
				<ng-container *ngFor="let acte of invoice.actes">
					<div class="detail-row acte">
						<small>{{acte.date | dateWithLocale: 'fr-FR' : 'dd/MM/yyyy'}}</small>
						<div class="acte-info">
							<p>{{acte.description}}</p>
							<div class="acte-info-tarif">
								<small>{{acte.partAMO | priceFormat:2 }} </small>
								<small>{{acte.partAMC | priceFormat:2 }} </small>
								<small>{{acte.partPatient | priceFormat:2 }} </small>
								<small>{{acte.honoraires | priceFormat:2 }} </small>
							</div>
						</div>
					</div>
				</ng-container>

			</section>
		</div>
	</div>
</div>