import { Injectable, ComponentRef, Type } from "@angular/core";
import {
	PopoverComponent,
	SwipeableDrawerComponent,
} from "lighting-up-angular";
import { Subject, Subscription } from "rxjs";
import { DeviceService } from "./device.service";
import { DestroyableServiceBase } from "@osapp/modules/utils/services/destroyable-service-base";
import { takeUntil } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class DrawerPopoverService extends DestroyableServiceBase {
	
	private drawerPopoverCloseSubscription: Subscription | null = null;

	constructor(private svcDevice: DeviceService) {
		super();
		this.svcDevice.isMobile$.pipe(
			takeUntil(this.destroyed$)
		).subscribe((flag: boolean) => {
			this.isMobile = flag;
		});
	}

	private drawerComponentRef: ComponentRef<SwipeableDrawerComponent>;
	private popoverComponentRef: ComponentRef<PopoverComponent>;
	private closePopoverSubject = new Subject<void>();
	isMobile = false;

	setPopoverComponentRef(componentRef: ComponentRef<PopoverComponent>) {
		this.popoverComponentRef = componentRef;
	}

	setDrawerComponentRef(componentRef: ComponentRef<SwipeableDrawerComponent>) {
		this.drawerComponentRef = componentRef;
	}

	open(
		title: string,
		height: string,
		anchorElement: any,
		content: Type<any>,
		inputs: any,
		onClose?: () => void,
		anchorRect?: DOMRect
	) {
		if (this.isMobile) {
			if (this.drawerComponentRef) {
				this.drawerComponentRef.instance.setContent(content, inputs);
				this.drawerComponentRef.instance.title = title;
				this.drawerComponentRef.instance.height = height;
				this.drawerComponentRef.instance.visible = true;
				if (this.drawerPopoverCloseSubscription) {
					this.drawerPopoverCloseSubscription.unsubscribe();
				}
				if(onClose){
					this.drawerPopoverCloseSubscription = this.drawerComponentRef.instance.onDrawerClose.subscribe(() => {
						onClose();
					});
				}
			}
		} else {
			if (this.popoverComponentRef) {
				this.popoverComponentRef.instance.setContent(content, inputs);
				this.popoverComponentRef.instance.title = title;
				this.popoverComponentRef.instance.anchorElement = anchorElement;
				this.popoverComponentRef.instance.anchorRect = anchorRect;
				this.popoverComponentRef.instance.open = true;
				if (this.drawerPopoverCloseSubscription) {
					this.drawerPopoverCloseSubscription.unsubscribe();
				}
				this.drawerPopoverCloseSubscription = this.popoverComponentRef.instance.onPopoverClose.subscribe(() => {
					if(onClose){
						onClose();
					}
					this.close();
				});
			}
		}
	}

	close() {
		if (this.popoverComponentRef) {
			this.popoverComponentRef.instance.open = false;
			this.popoverComponentRef.instance.isReady = false;
			this.closePopoverSubject.next();
		}
		if(this.drawerComponentRef) {
			this.drawerComponentRef.instance.close();
			this.closePopoverSubject.next();
		}
	}

	get closePopover$() {
		return this.closePopoverSubject.asObservable();
	}
}
