import { ArrayHelper } from '@osapp/helpers/arrayHelper';
import { DateHelper } from '@osapp/helpers/dateHelper';
import { IdHelper } from '@osapp/helpers/idHelper';
import { NumberHelper } from '@osapp/helpers/numberHelper';
import { StoreHelper } from '@osapp/helpers/storeHelper';
import { IGalleryFile } from '@osapp/model';
import { EPrefix } from '@osapp/model/EPrefix';
import { UserData } from '@osapp/model/application/UserData';
import { ETimetablePattern } from '@osapp/model/date/ETimetablePattern';
import { ModelResolver } from '@osapp/modules/utils/models/model-resolver';
import { Exclude, Expose } from 'class-transformer';
import { findLast, isEqual } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { C_PREFIX_PATIENT, C_PREFIX_TRAITEMENT } from '../app/app.constants';
import { Constraint } from '../modules/actes/model/constraint';
import { EOriginePrescription } from '../modules/ordonnances/models/eorigine-prescription.enum';
import { IRenouvellementOrdonnance } from '../modules/ordonnances/models/irenouvellement-ordonnance';
import { IPatient } from '../modules/patients/model/IPatient';
import { Deplacement } from '../modules/traitement/model/Deplacement';
import { EIndemniteType } from '../modules/traitement/model/EIndemniteType';
import { ETraitementTags } from '../modules/traitement/model/ETraitementTags';
import { ExtraCharge } from '../modules/traitement/model/extra-charge';
import { IExtraCharge } from '../modules/traitement/model/iextra-charge';
import { Acte } from './Acte';
import { EMajorationType } from './EMajorationType';
import { EPathologie } from './EPathologies';
import { ETraitementState } from './ETraitementState';
import { IActe } from './IActe';
import { ITraitement } from './ITraitement';
import { Seance } from './Seance';

export class Traitement implements ITraitement {

	//#region FIELDS

	/** Pourcentage remboursé par la CPAM, calculé automatiquement si un changement a lieu dans 'mnPatientPercent'. */
	private mnCpamPercent: number;
	/** Pourcentage à payer par le patient, calculé automatiquement si un changement a lieu dans 'mnCpamPercent'. */
	private mnPatientPercent: number;

	//#endregion

	//#region PROPERTIES

	public static readonly C_NB_DAYS_ENDING_SOON = 7;

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev: string;
	/** @implements */
	public actes: Array<Acte> = [];
	/** @implements */
	public beginDate: Date;
	/** @implements */
	public endDate: Date;
	/** @implements */
	public deplacement: Deplacement;
	/** @implements */
	public isIkEnable: boolean;
	/** @implements */
	public createDate: Date;
	/** @implements */
	public breakDate?: Date;
	/** @implements */
	public resumeActes?: string = "";
	/** @implements */
	@Exclude()
	public nbSeances?: number;
	/** @implements */
	public explanation?: string;
	/** @implements */
	public name?: string;
	/** @implements */
	public isBillingLocked?: boolean;
	/** @implements */
	public billingLockedReason?: string;
	public stopComment?: string;
	public stopDate?: Date;
	public get label(): string {
		return `Traitement du ${DateHelper.transform(this.beginDate, ETimetablePattern.EEE_dd_MMMM_yyyy)}${this.endDate ? ` au ${DateHelper.transform(this.endDate, ETimetablePattern.EEE_dd_MMMM_yyyy)}` : ""}`;
	};
	/** @implements */
	public tags?: ETraitementTags[];
	/** @implements */
	public pathologies?: EPathologie[];
	/** @implements */
	public state?: ETraitementState;

		/** @implements */
		public ordonnanceOrigineId?: string;



	public prescriptionDate?: string | Date;
	/** Informations à renseigner si l'ordonnance est un renouvellement. */
	public renouvellement?: IRenouvellementOrdonnance;
	/** Identifiant de contact du prescripteur. */
	public prescripteurContactId?: string;
	/** Origine de la prescription. */
	public originePrescription?: EOriginePrescription;
	/** Inquide si l'ordonnance correspond à une affection de longue durée. */
	public isAld?: boolean;
	public isAdc?: boolean;
	/** Inquide si l'ordonnance correspond à un accident de droit commun en précisant sa date. */
	public adcDate?: Date;
	/** Liste des documents de l'ordonnance dans le DMS. */
	public documents?: IGalleryFile[];
	/** Guids des actes liés */
	public linkedActesGuids?: string[];
	public description?: string;

	@Exclude()
	private maMajorations: ExtraCharge<EMajorationType>[];
	/** @implements */
	@Expose()
	public get majorations(): ReadonlyArray<ExtraCharge<EMajorationType>> {
		return this.maMajorations;
	}
	public set majorations(paMajorations: ReadonlyArray<ExtraCharge<EMajorationType>>) {
		if (paMajorations)
			this.maMajorations = paMajorations.map((poMajoration: IExtraCharge) => ModelResolver.toClass(poMajoration, ExtraCharge));
	}
	
	@Exclude()
	public countSeancesTotal?: number;
	
	@Exclude()
	public countSeancesDone?: number;
	
	@Exclude()
	public countSeancesCompleted?: number;

	@Exclude()
	public countSeancesDoneByDateFacturation?: number;
	
	@Exclude()
	public countSeancesToBeDoneByDateFacturation?: number;
	
	@Exclude()
	public countSeancesCancelledByDateFacturation?: number;

	@Exclude()
	public patient?: IPatient;

	@Exclude()
	private maIndemnites: ExtraCharge<EIndemniteType>[];
	/** @implements */
	@Expose()
	public get indemnites(): ReadonlyArray<ExtraCharge<EIndemniteType>> {
		return this.maIndemnites;
	}
	public set indemnites(paIndemnites: ReadonlyArray<ExtraCharge<EIndemniteType>>) {
		if (paIndemnites)
			this.maIndemnites = paIndemnites.map((poIndemnite: IExtraCharge) => ModelResolver.toClass(poIndemnite, ExtraCharge));
	}

	/** @implements */
	public get constraints(): Constraint[] {
		return ArrayHelper.flat(this.actes.map((poActe: Acte) => poActe.constraints));
	};
	/** Indique si le traitement arrive bientôt à échéance. */
	public get endsSoon(): boolean {
		return Traitement.endsSoon(this);
	}

	@Exclude()
	private msPatientId: string;
	/** Id du Patient. */
	public get patientId(): string {
		if (!this.msPatientId)
			this.msPatientId = Traitement.extractPatientId(this._id);

		return this.msPatientId;
	}

	@Exclude()
	private msSiteId: string;
	/** Id du Site. */
	public get siteId(): string {
		if (!this.msSiteId)
			this.msSiteId = Traitement.extractSiteId(this._id);

		return this.msSiteId;
	}

	@Exclude()
	private msGuid: string;
	public get guid(): string {
		if (!this.msGuid)
			this.msGuid = Traitement.extractGuid(this._id);

		return this.msGuid;
	}

	@Exclude()
	private readonly moSeancesSubject = new BehaviorSubject<Seance[]>([]);

	public get seances$(): Observable<Seance[]> { return this.moSeancesSubject.asObservable(); };

	public get seances(): Seance[] {
		return this.moSeancesSubject.value;
	}
	public set seances(paSeances: Seance[]) {
		if (paSeances)
			this.moSeancesSubject.next(paSeances);
	}

	//#endregion

	//#region METHODS
	constructor(psPatientId: string) {
		this.createDate = new Date();
		this.endDate = this.beginDate = DateHelper.resetDay(this.createDate);

		this._id = IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([UserData.currentSite?._id, psPatientId]));

		this.deplacement = new Deplacement();

		this.mnCpamPercent = 60; // Pourcentage de remboursement par défaut de la cpam.
		this.mnPatientPercent = 40; // Pourcentage à payer par défaut par le patient.
	}

	/** Crée une instance de traitement à partir des données d'un objet similaire récupérées en base de données.
	 * @param poData Données de l'objet récupérées en bases de données dont il faut créer une instance de `Traitement`.
	 */
	public static createFromData(poData: ITraitement): Traitement {
		const loTraitement: Traitement = new Traitement("");
		if (poData) {
			Object.assign(loTraitement, poData);

			loTraitement.actes = poData.actes.map((poActe: IActe) => new Acte(poActe));
			loTraitement.beginDate = new Date(poData.beginDate);
			loTraitement.endDate = new Date(poData.endDate);
			loTraitement.deplacement = Deplacement.getInstanceFromData(poData.deplacement);
			loTraitement.createDate = new Date(poData.createDate);
			loTraitement.breakDate = poData.breakDate ? new Date(poData.breakDate) : undefined;
			loTraitement.tags = ArrayHelper.hasElements(poData.tags) ? poData.tags : [];
			loTraitement.pathologies = ArrayHelper.hasElements(poData.pathologies) ? poData.pathologies : [];
		}

		return loTraitement;
	}

	/** Récupère le pourcentage de remboursement de la cpam. */
	public getCpamPercent(): number {
		return this.mnCpamPercent;
	}

	/** Récupère le pourcentage à payer par le patient. */
	public getPatientPercent(): number {
		return this.mnPatientPercent;
	}

	/** Modifie le pourcentage remboursé par la cpam et ajuste automatiquement le pourcentage restant à payer par le patient.
	 * @param pnValue Nouveau pourcentage de remboursement de la cpam.
	 * @return `true` si la valeur a été modifiée.
	 */
	public setCpamPercent(pnValue: number): boolean {
		if (NumberHelper.isValidPositive(pnValue) && pnValue !== this.mnCpamPercent) {
			this.mnCpamPercent = pnValue;
			this.mnPatientPercent = 100 - pnValue;
			return true;
		}
		else
			return false;
	}

	/** Modifie le pourcentage à payer par le patient et ajuste automatiquement le pourcentage remboursé par la cpam.
	 * @param pnValue Nouveau pourcentage à payer par le patient.
	 * @return `true` si la valeur a été modifiée.
	 */
	public setPatientPercent(pnValue: number): boolean {
		if (NumberHelper.isValidPositive(pnValue) && pnValue !== this.mnPatientPercent) {
			this.mnPatientPercent = pnValue;
			this.mnCpamPercent = 100 - pnValue;
			return true;
		}
		else
			return false;
	}

	/** Indique si le traitement passé en paramètre arrive bientôt à échéance.
	 * @param poTraitement
	 */
	public static endsSoon(poTraitement: ITraitement): boolean {
		return Traitement.isTraitementActive(poTraitement) && DateHelper.diffDays(poTraitement.endDate, new Date()) <= this.C_NB_DAYS_ENDING_SOON && ArrayHelper.hasElements(poTraitement.actes);
	}

	/** Indique si le traitement est actif.
	 * @param poTraitement
	 * @param pdToday
	 */
	public static isTraitementActive(poTraitement: ITraitement, pdToday: Date = new Date(), pbUseBreakDate: boolean = true): boolean {
		const ldBreak = DateHelper.isDate(poTraitement.breakDate) && pbUseBreakDate ? new Date(poTraitement.breakDate) : undefined;
		return poTraitement.state !== ETraitementState.termine &&
			(!DateHelper.isDate(ldBreak) || DateHelper.diffDays(ldBreak, pdToday) >= 0);
	}

	public addMajoration(poExtraCharge: IExtraCharge<EMajorationType>): ExtraCharge<EMajorationType> {
		return this.addExtraCharge(this.maMajorations ?? (this.maMajorations = []), poExtraCharge);
	}

	public addIndemnite(poExtraCharge: IExtraCharge<EIndemniteType>): ExtraCharge<EIndemniteType> {
		if (ArrayHelper.hasElements(this.maIndemnites) && !poExtraCharge.disabled) {
			if (!poExtraCharge.matcher) {
				// On supprime l'IFD si IFI, et l'IFI si IFD
				if (poExtraCharge.id === EIndemniteType.IFD)
					ArrayHelper.removeElementsByFinder(this.maIndemnites, (poItem: IExtraCharge<EIndemniteType>) => poItem.id === EIndemniteType.IFI);
				else if (poExtraCharge.id === EIndemniteType.IFI)
					ArrayHelper.removeElementsByFinder(this.maIndemnites, (poItem: IExtraCharge<EIndemniteType>) => poItem.id === EIndemniteType.IFD);
			}
			else if (poExtraCharge.id === EIndemniteType.IFD)
				this.removeLastMatchingIndemnite(poExtraCharge, EIndemniteType.IFI);
			else if (poExtraCharge.id === EIndemniteType.IFI)
				this.removeLastMatchingIndemnite(poExtraCharge, EIndemniteType.IFD);
		}

		return this.addExtraCharge(this.maIndemnites ?? (this.maIndemnites = []), poExtraCharge);
	}

	private removeLastMatchingIndemnite(poExtraCharge: IExtraCharge<EIndemniteType>, peIndemniteType: EIndemniteType) {
		ArrayHelper.removeLastElementByFinder(this.maIndemnites, (poItem: IExtraCharge<EIndemniteType>) =>
			poItem.id === peIndemniteType && isEqual(poExtraCharge.matcher, poItem.matcher)
		);
	}

	private addExtraCharge<T extends string>(paExtraCharges: ExtraCharge<T>[], poExtraCharge: IExtraCharge<T>): ExtraCharge<T> {
		let loExtraCharge: ExtraCharge<T> = ModelResolver.toClass(poExtraCharge, ExtraCharge);

		if (!poExtraCharge.matcher && ArrayHelper.hasElements(paExtraCharges)) // Dans le cas d'un supplément en mode 'all', on peut enlever les anciens suppléments avec le même id car ils seront tous activés ou désactivés.
			ArrayHelper.removeElementsByFinder(paExtraCharges, (poOldExtraCharge: ExtraCharge<T>) => poOldExtraCharge.id === poExtraCharge.id);
		else {
			const loOldExtraCharge: ExtraCharge<T> = findLast(paExtraCharges, (poOldExtraCharge: ExtraCharge<T>) => poOldExtraCharge.id === poExtraCharge.id &&
				isEqual(poExtraCharge.matcher, poOldExtraCharge.matcher)
			);

			if (loOldExtraCharge) {
				loOldExtraCharge.updateDate = loExtraCharge.createDate;
				loOldExtraCharge.updateUserContactId = loExtraCharge.createUserContactId;
				loOldExtraCharge.disabled = loExtraCharge.disabled;
				loExtraCharge = loOldExtraCharge;
			}
		}

		if (loExtraCharge)
			ArrayHelper.pushIfNotPresent(paExtraCharges, loExtraCharge);

		StoreHelper.makeDocumentDirty(this);
		return loExtraCharge;
	}

	public static extractPatientId(psTraitementId: string): string {
		return IdHelper.extractAllIds(psTraitementId).find((psId: string) => psId.startsWith(C_PREFIX_PATIENT)) ?? "";
	}

	public static extractSiteId(psTraitementId: string): string {
		return IdHelper.extractAllIds(psTraitementId).find((psId: string) => psId.startsWith(EPrefix.site)) ?? "";
	}

	public static extractGuid(psTraitementId: string): string {
		return IdHelper.getLastGuidFromId(psTraitementId);
	}

	public static createId(psPatientId: string, psSiteId: string, psGuid?: string): string {
		return IdHelper.buildChildId(C_PREFIX_TRAITEMENT, IdHelper.buildVirtualNode([psSiteId, psPatientId]), psGuid);
	}

	public isLastSeance(poSeance: Seance): boolean {
		return DateHelper.diffDays(poSeance.endDate, this.endDate) === 0 &&
			poSeance.equals(ArrayHelper.getLastElement(this.seances));
	}

	//#endregion
}