import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
	selector: "di-etat-facturation",
	templateUrl: "./etat-facturation.component.html",
	styleUrls: ["./etat-facturation.component.scss"],
})
export class EtatFacturationComponent implements OnInit {

	billDetails: { text: string; class: string; iconColor: string; iconeName: string } = {
    text: '',
    class: '',
    iconColor: '',
    iconeName: '',
  };

	
	constructor() { }


	@Input() totalSeance: number = 0;
	@Input() billedStatus!: "all" | "some" | "none";

	ngOnInit(): void {
    this.updateBillDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.billedStatus || changes.totalSeance) {
      this.updateBillDetails();
    }
  }

	private updateBillDetails(): void {
    switch (this.billedStatus) {
      case 'all':
        this.billDetails = {
          text: 'Toutes les séances sont facturées',
          class: 'all',
          iconColor: 'Indigo',
          iconeName: 'check_circle',
        };
        break;

      case 'none':
        this.billDetails = {
          text: 'Aucune séance à facturer',
          class: 'none',
          iconColor: 'Noir80',
          iconeName: 'info',
        };
        break;

      default:
        this.billDetails = {
          text: `${this.totalSeance} séance${this.totalSeance > 1 ? 's' : ''} à facturer`,
          class: 'some',
          iconColor: 'Info',
          iconeName: 'east',
        };
        break;
    }
  }
}
