import { Injectable, ComponentRef, ViewContainerRef, RendererFactory2, Renderer2 } from '@angular/core';
import { ImpressionTourneeComponent } from '../../tournees/components/impression-tournee/impression-tournee.component';

@Injectable({ providedIn: 'root' })
export class ImpressionService {
    private impressionViewRef!: ComponentRef<ImpressionTourneeComponent>;

    createPrintView(
        viewContainerRef: ViewContainerRef,
        tourneeDate: string,
        nurseName: string,
        sortedSeancesEvents
    ): HTMLElement {
        this.impressionViewRef = viewContainerRef.createComponent(ImpressionTourneeComponent);
        const instance = this.impressionViewRef.instance;
        instance.tourneeDate = tourneeDate;
        instance.nurseName = nurseName;
        instance.sortedSeancesEvents = sortedSeancesEvents;
        return this.impressionViewRef.location.nativeElement;
    }

    destroyPrintView(): void {
        if (this.impressionViewRef) {
            this.impressionViewRef.destroy();
            const element = this.impressionViewRef.location.nativeElement;
            if (element && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        }
    }
}