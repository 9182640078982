<div class="di-menu-mois">
	<ng-container *ngFor="let yearObj of yearsArray">
		<di-separateur-date [date]="yearObj.year"></di-separateur-date>
		<div class="select-month" *ngFor="let monthObj of yearObj.months">
			<di-mois-item 
				[month]="monthObj.month" 
				[year]="yearObj.year" 
				[invoiceCount]="monthObj.invoiceCount" 
				[montantTotal]="monthObj.montantTotal" 
				[selected]="yearObj.year === selectedYear && monthObj.month === selectedMonth"
				(monthSelected)="wrappedOnMonthSelected($event)"
				>
			</di-mois-item>
		</div>
	</ng-container>
</div>