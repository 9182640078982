<div class="di-part-detail-facturation" [ngClass]="statusClass">
	<div class="part-info">
		<ng-container *ngIf="showInfo">
			<p>{{isPP ? infoPart : statusTier.label}} <span *ngIf="date">, le {{ date | dateWithLocale: 'fr-FR' :
					'dd/MM/yyyy'}}</span>
			</p>
		</ng-container>
		<small>{{ text }}</small>
	</div>
	<di-tag-suivi-facture [etat]="status" [forceLabel]="labelStatus ?? null"></di-tag-suivi-facture>
	<lua-bouton-icone iconName="arrow_drop_down"></lua-bouton-icone>
</div>