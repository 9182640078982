import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateHelper } from '@osapp/helpers';
import { ETimetablePattern } from '@osapp/model';
import { IViewCountResult } from '@osapp/model/IViewCountResult';
import { DestroyableComponentBase } from '@osapp/modules/utils/components/destroyable-component-base';
import { Observable, merge } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Traitement } from '../../../../model/Traitement';
import { IPatient } from '../../../../modules/patients/model/IPatient';
import { PatientsService } from '../../../../modules/patients/services/patients.service';
import { TraitementService } from '../../../../services/traitement.service';
import { NB_ITEM_TO_DISPLAY } from '../../../anakin.constants';
import { PanneauPatientOrdonanceComponent } from '../../../features/ordonnances/components/panneau-patient-ordonnance/panneau-patient-ordonnance.component';
import { DeviceService } from '../../../features/shared/services/device.service';
import { LoaderService } from '../../../features/shared/services/loader.service';
import { PanneauService } from '../../../features/shared/services/panneau.service';
import { SeanceService } from '../../../features/shared/services/seance.service';

@Component({
  selector: 'di-ordonnance',
  templateUrl: './ordonnance.page.html',
  styleUrls: ['./ordonnance.page.scss'],
})
export class OrdonnancePage extends DestroyableComponentBase implements OnInit, OnDestroy {
  public ordonnancefilters: Array<string> = ["ACTIVES", "TERMINÉES"];
  public defaultFilter= this.ordonnancefilters[0];
	public ordoActiveCountMessage :string;
	public isMobileView: boolean = false;
	public searchedValue : string = "";
	public ordonnances: Traitement[] = [];
  public filteredOrdonnance: Traitement[] = [];
	public aucuneOrdonnance : boolean = false;
	public MainTextNoResult : string;
	public SubTextNoResult : string;
  public patientsMap: Map<string, IPatient> = new Map();
  public displayCount: number = NB_ITEM_TO_DISPLAY;
 
  public countSeancesByStatus: IViewCountResult[];

  constructor( 
		private svcDevice : DeviceService,
		private svcPatient : PatientsService,
		private svcPanneau: PanneauService,
		private svcTraitement : TraitementService,	
		private router: Router,
    private svcSeance: SeanceService,
    private svcLoader: LoaderService
		) {
			super()
		 }

  ngOnInit() {
		this.svcDevice.isMobile$.pipe(takeUntil(this.destroyed$)).subscribe((flag: boolean) => {
      this.isMobileView = flag;
    });

		merge(
			this.svcTraitement.deletion$,
			this.svcPanneau.closePanel$,
			this.svcTraitement.ordonnanceChanges$
		).pipe(
			takeUntil(this.destroyed$)
		).subscribe(() => {
			this.loadListOrdo();
		});
		this.loadListOrdo();

	}

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.svcLoader.hideLoader();
  }

	private loadListOrdo() : void {
    let patientIds: string[];
    this.svcLoader.showLoader("Récupération des ordonnances...");
		this.initOrdonnance().pipe(
      // On récupère les counts de séances par traitement et par status
      switchMap(() => this.svcSeance.countSeancesGroupByStatus()),
      tap((result: IViewCountResult[]) => {
        this.countSeancesByStatus = result;
        this.ordonnances = this.ordonnances.map((ordonnance: Traitement) => {
          ordonnance.countSeancesTotal = this.countSeancesByStatus
            .filter((traitement: IViewCountResult) => traitement.key[0] === ordonnance._id)
            .reduce((total, traitement) => total + traitement.value, 0);
          ordonnance.countSeancesDone = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
            traitement.key[0] === ordonnance._id && traitement.key[1] === 2
          )?.value ?? 0;
          ordonnance.countSeancesCompleted = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
            traitement.key[0] === ordonnance._id && traitement.key[1] === 5
          )?.value ?? 0;
          return ordonnance;
        })
      }),
      switchMap(() => {
        const mapOrdoPatients = this.ordonnances.map((ordonnance: Traitement) => Traitement.extractPatientId(ordonnance._id)).filter(id => id);
        const setOrdoPatients = new Set(mapOrdoPatients)
        patientIds = Array.from(setOrdoPatients);
        return this.svcPatient.getPatientsByIds(patientIds);
      }),
			tap((patients: IPatient[]) => {
        // //TODO : a conserver pour vérifier que les migrations sont correctes et qu'aucun patient n'a été supprimé
        // if (patientIds.length !== patients.length) {
        //   // Récupérer les IDs des patients obtenus depuis la BDD
        //   const patientIdsRetrieved = patients.map(patient => patient._id);

        //   // Trouver les IDs manquants en comparant la liste originale avec la liste récupérée
        //   const missingIds = patientIds.filter(id => !patientIdsRetrieved.includes(id));
        //     console.error("patients manquants", missingIds.filter(Boolean).join(";"));
        // }
				this.patientsMap = new Map(patients.map(patient => [patient._id, patient]));
        this.ordonnances.forEach((ordonnance: Traitement) => {
          ordonnance.patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id))
        });
        this.applyFilter();
        this.getNbOrdonnance();
        this.aucuneOrdonnance = this.ordonnances.length === 0;
        if (this.aucuneOrdonnance) {
          this.MainTextNoResult = "Aucune ordonnance";
          this.SubTextNoResult = "Vous allez retrouver ici toutes les ordonnances par patient.";
        }
        this.svcLoader.hideLoader();  
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private initOrdonnance(): Observable<Traitement[]> {
    //TODO : Si demain on a beaucoup de traitement à récupérer il faudra utiliser les options "skip" et "limit" 
    //car actuellement on récupère tous les traitements

    return this.svcTraitement.getAllTraitementsOrdonnances().pipe(
        tap((traitements: Traitement[]) => {
            if (traitements.length === 0) {
                this.ordonnances = [];
            } else {
                this.ordonnances = traitements.map(traitement => {
                  traitement.createDate = new Date(traitement.createDate); 
                  return traitement
                });

                this.ordonnances.sort((a, b) => {
                    return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
                });
            }
        })
    );
}


	private applyFilter(): void {
		this.filteredOrdonnance = this.ordonnances;
    if (this.defaultFilter === "ACTIVES") {
      // Actives : ordonnance avec au moins une séance 'to be done' OU sans aucune séance générée
      this.filteredOrdonnance = this.filteredOrdonnance.filter((ordonnance: Traitement) => {
        const treatmentEntry = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
          traitement.key[0] === ordonnance._id && traitement.key[1] === 6
        );
        return (treatmentEntry?.value ?? 0) > 0 || ordonnance.countSeancesTotal === 0;
      });

    } else if (this.defaultFilter === "TERMINÉES") {
      // Terminées : aucune séance 'to be done' ET des séances générées
      this.filteredOrdonnance = this.filteredOrdonnance.filter((ordonnance: Traitement) => {
        const treatmentEntry = this.countSeancesByStatus.find((traitement: IViewCountResult) =>
          traitement.key[0] === ordonnance._id && traitement.key[1] === 6
        );
        return (treatmentEntry?.value ?? 0) === 0 && ordonnance.countSeancesTotal > 0;
      });
    }

    this.filteredOrdonnance.sort((a, b) => {
      return (b.createDate as Date).getTime() - (a.createDate as Date).getTime();
    });
    this.getNbOrdonnance();
  }

  showMore() {
    this.displayCount += NB_ITEM_TO_DISPLAY;
  }

  public filterOrdonnances(searchValue: string) {
		this.searchedValue = searchValue.trim().toLowerCase();
    if (this.searchedValue && this.searchedValue.length > 2) {
			  this.applyFilter();
        const searchTerms = this.searchedValue.split(' ').filter(term => term);
        this.filteredOrdonnance = this.filteredOrdonnance.filter((ordonnance: Traitement) => {
          const patient = this.patientsMap.get(Traitement.extractPatientId(ordonnance._id));
            if (!patient) return false;

            const firstName = patient.firstName ? patient.firstName.toLowerCase() : '';
            const lastName = patient.lastName ? patient.lastName.toLowerCase() : '';
            const lastNameUsuel = patient.usualLastName ? patient.usualLastName.toLowerCase() : '';

						const formattedBirthDateSlash = DateHelper.transform(patient.birthDate, ETimetablePattern.dd_MM_yyyy_slash);

            const matchesOnlyFirstName = searchTerms.length === 1 &&
                searchTerms[0] && firstName.includes(searchTerms[0]);

            const matchesOnlyLastName = searchTerms.length === 1 &&
                searchTerms[0] && lastName.includes(searchTerms[0]);
						
						const matchesOnlyLastNameusuel = searchTerms.length === 1 &&
                searchTerms[0] && lastNameUsuel.includes(searchTerms[0]);

            const matchesFirstLast = searchTerms.length === 2 &&
                firstName.includes(searchTerms[0]) && lastName.includes(searchTerms[1]);

            const matchesLastFirst = searchTerms.length === 2 &&
                lastName.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

						const matchesFirstLastUsuel = searchTerms.length === 2 &&
                firstName.includes(searchTerms[0]) && lastNameUsuel.includes(searchTerms[1]);

            const matchesLastusuelFirst = searchTerms.length === 2 &&
                lastNameUsuel.includes(searchTerms[0]) && firstName.includes(searchTerms[1]);

					const matchesSocialNumber = patient.socialNumber?.startsWith(this.searchedValue);
					const matchesBirthDate = this.searchedValue === formattedBirthDateSlash;

					return matchesOnlyFirstName || matchesOnlyLastName || matchesOnlyLastNameusuel || matchesFirstLastUsuel || matchesLastusuelFirst || matchesFirstLast || matchesLastFirst || matchesSocialNumber || matchesBirthDate;
        });
    } else {
				if(searchValue === ""){
					this.applyFilter();
				}
        this.filteredOrdonnance = this.filteredOrdonnance;
    }
		this.aucuneOrdonnance = this.filteredOrdonnance.length === 0;
		if(this.aucuneOrdonnance)
		{
			this.MainTextNoResult = "Aucune ordonnance trouvée";
			this.SubTextNoResult = "Essayer de modifier votre recherche";
		}
		this.getNbOrdonnance();
  }

  public showOrdonnanceList() {
    return this.filteredOrdonnance.length > 0;
  }

	public getNbOrdonnance() : void {
    const nbOrdoActive = this.filteredOrdonnance.length ;
		if(nbOrdoActive == 0)
		{
			this.ordoActiveCountMessage = "Aucune ordonnance";
		}
		else
		{
      this.ordoActiveCountMessage = `${nbOrdoActive} ordonnance${nbOrdoActive > 1 ? `s ${this.defaultFilter.toLowerCase()}` : ` ${this.defaultFilter.toLowerCase().slice(0, -1)}`}`;
		}
  }

	public changeFiltre(filtre: string )
	{
    this.defaultFilter	= filtre;
		this.applyFilter();
		if(this.searchedValue && this.searchedValue.length > 2)
      this.filterOrdonnances(this.searchedValue);
  }

  public handleClickAddOrdonnance(event: Event) {
    this.svcPanneau.open("Patients",PanneauPatientOrdonanceComponent,{ })
  }
}