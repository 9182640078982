export const EEtatsFacture = {
  aEnvoyer: {
    status: "aEnvoyer",
    labelTier: "A télétransmettre au tiers",
		label: ""
  },
  papier: {
    status: "papier",
    labelTier: "A envoyer en papier au tiers",
		label: ""
  },
  envoyer: {
    status: "envoyer",
    labelTier: "Envoyé au tiers",
		label: "Envoyé"
  },
  paye: {
    status: "paye",
    labelTier: "Payé au tiers",
		label: "Payé"
  },
  rejet: {
    status: "rejet",
    labelTier: "Rejeté par le tiers payeur",
		label: "Rejet"
  }
	
};
