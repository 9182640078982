import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StringHelper } from '@osapp/helpers/stringHelper';
import { ConfigData, EEnvironmentId, IContact, UserData } from '@osapp/model';
import { ContactsService } from '@osapp/services';
import { tap } from 'rxjs/operators';
import { IIdlEnvironment } from '../../../model/IIdlEnvironment';
import { IESecurisationService } from '../../features/shared/services/ie-securisation.service';
import { IE_PAGE_TELETRANSMISSION } from '../../anakin.constants';

@Component({
	selector: 'di-teletransmisson',
	templateUrl: './teletransmisson.page.html',
	styleUrls: ['./teletransmisson.page.scss'],
})
export class TeletransmissonPage implements OnInit {

	public iframeSrc: SafeUrl;
	private firstLoad = true;


	private iframePrivate: ElementRef<HTMLIFrameElement>;
	@ViewChild('iframe') public set iframe(iframe: ElementRef<HTMLIFrameElement>) {
		if (!this.iframePrivate && iframe) {
			this.iframePrivate = iframe;
			this.initIFrame();
		}
	};


	constructor(
		private svcContacts: ContactsService,
		private readonly ioSanitizer: DomSanitizer,
		private route: Router,
		private cdr: ChangeDetectorRef,
		private svcIeSecurisation: IESecurisationService
	) { }

	public ngOnInit(): void {

		this.svcContacts.getContactFromUserId(UserData.current?.name)
			.pipe(
				tap((poContact: IContact) => {
					let numNatPs: string = StringHelper.isBlank(poContact.rpps) ? poContact.adeli : poContact.rpps;
					const numFiness: string = poContact.finess;

					if (numNatPs && numFiness) {
						numNatPs = numNatPs + numNatPs.charAt(numNatPs.length - 1);
						this.svcIeSecurisation.genererTokenUI(numNatPs, numFiness, IE_PAGE_TELETRANSMISSION).pipe(
							tap((uiToken) => {
								if (uiToken) {
									const environment = ConfigData.environment as IIdlEnvironment;
									const baseUrl = `${environment.fsv_teletransmission_url}?ui_token=${uiToken}&URLRetour=${location.origin}`;
									const resetSessionParam = environment.id === EEnvironmentId.prod ? '&resetsession' : '';

									this.iframeSrc = this.ioSanitizer.bypassSecurityTrustResourceUrl(baseUrl + resetSessionParam);
								}
							})
						).subscribe();
					}
				})
			)
			.subscribe();
	}

	private async initIFrame(): Promise<void> {
		this.iframePrivate.nativeElement.addEventListener("load", () => {
			if (!this.firstLoad) {
				this.route.navigate(["home"])
			}
			this.firstLoad = false;
		});
	}
}
